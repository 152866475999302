import React from 'react';

import { DialogContent } from '@mui/material';

import PlansSection from 'ReusableComponents/PlansSection/PlansSection';
import { useDriveContext } from 'Drive/DriveContext';

const ManageSubscriptions = ({ path }) => {
  const { handleOptimisticUpdate } = useDriveContext();
  return (
    <DialogContent>
      <PlansSection path={path} type="manage" handleOptimisticUpdate={handleOptimisticUpdate} />
    </DialogContent>
  );
};

export default ManageSubscriptions;
