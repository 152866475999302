import React from 'react';

import { GetApp } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useContextMenu } from '../../ContextMenuContext';
import { downloadFile } from 'Drive/itemUtils';
import { useMinimalAuth } from 'hooks';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useSelectedItems } from 'Drive/selectedItemContext';
import ApiManager from 'ApiManager';
import { useMainContext } from 'ReusableComponents';
import { useNotificationSetter } from 'Navbar/Notifications';

const Download = ({ setDialog, closeDialog, onClose }) => {
  const { currentFolderInfo } = useCurrentFolder();
  const { menuOptions } = useContextMenu();
  const { onOpenSnackbar } = useMainContext();
  const { path, type } = menuOptions;
  const { selectedItems, resetSelectedItems } = useSelectedItems();
  const setMenuOpen = useNotificationSetter();
  const user = useMinimalAuth();
  const download = () => {
    let pathId;
    let paths;
    if (type === 'multiple') {
      paths = selectedItems;
    } else if (path.type !== 'file') {
      paths = [path];
    }

    if (paths) {
      console.log(selectedItems);
      ApiManager.post(`/v3/path/order`, { pathIds: paths.map((p) => p.id) }, user)
        .then((r) => {
          onOpenSnackbar({
            content: 'You can view the status of your download in notifications.',
            level: 'success',
          });
          setMenuOpen(true);
        })
        .catch((e) => {
          onOpenSnackbar({
            content: e.message,
            level: 'error',
          });
        });

      resetSelectedItems();
    } else {
      if (path) {
        pathId = path.id;
      } else {
        pathId = currentFolderInfo?.id;
      }

      downloadFile(pathId, user);
    }
    onClose();
  };

  return <CombinedMenuItem icon={<GetApp color={'inherit'} />} label="Download" action={download} />;
};

export default Download;
