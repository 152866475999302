import DateRangePicker from 'ReusableComponents/DateRangePicker/DateRangePicker';
import React from 'react';

const Date = ({ searchVal: value, setSearchVal: setValue, initialValue }) => (
  <DateRangePicker
    value={value}
    setValue={setValue}
    initialValue={initialValue}
    slotProps={{ dateRangePicker: { disableFuture: false } }}
  />
);

export default Date;
