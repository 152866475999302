import React, { useState, memo, useEffect, useRef } from 'react';

import { Typography, Tooltip, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { CloudUpload, CheckCircle, Error, Cancel, HourglassEmpty, SkipNext } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { AutoSizer, List } from 'react-virtualized';

import { useUploadContext } from './uploadContext';
import { CircularProgressWithLabel, ConfirmationDialog } from '../MapManagement/core';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { UseUploadingTimestamps } from './useUploadLogic';

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkitScrollbar': {
      display: 'none',
    },
  },
  itemContainer: {
    paddingLeft: '14px',
    paddingRight: '20px',
    margin: 0,
    display: 'grid',
    gridTemplateColumns: `30px 1fr 40px`,
    alignItems: 'center',
    gridGap: '14px',
    height: '50px',
    width: '100%',
    boxSizing: 'border-box',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkitScrollbar': {
      display: 'none',
    },
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  itemText: {},
  uploadingItem: {
    cursor: 'pointer',
  },
  cloudUploadIcon: {
    color: theme.palette.primary.dark,
  },
  endIcon: {
    justifySelf: 'center',
  },
  successIcon: {
    color: theme.palette.success.dark,
  },
  errorIcon: {
    color: theme.palette.error.dark,
  },
}));

const UploadItems = () => {
  const { classes } = useStyles();
  const { uploads } = useUploadContext();
  const [dialogOpen, setDialogOpen] = useState({ open: false, item: 0 });
  const { currentFolderInfo: map } = useCurrentFolder();
  const handleClose = () => setDialogOpen((prev) => ({ ...prev, open: false }));
  const handleOpen = (i) => setDialogOpen({ open: true, item: i });
  const { setUploadCounter } = UseUploadingTimestamps();
  const itemRenderer = ({ key, index, style }) => {
    const upload = uploads[index];

    if (typeof upload.progressStatus === 'number') {
      return <UploadingItem style={style} key={key} upload={upload} setDialogOpen={() => handleOpen(index)} />;
    } else if (upload.progressStatus === 'success') {
      return <SuccessItem style={style} key={key} upload={upload} />;
    } else if (upload.progressStatus === 'error') {
      return <ErrorItem style={style} key={key} upload={upload} />;
    } else if (upload.progressStatus === 'pending') {
      return <PendingItem style={style} key={key} upload={upload} />;
    } else if (upload.progressStatus === 'skipped') {
      return <SkippedItem style={style} key={key} upload={upload} />;
    } else {
      return <UploadingItem style={style} key={key} upload={upload} setDialogOpen={() => handleOpen(index)} />;
    }
  };

  // scroll to top when new upload added
  const listRef = useRef();
  useEffect(() => {
    listRef.current?.scrollToRow(0);
  }, [uploads.length]);

  return (
    <div className={classes.container}>
      <div style={{ height: `${uploads.length * 65}px`, maxHeight: '385px' }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={listRef}
              height={height}
              rowCount={uploads.length}
              rowHeight={65}
              rowRenderer={itemRenderer}
              width={width}
            />
          )}
        </AutoSizer>
      </div>
      {/* {uploads.map((upload, i) => renderItem(upload, i))} */}
      <ConfirmationDialog
        open={!!dialogOpen.open}
        handleClose={handleClose}
        title="Cancel this upload"
        legend="Are you sure you want to cancel this upload?"
        cancelText="No"
        agreeText="Yes"
        onClick={() => {
          console.log('aborting request', uploads[dialogOpen.item]);
          uploads[dialogOpen.item]?.request.abort();
          if (map) {
            setUploadCounter();
          }
          handleClose();
        }}
      />
    </div>
  );
};

const Item = memo(({ upload, endIcon, className, setDialogOpen, ...otherProps }) => {
  const { classes } = useStyles();
  return (
    <section className={`${classes.itemContainer} ${className ? className : ''}`} {...otherProps}>
      <CloudUpload className={classes.cloudUploadIcon} />
      <Typography className={classes.itemText} variant="body2" noWrap>
        {upload.filename}
      </Typography>
      {endIcon}
    </section>
  );
});

Item.displayName = 'Item';

Item.propTypes = {
  upload: PropTypes.shape({
    filename: PropTypes.string.isRequired,
  }).isRequired,
  endIcon: PropTypes.element,
  className: PropTypes.string,
  setDialogOpen: PropTypes.func,
};

const UploadingItem = ({ setDialogOpen, upload, ...props }) => {
  const { classes } = useStyles();

  const [hover, setHover] = useState(false);
  console.log('status', upload.progressStatus);
  const progressIcon =
    Number(upload.progressStatus) !== 100 ? (
      <CircularProgressWithLabel
        value={upload.progressStatus}
        typographyStyles={{ fontSize: '0.8rem' }}
        className={classes.progressIcon}
      />
    ) : (
      <CircularProgress className={classes.progressIcon} />
    );

  UploadingItem.propTypes = {
    setDialogOpen: PropTypes.func.isRequired,
    upload: PropTypes.shape({
      progressStatus: PropTypes.number.isRequired,
    }).isRequired,
  };

  const deleteIcon = <Cancel className={`${classes.endIcon} ${classes.cancelIcon}`} />;

  return (
    <Item
      {...props}
      upload={upload}
      onClick={setDialogOpen}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      endIcon={hover ? deleteIcon : progressIcon}
      className={classes.uploadingItem}
    />
  );
};

const SuccessItem = (props) => {
  const { classes } = useStyles();

  return <Item {...props} endIcon={<CheckCircle className={`${classes.endIcon} ${classes.successIcon}`} />} />;
};

const ErrorItem = (props) => {
  const { classes } = useStyles();
  return (
    <Item
      {...props}
      endIcon={
        <Tooltip title={props.upload.errorMessage}>
          <Error className={`${classes.endIcon} ${classes.errorIcon}`} />
        </Tooltip>
      }
    />
  );
};

const PendingItem = (props) => {
  const { classes } = useStyles();
  return (
    <Item
      {...props}
      endIcon={
        <Tooltip title={'Upload waiting on other uploads to finish.'}>
          <HourglassEmpty className={classes.endIcon} />
        </Tooltip>
      }
    />
  );
};

const SkippedItem = (props) => {
  const { classes } = useStyles();
  return (
    <Item
      {...props}
      endIcon={
        <Tooltip title={'Upload skipped as it already existed.'}>
          <SkipNext className={classes.endIcon} />
        </Tooltip>
      }
    />
  );
};

ErrorItem.propTypes = {
  upload: PropTypes.shape({
    errorMessage: PropTypes.string,
  }),
};

export default UploadItems;
