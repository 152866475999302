import React, { useEffect, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import { Button, Collapse, Divider, IconButton, TextField, Typography } from '@mui/material';
import { Add, Check, Close, Delete } from '@mui/icons-material';

import AccessLevelButton from 'ReusableComponents/AccessLevelComponent/AccessLevelButton';
import AccessLevelComponent from 'ReusableComponents/AccessLevelComponent';

import ActionsPerMinuteButton from 'ReusableComponents/ActionsPerMinuteComponent/ActionsPerMinuteButton';
import { ActionsPerMinuteComponent } from 'ReusableComponents/ActionsPerMinuteComponent';
import { MINIMUM } from 'ReusableComponents/ActionsPerMinuteComponent/ActionsPerMinuteUtils';

import { useAuth } from 'hooks';
import ApiManager from 'ApiManager';
import { useHistory } from 'react-router';
import { useMainContext } from 'ReusableComponents/MainContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useStyles = makeStyles({ name: 'PlansSection' })((theme, { type }) => ({
  root: {
    '&>*': {
      boxSizing: 'border-box',
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr) min-content',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 6,
    height: 52,
  },
  fauxButton: {
    padding: '6px 8px',
    paddingRight: 24,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  spacer: {
    width: type === 'subscribe' ? 110 : 52,
  },
}));

const INITIAL_VALUES = {
  accessLevel: ApiManager.newAccessLevel['view'],
  actionsPerMinute: MINIMUM,
  fee: 10,
};

const PlansSection = ({ path, type, onClick = null, handleOptimisticUpdate }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(path.plans);
  const [profile, setProfile] = useState({});
  const [accessLevel, setAccessLevel] = useState(INITIAL_VALUES.accessLevel);
  const [actionsPerMinute, setActionsPerMinute] = useState(INITIAL_VALUES.actionsPerMinute);
  const [fee, setFee] = useState(INITIAL_VALUES.fee);
  const history = useHistory();
  const { getPathInfo } = useCurrentFolder();

  const user = useAuth();

  const { classes: styles } = useStyles({ type });

  useEffect(() => {
    ApiManager.get(`/v3/user/${path.user.id}`)
      .then((r) => {
        console.log(r);
        setProfile(r);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [path.user.id]);

  const resetPlan = () => {
    setAccessLevel(INITIAL_VALUES.accessLevel);
    setActionsPerMinute(INITIAL_VALUES.actionsPerMinute);
    setFee(INITIAL_VALUES.fee);
  };

  const { setOpenContact } = useMainContext();

  const setContactOpen = (b) => {
    setOpenContact({
      title: 'Inquiry for ' + path.user.username,
      userId: path.user.id,
    });
  };

  const toggleAddPlan = () => {
    setOpen((oldOpen) => !oldOpen);
  };

  const closePlan = () => {
    toggleAddPlan();
    resetPlan();
  };

  const addPlan = async () => {
    const access = { accessLevel: accessLevel, processingUnits: actionsPerMinute, monthlyFee: fee };
    const body = { access: access };
    closePlan();

    await ApiManager.post(`/v3/path/${path.id}/plan`, body, user);

    const res = await getPathInfo(path.id);
    console.log(res);
    setPlans(res.plans);

    if (handleOptimisticUpdate) {
      handleOptimisticUpdate({
        updateMaps: (maps) => {
          return maps.map((m) => {
            if (m.id === path.id) {
              return res;
            } else {
              return m;
            }
          });
        },
      });
    }
  };

  const removePlan = (id) => {
    ApiManager.delete(`/v3/path/${path.id}/plan/${id}`, null, user)
      .then((result) => {
        setOpen(false);
        resetPlan();
        getPathInfo(path.id);
        setPlans((prev) => prev.filter((p) => p.id !== id));

        if (handleOptimisticUpdate) {
          handleOptimisticUpdate({
            updateMaps: (maps) => {
              return maps.map((m) => {
                if (m.id === path.id) {
                  let newMap = { ...m };
                  newMap.plans = newMap.plans.filter((p) => p.id !== id);
                  return newMap;
                } else {
                  return m;
                }
              });
            },
          });
        }
      })
      .catch((err) => console.error(err));
  };

  const subscribeToPlan = (id) => {
    setLoading(true);

    ApiManager.post(`/v3/path/${path.id}/plan/${id}/subscribe`, { pathId: path?.id, planId: id }, user)
      .then((result) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const handleAccessLevelChange = (value) => {
    setAccessLevel(value);
  };

  const handleActionsChange = (value) => {
    setActionsPerMinute(value);
  };

  const handleFeeChange = (e) => {
    let v = parseFloat(e.target.value);
    if (!v) {
      v = 1;
    } else {
      v = Math.max(1, v);
    }
    setFee(v);
  };

  const userIsCompany = profile?.billingInformation?.isCompany;
  if (!profile?.billingInformation) return null;
  if (type === 'manage' && !userIsCompany) {
    return (
      <React.Fragment>
        {' '}
        <Typography align="center">
          {path.user.id === user.id && !userIsCompany
            ? 'According to your billing information you are not a company, please update your billing information first'
            : `The owner of this ${
                path.type === 'process' ? 'process' : path.type === 'folder' ? 'folder' : 'layer'
              } needs to update his/her billing information before you can add subcription plans.`}
        </Typography>
        {user && user.id === path.user.id ? (
          <div className={styles.buttonContainer}>
            <Button
              color="primary"
              onClick={() => {
                if (path.user.id === user.id && !path.user.commercial) {
                  history.push('/plan');
                } else if (path.user.id === user.id && !path.user.company) {
                  history.push('/balance');
                } else {
                  setContactOpen();
                }
              }}
            >
              {path.user.id === user.id && !path.user.company ? 'UPDATE BILLING ADDRESS' : 'CONTACT OWNER'}
            </Button>
          </div>
        ) : null}
      </React.Fragment>
    );
  }

  return (
    <>
      <Collapse in={!!open || plans?.length > 0}>
        <div className={styles.grid}>
          <Typography variant="overline" align="center" display="block">
            Access Level
          </Typography>
          <Typography variant="overline" align="center" display="block">
            {'Processing units'}
          </Typography>
          <Typography variant="overline" align="center" display="block">
            {type === 'subscribe' ? 'Fee' : 'Fee (at least 1 euro)'}
          </Typography>
          {plans?.length > 0 && <div className={styles.spacer} />}
        </div>
      </Collapse>
      <Collapse in={plans?.length === 0 && !open}>
        <Typography align="center">No plans yet</Typography>
      </Collapse>
      {plans.map((x) => (
        <div key={x.id} className={styles.grid}>
          <div className={styles.fauxButton}>
            <AccessLevelComponent accessLevel={x.accessLevel} isButton />
          </div>
          <div className={styles.fauxButton}>
            <ActionsPerMinuteComponent actionsPerMinute={x.actionsMonth ? x.actionsMonth : x.processingUnits} />
          </div>
          <Typography align="center" display="block">
            {x.monthlyFee === 0 || x.subscriptionFee === 0
              ? 'Free'
              : x.monthlyFee
              ? Number(x.monthlyFee).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'EUR',
                })
              : Number(x.subscriptionFee).toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'EUR',
                })}
          </Typography>

          {type === 'subscribe' ? (
            <Button
              disabled={loading}
              onClick={!!onClick ? () => onClick({ pathId: path?.id, ...x }) : () => subscribeToPlan(x.id)}
              color="primary"
              variant="contained"
            >
              Subscribe
            </Button>
          ) : (
            <IconButton onClick={() => removePlan(x.id)} size="large">
              <Delete fontSize="inherit" />
            </IconButton>
          )}
        </div>
      ))}
      {type === 'manage' && (
        <React.Fragment>
          <Collapse in={!open}>
            <div className={styles.buttonContainer}>
              <Button color="primary" startIcon={<Add />} onClick={toggleAddPlan}>
                add new plan
              </Button>
            </div>
          </Collapse>
          <Collapse in={!!open}>
            {plans?.length > 0 && <Divider style={{ margin: '12px 0' }} />}
            <div className={styles.grid}>
              <AccessLevelButton
                accessLevel={accessLevel}
                min={ApiManager.newAccessLevel['view']}
                max={ApiManager.newAccessLevel['view+']}
                onChange={handleAccessLevelChange}
                noNone={true}
              />
              <ActionsPerMinuteButton actionsPerMinute={actionsPerMinute} onChange={handleActionsChange} />
              <TextField
                variant="outlined"
                size="small"
                type="number"
                inputProps={{
                  min: 1,
                  step: 1,
                }}
                InputProps={{
                  startAdornment: <span style={{ marginTop: 0.5 }}>€</span>,
                }}
                value={fee}
                onChange={handleFeeChange}
              />
            </div>
            <div className={styles.buttonContainer}>
              <IconButton onClick={closePlan} size="large">
                <Close />
              </IconButton>
              <IconButton color="primary" onClick={addPlan} size="large">
                <Check />
              </IconButton>
            </div>
          </Collapse>
        </React.Fragment>
      )}
    </>
  );
};

export default PlansSection;
