import React, { PureComponent } from 'react';

import ApiManager from '../ApiManager';
import { withMainContext } from 'ReusableComponents/MainContext';
import { Send } from '@mui/icons-material';
import { checkEmail } from 'Account//utils';
import { withMinimalAuth, withAuthFuncs } from 'Account';
import {
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  TextField,
  Typography,
  Dialog,
  DialogContent,
} from '@mui/material';

/* const questionsInit = {
  name: '',
  email: '',
  message: '',
}; */

class ReferralInvite extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  sendEmail = () => {
    this.setState({ loading: true });
    ApiManager.post('/v3/user/invite', { email: this.state.email }, this.props.authMinimalUser)
      .then((r) => {
        this.props.fetchUserInfo();
        this.props.onOpenSnackbar({
          content: 'Invite sent',
          level: 'success',
        });
        this.setState({ loading: false });

        this.props.setInvite(false);
      })
      .catch((e) => {
        this.setState({ userExists: true, loading: false });
        console.log(e);
      });
  };

  render() {
    let error = ' ';
    for (let key in this.state.error) {
      error = error + ' ' + this.state.error[key];
    }
    let validEmail = checkEmail(this.state.email, () => {});
    return (
      <React.Fragment>
        {' '}
        <Dialog
          open={true}
          keepMounted
          onClose={() => {
            this.props.setInvite(false);
          }}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <CardContent>
              <Typography component="h1" variant="h1" color="primary" align="center">
                Invite someone to join Ellipsis Drive
              </Typography>

              <TextField
                variant="standard"
                label="Type email"
                fullWidth
                type="text"
                helperText={
                  this.state.userExists
                    ? 'This user already has an Ellipsis Drive account'
                    : this.state.email && !validEmail
                    ? 'Not a valid email'
                    : null
                }
                error={(this.state.email && !validEmail) || this.state.userExists}
                onChange={(e) => {
                  this.setState({ email: e.target.value, userExists: false });
                }}
                value={this.state.email}
              />
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                disabled={!this.state.email || !validEmail}
                color="primary"
                variant="contained"
                onClick={() => {
                  this.sendEmail();
                }}
                startIcon={!this.state.loading ? <Send /> : <CircularProgress />}
              >
                Send
              </Button>
            </CardActions>
          </DialogContent>
        </Dialog>
        <div className="overlay"></div>
      </React.Fragment>
    );
  }
}

export default withAuthFuncs(withMinimalAuth(withMainContext(ReferralInvite)));
