import React, { useEffect, useMemo, useState } from 'react';

import { Divider, ListItem, ListItemIcon, Menu, MenuItem, Slider, Tooltip, Typography } from '@mui/material';

import { Delete, InfoOutlined } from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

import AccessLevelComponent from './AccessLevelComponent';

import ApiManager from '../../ApiManager';

const SLIDER_WIDTH = 4;

const useStyles = makeStyles({ name: 'AccessLevelMenu' })((theme) => ({
  menu: { padding: theme.spacing(1) },
  subheader: { lineHeight: theme.spacing(4) },
  sliderContainer: {
    minWidth: 200,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  slider: {
    '&.MuiSlider-root.MuiSlider-vertical': {
      width: SLIDER_WIDTH,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: '100%',
      position: 'relative',
      boxSizing: 'border-box',
      '& .MuiSlider-mark': { display: 'none' },
      '&>.MuiSlider-track': { border: 'none', transform: 'rotate(180deg)', left: SLIDER_WIDTH / 2 },

      '&>.MuiSlider-thumb, &>.MuiSlider-mark, &>.MuiSlider-rail': { left: SLIDER_WIDTH },
    },

    '& .MuiSlider-markLabel': { left: theme.spacing(4), right: 0 },
    '& .MuiSlider-markLabel .AccessLevelComponent': { opacity: '50%' },
    '& .MuiSlider-markLabelActive.MuiSlider-markLabel .AccessLevelComponent': { opacity: '100%' },
  },
}));

const filteredValues = [ApiManager.newAccessLevel['fullView']];

const AccessLevelMenu = ({ setMenuOptions, menuOptions }) => {
  const [sliderValue, setSliderValue] = useState(-1);
  const isPublic = !menuOptions.targetUser;
  const { classes: styles } = useStyles();
  const disabled = sliderValue === -2;

  const marks = useMemo(
    () =>
      [...ApiManager.newAccessLevelLong]
        ?.reverse()
        ?.slice(1)
        ?.filter((x) => {
          return (
            (!menuOptions.max || x.accessLevel <= menuOptions.max) &&
            !(filteredValues?.includes(x?.accessLevel) && x?.accessLevel !== menuOptions?.accessLevel)
          );
        })
        ?.slice(0, -1)
        ?.map((l, i) => ({
          value: i,
          label: (
            <LabelItem
              accessLevelObj={l}
              disabled={
                menuOptions.min > l.accessLevel ||
                (menuOptions.hasGeofence && l.accessLevel > ApiManager.newAccessLevel['view'])
              }
              hasGeofence={menuOptions.hasGeofence}
            />
          ),
          accessLevelKey: l.key,
          accessLevel: l.accessLevel,
        }))
        ?.filter((x) => x),
    [isPublic, menuOptions?.noNone, menuOptions?.accessLevel, menuOptions.max, menuOptions.min]
  );

  useEffect(() => {
    if (!!menuOptions?.accessLevel || menuOptions.accessLevel === 0) {
      let findValue = Math.min(menuOptions.max, menuOptions.accessLevel);

      findValue = marks.findIndex((m) => m.accessLevel === findValue);
      setSliderValue(findValue);
    }
  }, [marks, menuOptions.accessLevel, menuOptions.max]);

  const onClose = (e) => {
    e?.stopPropagation();

    setSliderValue(-1);
    setMenuOptions('reset');
  };

  const handleSlide = (e, value) => {
    e?.stopPropagation();

    if (marks[value].accessLevel < menuOptions.min || menuOptions.hasGeofence) {
      return;
    }

    setSliderValue(value); //greater then because slide is inverted
  };

  const handleSlideCommit = (e, value) => {
    e?.stopPropagation();

    if (marks[value].accessLevel < menuOptions.min || menuOptions.hasGeofence) {
      return;
    }

    const targetAccessLevel = marks[value]?.accessLevel;

    if (!!targetAccessLevel || targetAccessLevel === 0) {
      setSliderValue(value);
      menuOptions.onChange(targetAccessLevel);
      onClose();
    } else {
      onClose();
    }
  };

  const handleDelete = () => {
    onClose();
    menuOptions.deleteUser(menuOptions.targetUser?.id);
  };

  return (
    !!menuOptions?.targetEl && (
      <Menu
        PaperProps={{ className: styles.menu }}
        open={!!menuOptions?.targetEl}
        anchorEl={menuOptions.targetEl}
        onClose={onClose}
        onClick={(e) => e?.stopPropagation()}
      >
        <ListItem className={styles.sliderContainer} sx={{ height: marks?.length ? 4.5 * 8 * marks.length : 250 }}>
          <Slider
            marks={marks}
            step={null}
            min={0}
            max={marks.length - 1}
            className={styles.slider}
            orientation="vertical"
            onChange={handleSlide}
            onChangeCommitted={handleSlideCommit}
            value={sliderValue}
            disabled={disabled}
            track="inverted"
          />
        </ListItem>

        {!!menuOptions?.displayEdit &&
          menuOptions?.deleteUser && [
            <Divider key="menuItem_userDivider" sx={{ my: '1rem' }} />,
            <MenuItem key="menuItem_delete" onClick={handleDelete}>
              <ListItemIcon>
                <Delete className={styles.icon} />
              </ListItemIcon>
              <Typography variant="inherit" noWrap>
                Remove
              </Typography>
            </MenuItem>,
          ]}
      </Menu>
    )
  );
};

export default AccessLevelMenu;

const useLabelItemStyles = makeStyles({ name: 'LabelItem' })((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.common.gray1,
  },
  disabled: {
    opacity: '50%',
  },
}));

const LabelItem = ({ accessLevelObj, disabled, extraText, hasGeofence }) => {
  const { classes: styles } = useLabelItemStyles();
  console.log('has geofence', hasGeofence, 'disabled', disabled);
  return (
    <div className={styles.root + ' ' + (disabled ? styles.disabled : '')}>
      <AccessLevelComponent
        accessLevel={accessLevelObj?.accessLevel}
        disabled={disabled || hasGeofence}
        labelProps={{ component: Typography, variant: 'button' }}
      />
      <Tooltip
        arrow
        title={
          <>
            <b>{accessLevelObj.tooltip}</b>
            {disabled && <br />}
            {!disabled ? null : hasGeofence ? 'Disabled due to geofence' : ' Disabled by inherited AccessLevel'}
          </>
        }
      >
        <InfoOutlined fontSize="small" className={styles.icon} disabled={!!extraText} />
      </Tooltip>
    </div>
  );
};
