import React, { useState, useCallback, useEffect, createContext, useContext, useRef, useMemo } from 'react';
import { readAndCompressImage } from 'browser-image-resizer';

import exifr from 'exifr';

import { uploadToAPI } from '../FilesManagement/utils';
import { v4 as uuidv4 } from 'uuid';
import ApiManager from 'ApiManager';

const NUMBER_OF_SIMULTANEOUS_UPLOADS = 2;

const uploadContext = createContext();
const allUploadsContext = createContext();
const uploadingContext = createContext();

const UseUploadingTimestampsProvider = ({ children }) => {
  const [uploading, setUploading] = useState([]);
  const [uploadingCounter, setUploadingCounter] = useState(1);
  const { uploads } = useAllUploadsLogic();

  const setUploadCounter = useCallback(() => {
    setUploadingCounter((prev) => prev + 1);
  }, []);

  const stopUploading = useCallback((noLongerUploading) => {
    setUploading((prev) => {
      return prev.filter((x) => !noLongerUploading.includes(x));
    });
  }, []);

  const addUploading = useCallback((newUploading) => {
    setUploading((prev) => {
      if (!prev.includes(newUploading)) {
        return [...prev, newUploading];
      } else {
        return prev;
      }
    });
  }, []);

  useEffect(() => {
    let noLongerUploading = [];
    for (let i = 0; i < uploading.length; i++) {
      const timestampId = uploading[i];
      if (
        uploads.filter(
          (u) =>
            u.sourceLayer === timestampId &&
            u.progressStatus !== 'success' &&
            u.progressStatus !== 'error' &&
            u.progressStatus !== 'skipped'
        ).length === 0
      ) {
        noLongerUploading = [...noLongerUploading, timestampId];
      }
    }
    if (noLongerUploading.length > 0) {
      stopUploading(noLongerUploading);
    }
  }, [uploads, stopUploading, uploading]);

  const memoizedUploading = useMemo(() => {
    return uploading;
  }, [uploading]);

  return (
    <uploadingContext.Provider
      value={{
        uploading: memoizedUploading,
        addUploading: addUploading,
        uploadCounter: uploadingCounter,
        setUploadCounter: setUploadCounter,
      }}
    >
      {children}
    </uploadingContext.Provider>
  );
};

const UseUploadLogicProvider = ({ children }) => {
  const [uploads, setUploads] = useState([]);
  const [hideDialog, setHideDialog] = useState(true);
  const [autoProcess, setAutoProcess] = useState({});

  const doHideDialog = useCallback(() => {
    setHideDialog(true);
  }, []);

  const setAutoProcessTimestamp = useCallback((timestampId, bool, map, onSelectMap, user) => {
    setAutoProcess((prev) => {
      return { ...prev, [timestampId]: { auto: bool, map: map, onSelectMap: onSelectMap, user: user } };
    });
  }, []);

  const uploadsRef = useRef(uploads);
  //display warning
  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
      if (uploadsRef.current.reduce((acc, curr) => (typeof curr.progressStatus === 'number' ? acc + 1 : acc), 0) > 0) {
        event.preventDefault();
        event.returnValue = 'Are you sure you want to leave this page? You still have uploads pending.';
      }
    });
  }, []);

  // handle upload queue
  useEffect(() => {
    uploadsRef.current = uploads;
    const currentUploadsNumber = uploads.reduce(
      (acc, { progressStatus }) => (typeof progressStatus === 'number' ? acc + 1 : acc),
      0
    );

    if (currentUploadsNumber < NUMBER_OF_SIMULTANEOUS_UPLOADS) {
      const stillToStartUpload = uploads.filter(({ progressStatus }) => progressStatus === 'pending');

      if (stillToStartUpload.length > 0) {
        updateUploads(stillToStartUpload[0].id, { progressStatus: 0 });
        stillToStartUpload[0].startUpload();
      }
    }
  }, [uploads]);

  const activateTimestamp = useCallback((map, sourceLayer, user, onSelectMap) => {
    const type = map.type === 'map' ? 'raster' : map.type === 'pointCloud' ? 'pointCloud' : 'vector';
    ApiManager.post(`/v3/path/${map.id}/${type}/timestamp/${sourceLayer}/activate`, null, user)
      .then((r) => {
        onSelectMap();
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const pauseTimestamp = useCallback((map, sourceLayer, user, onSelectMap) => {
    const type = map.type === 'pointCloud' ? 'pointCloud' : map.type === 'map' ? 'raster' : 'vector';
    ApiManager.post(`/v3/path/${map.id}/${type}/timestamp/${sourceLayer}/deactivate`, null, user)
      .then((r) => {
        onSelectMap();
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const doneTimestamps = useRef([]);
  useEffect(() => {
    const keys = Object.keys(autoProcess);
    for (let i = 0; i < keys.length; i++) {
      let obj = autoProcess[keys[i]];
      if (
        !doneTimestamps.current.includes(keys[i]) &&
        uploads.filter((u) => u.sourceLayer === keys[i] && u.progressStatus !== 'success').length === 0
      ) {
        doneTimestamps.current = [...doneTimestamps.current, keys[i]];
        if (obj.auto) {
          activateTimestamp(obj.map, keys[i], obj.user, obj.onSelectMap);
        } else {
          pauseTimestamp(obj.map, keys[i], obj.user, obj.onSelectMap);
        }
      }
    }
  }, [uploads, autoProcess, activateTimestamp, pauseTimestamp]);

  const updateUploads = (id, newData) => {
    setUploads((uploads) => uploads.map((upload) => (upload.id === id ? { ...upload, ...newData } : upload)));
  };

  const handleStartUpload = useCallback(
    (file, body, user, type, sourceLayer = null, onUploadSuccess, onUploadError, shouldSkip, map) => {
      doneTimestamps.current = doneTimestamps.current.filter((x) => x !== sourceLayer);
      const id = uuidv4();

      const uploadStart = (e) => {
        updateUploads(id, { request: e.target });
      };

      const uploadProgress = (e) => {
        console.log('upload event', e);
        updateUploads(id, { progressStatus: Math.ceil((e.loaded / e.total) * 100) });
      };

      const uploadSuccess = (e) => {
        if (e.target.status === 200) {
          updateUploads(id, { progressStatus: 'success' });
          // console.log(e);
          //make api call to activate the layer
          let r;
          try {
            r = JSON.parse(e.target.response);
          } catch {}
          onUploadSuccess(r);
        } else {
          onUploadError && onUploadError();

          try {
            updateUploads(id, { progressStatus: 'error', errorMessage: JSON.parse(e.target.response).message });
          } catch (err) {
            console.warn(err);
          }
        }
      };

      const uploadFailed = (e) => {
        onUploadError && onUploadError();
        // console.log('upload failed');
        console.log(e);
      };

      const uploadEnd = (e) => {
        if (e.target.status === 0) updateUploads(id, { progressStatus: 'error', errorMessage: 'Upload canceled' });
      };

      const uploadImage = async ({ type, body, user, events }) => {
        uploadProgress({ loaded: 10, total: 10 });

        try {
          let exifData = await exifr.parse(file);
          console.log('exifData', exifData);

          let lat =
            exifData['GPSLatitude'][0] + exifData['GPSLatitude'][1] / 60 + exifData['GPSLatitude'][1] / (60 * 60);
          if (exifData['GPSLatitudeRef'] !== 'N') {
            lat = -lat;
          }

          let lon =
            exifData['GPSLongitude'][0] + exifData['GPSLongitude'][1] / 60 + exifData['GPSLongitude'][1] / (60 * 60);
          if (exifData['GPSLongitudeRef'] !== 'E') {
            lon = -lon;
          }
          let geojson;
          if (Object.keys(exifData).includes('GPSAltitude')) {
            let z = exifData['GPSAltitude'];
            geojson = { type: 'Feature', properties: {}, geometry: { type: 'Point', coordinates: [lon, lat, z] } };
          } else {
            geojson = { type: 'Feature', properties: {}, geometry: { type: 'Point', coordinates: [lon, lat] } };
          }

          const res = await ApiManager.post(
            `/v3/path/${body.mapId}/vector/timestamp/${body.timestampId}/feature`,
            {
              features: [geojson],
            },
            user
          );
          const featureId = res[0];

          const imgConfig = {
            quality: 0.8,
            maxWidth: 4096,
            maxHeight: 4096,
            autoRotate: true,
          };
          const image = await readAndCompressImage(file, imgConfig);

          const base64 = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(image);
          });
          console.log('base64', base64);

          await ApiManager.post(
            `/v3/path/${body.mapId}/vector/timestamp/${body.timestampId}/feature/${featureId}/message`,
            { image: base64 },
            user
          );

          uploadSuccess({ target: { status: 200, response: '{ "message": "Image added" }' } });
        } catch {
          onUploadError && onUploadError();

          updateUploads(id, { progressStatus: 'error', errorMessage: 'No location data found in image' });
        }
      };

      const events = {
        loadstart: uploadStart,
        progress: uploadProgress,
        load: uploadSuccess,
        loadend: uploadEnd,
        error: uploadFailed,
      };

      setUploads((uploads) => [
        {
          filename: file.name,
          progressStatus: shouldSkip ? 'skipped' : 'pending',
          id,
          sourceLayer,
          map,
          startUpload:
            body.format === 'IMAGE'
              ? () => uploadImage({ type, body, user, file, events })
              : () => uploadToAPI(type, body, user, file, events),
        },
        ...uploads,
      ]);
    },
    []
  );

  const memoizedValue = useMemo(() => {
    return {
      handleStartUpload,
      setAutoProcessTimestamp,
      hideDialog,
      setUploads,
      setHideDialog,
      doHideDialog,
      uploads: [],
    };
  }, [handleStartUpload, setAutoProcessTimestamp, hideDialog, setHideDialog, doHideDialog]);
  //carefull not to place these in same contexts, this one rerenders like crazy
  const memoizedUploads = useMemo(() => uploads, [uploads]);

  return (
    <allUploadsContext.Provider value={{ uploads: memoizedUploads }}>
      <uploadContext.Provider value={memoizedValue}>{children}</uploadContext.Provider>
    </allUploadsContext.Provider>
  );
};

function useUploadLogic() {
  const context = useContext(uploadContext);
  if (context === undefined) {
    throw new Error('useUploadLogic must be used within a UseUploadLogicProvider');
  }
  return context;
}

function UseUploadingTimestamps() {
  const context = useContext(uploadingContext);
  if (context === undefined) {
    throw new Error('useUploadingContext must be used within a UseUploadingTimestampsProvider');
  }
  return context;
}

function useAllUploadsLogic() {
  const context = useContext(allUploadsContext);
  if (context === undefined) {
    throw new Error('useUploadLogic must be used within a UseUploadLogicProvider');
  }
  return context;
}

function withUploadLogic(Component) {
  return function WrapperComponent(props) {
    const uploadLogic = useUploadLogic();
    return <Component {...props} uploadLogic={uploadLogic} />;
  };
}

export {
  UseUploadLogicProvider,
  useAllUploadsLogic,
  useUploadLogic,
  withUploadLogic,
  UseUploadingTimestamps,
  UseUploadingTimestampsProvider,
};
