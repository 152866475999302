export function convertBytesToMbsOrKbs(filesize) {
  let size = '';
  if (filesize >= 1048576) {
    size = Number(filesize / 1048576).toFixed(2) + ' MB';
  } else if (filesize >= 1024) {
    size = Number(filesize / 1024).toFixed(2) + ' KB';
  } else {
    size = Number(filesize).toFixed(2) + ' B';
  }
  return size;
}
