import React, { useState } from 'react';

import {
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import useStyles from './useFormSideStyles';
import ApiManager from 'ApiManager';
import { LinkStyleButton } from 'ReusableComponents';

const FirstFormTab = ({
  validationToken,
  username,
  handleUsernameChange,
  email,
  handleEmailChange,
  termsChecked,
  handleTermsChange,
  password,
  handlePasswordChange,

  errors,
}) => {
  const { classes } = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className={classes.textFieldContainer}>
        <TextField
          label="Username*"
          InputLabelProps={{ shrink: true }}
          placeholder="e.g. yourUsername"
          value={username}
          onChange={handleUsernameChange}
          variant="outlined"
          type="text"
          autoComplete="username"
          className={classes.textField}
          helperText={errors.username}
          error={errors.username !== ''}
        />
      </div>

      {!validationToken && (
        <div className={classes.textFieldContainer}>
          <TextField
            label="Email Address*"
            InputLabelProps={{ shrink: true }}
            placeholder="e.g. you@company.com"
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            type="email"
            autoComplete="email"
            className={classes.textField}
            helperText={errors.email}
            error={errors.email !== ''}
          />
        </div>
      )}
      <div className={classes.textFieldContainer}>
        <TextField
          label="Password*"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            sx: { pr: 0.5 },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword((prev) => !prev)}
                  onMouseDown={(e) => e.preventDefault()}
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Enter a strong password"
          value={password}
          onChange={handlePasswordChange}
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          autoComplete="new-password"
          className={classes.textField}
          helperText={errors.password}
          error={errors.password !== ''}
        />
      </div>
      {ApiManager.cloud === 'ellipsis' && (
        <div className={classes.textFieldContainer}>
          <FormControlLabel
            sx={{ gap: 1, m: 0 }}
            control={<Checkbox color="primary" checked={termsChecked} onChange={handleTermsChange} />}
            label={
              <Typography
                component="div"
                sx={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'baseline', m: 0 }}
              >
                <Typography component="span">Agree to</Typography>
                <LinkStyleButton
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  href="https://ellipsis-drive.com/terms"
                  sx={{ '&&': { m: 0 } }}
                >
                  Terms of Service
                </LinkStyleButton>

                <Typography component="span">and</Typography>
                <LinkStyleButton
                  component="a"
                  target="_blank"
                  rel="noreferrer"
                  href="https://ellipsis-drive.com/privacy"
                  sx={{ '&&': { m: 0 } }}
                >
                  Privacy Policy
                </LinkStyleButton>
              </Typography>
            }
          />
          {errors.terms && (
            <FormHelperText className={classes.termsErrorText}>Agreement is required for sign up.</FormHelperText>
          )}
        </div>
      )}
    </>
  );
};

export default FirstFormTab;
