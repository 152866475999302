import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useDrop } from 'react-dnd';

import MenuItem from './MenuItems/MenuItem';
import { ItemTypes } from 'Drive/ItemTypes';
import { useMinimalAuth /* , useQueryParams */ } from 'hooks';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import ApiManager from 'ApiManager';

const useStyles = makeStyles({
  name: 'SidebarButton',
})((theme) => ({
  newProject: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 64,
    padding: theme.spacing(2, 2),
    paddingRight: theme.spacing(3),
    textAlign: 'left',
    gap: theme.spacing(1.5),
    transition: theme.transitions.create(['background-color', 'color', 'box-shadow']),
    '& svg.MuiSvgIcon-root': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiButton-startIcon': { marginRight: 0 },
    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'bf',
      boxShadow: `0px 3px 5px -1px ${theme.palette.primary.dark}33, 0px 6px 10px 0px ${theme.palette.primary.dark}24, 0px 1px 18px 0px ${theme.palette.primary.dark}1f`,
    },
  },
}));

const SidebarButton = (props) => {
  const { classes } = useStyles();
  const user = useMinimalAuth();
  const { currentFolderInfo } = useCurrentFolder();

  const folderInfoRef = useRef(currentFolderInfo);

  useEffect(() => {
    folderInfoRef.current = currentFolderInfo;
  }, [currentFolderInfo]);

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.GRID_ITEM,
    drop: () => (props.title === 'Trash' ? { name: 'trash', id: '' } : { name: null, id: null }),
    canDrop: (item) => {
      if (props.title === 'Trash') {
        const notSameUser = !!item.items.find((x) => x.owner.id !== user.id);
        return props.title === 'Trash' && !notSameUser;
      } else if (props.title === 'My Drive') {
        return (
          folderInfoRef.current && folderInfoRef.current.yourAccess.accessLevel >= ApiManager.newAccessLevel['admin']
        );
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  // const isTrash = props.title === 'Trash';

  if (props.type === 'newProject') {
    return (
      <Button
        className={classes.newProject}
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={(e) => props.onRepresenting(e)}
        onContextMenu={(e) => {
          e?.preventDefault();
          props.onRepresenting(e);
        }}
        ref={props.title === 'Trash' || props.title === 'My Drive' ? drop : undefined}
      >
        {props.title}
      </Button>
    );
  } else {
    return (
      <MenuItem
        ref={props.title === 'Trash' || props.title === 'My Drive' ? drop : undefined}
        {...props}
        canDrop={canDrop}
        isOver={isOver}
      />
    );
  }
};

export default SidebarButton;

SidebarButton.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  icon: PropTypes.element,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  user: PropTypes.object,
  onRepresenting: PropTypes.func,
};

SidebarButton.defaultProps = {
  type: 'menuItem',
};
