import React, { forwardRef } from 'react';

import { Button, CircularProgress, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles()((theme, { loading }) => ({
  childrenContainer: {
    opacity: loading ? 0 : 1,
  },
  spinnerContainer: {
    opacity: loading ? 1 : 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-12.5px, -12.5px)',
  },
  spinner: {
    color: 'inherit',
  },
}));

const LoadingButton = forwardRef(({ children, className = '', title, loading = false, ...buttonProps }, ref) => {
  const { classes, cx } = useStyles({ loading });

  const button = (
    <Button {...buttonProps} className={cx(classes.button, className)} ref={ref}>
      <div className={classes.childrenContainer}>{children}</div>
      <div className={classes.spinnerContainer}>
        <CircularProgress style={{ height: '25px', width: '25px' }} className={classes.spinner} />
      </div>
    </Button>
  );
  if (title) {
    return (
      <Tooltip title={title}>
        <div>{button}</div>
      </Tooltip>
    );
  } else {
    return button;
  }
});

LoadingButton.displayName = 'LoadingButton';

LoadingButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default LoadingButton;
