import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import React from 'react';

const SpatialFile = ({ cancel, confirm }) => {
  const handleConfirm = () => {
    confirm();
  };

  return (
    <React.Fragment>
      <DialogContent>
        <DialogContentText>
          To use spatial data on a map you need to upload your files to a layer. Pick new layer instead.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={cancel} color={'inherit'}>
          I will create a layer
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Upload anyway
        </Button>
      </DialogActions>
    </React.Fragment>
  );
};

export default SpatialFile;
