import React from 'react';

import { Visibility } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useContextMenu } from '../../ContextMenuContext';

const View = ({ onClose }) => {
  const { changeMainModeAndEnter, currentFolderInfo, changeMainMode, mainMode, onToLocationClick } = useCurrentFolder();

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;
  return (
    <React.Fragment>
      <CombinedMenuItem
        icon={<Visibility />}
        label={'Open in viewer'}
        onClick={() => {
          onClose();
          if (mainMode === '/drive/search') {
            onToLocationClick(path, '/view');
          } else {
            if (currentFolderInfo?.id === path.id) {
              changeMainMode('/view');
            } else {
              changeMainModeAndEnter(path, '/view');
            }
          }
        }}
        openNew
      />
    </React.Fragment>
  );
};

export default View;
