import React from 'react';

import { Pause, PlayArrow } from '@mui/icons-material';

import { MultiSelectComponent } from '../Helpers';

const Active = ({ searchVal, setSearchVal }) => {
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;

    setSearchVal(value);
  };

  const TYPES = [
    { key: 'active', label: 'Active', icon: PlayArrow, color: 'success' },
    { key: 'passive', label: 'Passive', icon: Pause, color: 'warning' },
  ];

  return <MultiSelectComponent value={searchVal || []} items={TYPES} handleChange={handleChange} />;
};

export default Active;
