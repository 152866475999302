import React from 'react';

import { Code, DirectionsWalk, DirectionsBike, Edit } from '@mui/icons-material';

const MINIMUM = 10000000;

const TRANCHES = [
  {
    icon: <DirectionsWalk fontSize="inherit" />,
    label: 'Regular',
    ApM: 10000000,
    tooltip: "Gives 10M PU's. Good for regular users.",
  },
  {
    icon: <DirectionsBike fontSize="inherit" />,
    label: 'Heavy',
    ApM: 50000000,
    tooltip: "Gives 50M PU's. Good for heavy usages.",
  },
  {
    icon: <Code fontSize="inherit" />,
    label: 'Scripting',
    ApM: 250000000,
    tooltip: "Gives 250M PU's. Suggested for scripters.",
  },
  {
    icon: <Edit fontSize="inherit" />,
    label: 'Custom',
    type: 'custom',
    tooltip: "Gives a custom amount of PU's.",
  },
];

export { MINIMUM, TRANCHES };
