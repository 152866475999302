import React from 'react';

import TagManager from 'react-gtm-module';
import { v4 as uuidv4 } from 'uuid';
import * as Sentry from '@sentry/react';
import { ErrorComponent } from 'GlobalErrorBoundary';

const SentryComponent = ({ children }) => {
  const onBeforeCapture = (scope, error) => {
    console.log('error: ', error);
    const errorId = uuidv4();

    TagManager.dataLayer({
      gtmId: 'GTM-K72V8Z6',
      dataLayer: {
        event: 'error',
      },
    });
    TagManager.dataLayer({
      gtmId: 'GTM-K72V8Z6',
      dataLayer: {
        event: `error-${errorId}`,
      },
    });
    scope.setTag('error-id', `error-${errorId}`);
  };

  return (
    <Sentry.ErrorBoundary
      beforeCapture={onBeforeCapture}
      fallback={({ resetError }) => <ErrorComponent reset={resetError} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryComponent;
