import React, { useEffect, useCallback, useRef } from 'react';

import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';

import { ListItem, ListItemSecondaryAction, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProjectTypeIcon from 'ReusableComponents/ProjectTypeIcon/ProjectTypeIcon';
import { ItemTypes } from 'Drive/ItemTypes';
import ApiManager from 'ApiManager';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { DriveMenuButton } from 'Drive/DriveMenu/DriveMenu';
import { AvatarWithInfo, FileIcon, FileNameComponent } from 'ReusableComponents';

const useStyles = makeStyles({ name: 'BreadCrumbLink' })((theme, _params, classes) => ({
  buttonBreadcrumb: {
    textTransform: 'none',
    minWidth: 'auto',
    color: theme.palette.text.secondary,
    border: '1px solid transparent',
    borderRadius: '8px',
    [`&.${classes.active}`]: { color: theme.palette.text.primary },
  },
  icon: { minWidth: theme.spacing(4) },
  link: {
    textDecoration: 'none',
    border: '1px solid transparent',
  },
  separator: {
    margin: '0px 2px',
  },
  active: {},
  canDrop: {
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 0,
  },
  droppable: {
    background: theme.palette.primary.main + '20',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  avatar: { marginLeft: theme.spacing(-1) },
  isRoot: { padding: theme.spacing(0.5, 2), paddingLeft: theme.spacing(1.5) },
}));

const BreadcrumbLink = React.forwardRef(({ folder, content, active = false, disabled, owner }, ref) => {
  const { classes, cx } = useStyles();

  const isRoot = !folder;
  const { onBreadcrumbClick, root, currentFolderInfo, mainMode } = useCurrentFolder();

  // needed because useDrop doesn't do well with changing props
  const currentRoot = useRef(root);
  useEffect(() => {
    let canUpdate = true;
    if (!!canUpdate) {
      currentRoot.current = root;
    }

    return () => {
      canUpdate = false;
    };
  }, [root]);

  const canDropFunc = useCallback(
    (item) => {
      // if in shared, check that owner === targetOwner and that accessLevel >= 1000
      const condition =
        (mainMode === '/drive/me' && !folder && !!currentFolderInfo) ||
        (folder?.id !== currentFolderInfo?.id &&
          (mainMode === '/drive/me' || mainMode === '/drive/shared') &&
          folder?.info?.yourAccess?.accessLevel >= ApiManager.newAccessLevel['edit+']);

      return condition;
    },
    [folder, mainMode, currentFolderInfo]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.GRID_ITEM,
      drop: () => ({ name: folder?.name, id: folder?.id }),
      canDrop: canDropFunc,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [canDropFunc]
  );

  const handleClick = () => {
    if ((folder && currentFolderInfo && folder.id === currentFolderInfo.id) || (!folder && !currentFolderInfo)) {
      return;
    }
    onBreadcrumbClick(isRoot ? null : folder.id);
  };

  return (
    <ListItem
      ContainerComponent="div"
      button
      dense
      ref={drop}
      componentsProps={{ root: { ref } }}
      color="inherit"
      className={cx(
        classes.buttonBreadcrumb,
        classes.link,
        isRoot && classes.isRoot,
        canDrop && classes.canDrop,
        isOver && canDrop && classes.droppable,
        active && classes.active
      )}
      onClick={handleClick}
      disabled={!!disabled || (!folder?.info && !isRoot)}
    >
      {((currentFolderInfo && isRoot) || (folder && folder.info && folder.info?.type !== 'folder')) && (
        <ListItemIcon className={classes.icon}>
          {isRoot ? (
            <AvatarWithInfo size={18} user={owner} className={classes.avatar} />
          ) : currentFolderInfo?.type === 'file' ? (
            <FileIcon file={currentFolderInfo} type="round" size={24} />
          ) : (
            <ProjectTypeIcon type={folder?.info.type} style={{ width: 18, height: 18, marginRight: 8 }} />
          )}
        </ListItemIcon>
      )}
      {folder?.info?.type === 'file' ? (
        <FileNameComponent name={folder?.name} />
      ) : (
        <ListItemText primary={content || folder?.name} primaryTypographyProps={{ noWrap: true }} />
      )}
      {!isRoot && active && (
        <ListItemSecondaryAction>
          <DriveMenuButton edge="end" size="small" type={'single'} path={currentFolderInfo} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
});

BreadcrumbLink.displayName = 'BreadcrumbLink';

BreadcrumbLink.propTypes = {
  folder: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // dropEnabled: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  root: PropTypes.string,
};

export default BreadcrumbLink;
