import React from 'react';
import { ListSubheader } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

const useStyles = makeStyles({ name: 'Section' })((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '&:first-child': {
      marginTop: '6px',
    },
  },
  title: {
    top: 52,
  },
}));

const Section = ({ children, title }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <ListSubheader className={classes.title}>{title}</ListSubheader>
      {children}
    </div>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Section;
