import React from 'react';

import { Folder } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { getMainModeFromRoot, pathToRoot, useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useContextMenu } from '../../ContextMenuContext';
import { useMinimalAuth } from 'hooks';
import VersionUtility from 'VersionUtility';
import ApiManager from 'ApiManager';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const OpenLocation = ({ onClose }) => {
  const { onToLocationClick } = useCurrentFolder();
  const history = useHistory();
  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;
  const user = useMinimalAuth();
  const handleClick = async () => {
    onClose();

    if (path.path.path.length > 1) {
      const folderId = path.path.path[1].id;
      const res = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${folderId}`, null, user));
      onToLocationClick(res);
    } else {
      const url = getMainModeFromRoot(pathToRoot(path));
      history.push(url);
    }
  };

  return <CombinedMenuItem icon={<Folder />} label={'Show in location'} onClick={handleClick} />;
};

export default OpenLocation;
