import React, { useRef, useState } from 'react';

import { Button, CircularProgress, Divider, Skeleton, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import './custom.css';
import 'simplemde/src/css/simplemde.css';
import MarkdownWrapper from './MarkdownWrapper';

import { retryableLazy } from 'globalUtils';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';

const MarkdownEditor = retryableLazy(() => import('react-markdown-editor-smde'));

const MAX_LENGTH = 255;

const useStyles = makeStyles({
  name: 'Editor',
})((theme, { backgroundColor }, classes) => ({
  root: {},
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(1),
    alignItems: 'stretch',
    [`&.${classes.small}`]: { gridTemplateColumns: '1fr' },
    [theme.breakpoints.down('sm')]: { gridTemplateColumns: '1fr' },
    paddingBottom: theme.spacing(6),
    '& .md-editor': {
      minWidth: 0,
      '& .editor-toolbar': { overflowY: 'hidden' },
      '& .editor-toolbar, & .CodeMirror': { background: backgroundColor },
    },
    [`&.${classes.disableButtons}`]: { paddingBottom: 0 },
  },
  preview: {},
  error: {
    '& .md-editor>*': { borderColor: theme.palette.error.main },
    '& .keystrokes': { color: theme.palette.error.main },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: !!backgroundColor ? backgroundColor : 'white',
  },
  small: {
    [`& .${classes.preview}`]: { marginBottom: theme.spacing(2) },
    [`& .CodeMirror, & .CodeMirror-scroll, & .${classes.wrapper}`]: { minHeight: 75 },
  },
  disableButtons: {},
  wrapper: {},
}));

const Editor = React.forwardRef(
  (
    {
      initialValue,
      cb = () => {},
      small = false,
      backgroundColor,
      disableButtons,
      overrideState: [overrideValue, setOverrideValue] = [],
      maxLength = MAX_LENGTH,
      title = 'Description Preview',
    },
    ref
  ) => {
    const { classes: styles, cx } = useStyles({ backgroundColor });

    const el = useRef();

    const [value, setValue] = useState(initialValue);

    const onSubmit = () => cb(el?.current?.mdValue);

    const doReset = () => {
      if (el.current) {
        el.current.mdValue = initialValue;
      }
    };

    const tooLong = el?.current?.mdValue?.length > maxLength;
    const isSame = el?.current?.mdValue === initialValue;

    return (
      <div className={styles.root} ref={ref}>
        <div
          className={cx(
            styles.grid,
            small && styles.small,
            disableButtons && styles.disableButtons,
            tooLong && styles.error
          )}
        >
          <SuspenseContainer
            fallback={
              <Skeleton
                variant="rectangular"
                width="90%"
                height="unset"
                sx={{
                  margin: '0 auto',
                  borderRadius: 1,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress sx={{ color: 'text.secondary', '&&': { visibility: 'unset' } }} />
              </Skeleton>
            }
            displayName="MarkdownEditor"
          >
            <MarkdownEditor
              key={'editor'}
              ref={el}
              initialValue={initialValue}
              toolbar={['bold', 'italic', 'strikethrough', '|', 'unordered-list', 'ordered-list', '|', 'link']}
              editorConfig={{
                insertTexts: { link: ['[', '](https://)'] },
                status: [
                  {
                    className: 'keystrokes',
                    defaultValue: function (e) {
                      e.innerHTML = `Length: ${el?.current?.mdValue?.length || initialValue?.length || 0}/${maxLength}`;
                    },
                    onUpdate: function (e) {
                      !!setOverrideValue ? setOverrideValue(el?.current?.mdValue) : setValue(el?.current?.mdValue);
                      e.innerHTML = `Length: ${el?.current?.mdValue?.length}/${maxLength}`;
                    },
                  },
                ],
              }}
              moreExtraKeys={{ 'Ctrl-Enter': () => !(tooLong || isSame) && onSubmit(el?.current?.mdValue) }}
            />
          </SuspenseContainer>
          <div className={styles.preview}>
            <Typography variant="subtitle2">{title}</Typography>
            <Divider sx={{ my: 1, mb: 1.5 }} />
            <MarkdownWrapper className={styles.wrapper}>{!!setOverrideValue ? overrideValue : value}</MarkdownWrapper>
          </div>
        </div>
        {!disableButtons && (
          <div className={styles.buttonContainer}>
            <Button variant="outlined" onClick={doReset} disabled={isSame}>
              Reset
            </Button>
            <Button variant="contained" onClick={onSubmit} disabled={tooLong || isSame}>
              Submit
            </Button>
          </div>
        )}
      </div>
    );
  }
);

export default Editor;

Editor.displayName = 'Editor';
