import React, { createContext, useContext, useState, useCallback, useRef, useMemo } from 'react';
import { isValidTimestamp } from 'ReusableComponents/ValidLayer/ValidLayer';

export const defaultModelInfoObject = (model) => {
  const timestamps = model.timestamps.filter((t) => isValidTimestamp(t).available);
  const res = {
    band: model.bands[0],
    timestamps: timestamps,
    timestamp: timestamps.length - 1,
    distortion: 1,
  };

  return res;
};

const elevationModelsContext = createContext();

export const ElevationModelsProvider = ({ children }) => {
  // all of the selected elevationModels
  const [elevationModels, setElevationModels] = useState({ models: [], modelInfo: {} });
  const cachedElevationModels = useRef([]);
  const selectedElevationModel = elevationModels.models.find((m) => m.isSelected);

  const addElevationModel = useCallback((model) => {
    setElevationModels((prevModels) => {
      const info = defaultModelInfoObject(model);
      return {
        modelInfo: { ...prevModels.modelInfo, [model.id]: info },
        models: [
          { ...model, isSelected: true },
          ...prevModels.models.map((m) => {
            return { ...m, isSelected: false };
          }),
        ],
      };
    });
  }, []);

  const removeElevationModel = useCallback((model) => {
    setElevationModels((prevModels) => {
      const newModelsInfo = { ...prevModels.modelInfo };
      delete newModelsInfo[model.id];
      return { ...prevModels, modelsInfo: newModelsInfo, models: prevModels.models.filter((m) => model.id !== m.id) };
    });
  }, []);

  const selectModel = useCallback((model) => {
    setElevationModels((prevModels) => {
      let newModels = prevModels.models.map((m) => {
        if (m.id === model.id) {
          m = { ...m, isSelected: true };
        } else {
          m = { ...m, isSelected: false };
        }
        return m;
      });
      return {
        ...prevModels,
        models: newModels,
      };
    });
  }, []);

  const deselectModel = useCallback((model) => {
    setElevationModels((prevModels) => {
      let newModels = prevModels.models.map((m) => {
        if (m.id === model.id) {
          m = { ...m, isSelected: false };
        } else {
          m = { ...m };
        }
        return m;
      });
      return {
        ...prevModels,
        models: newModels,
      };
    });
  }, []);

  const updateModelInfo = useCallback((model, info) => {
    setElevationModels((prevModels) => {
      return {
        ...prevModels,
        modelInfo: { ...prevModels.modelInfo, [model.id]: { ...prevModels.modelInfo[model.id], ...info } },
      };
    });
  }, []);

  const updateModel = useCallback((newModel) => {
    setElevationModels((prevModels) => {
      return {
        ...prevModels,
        models: prevModels.models.map((m) => {
          if (m.id === newModel.id) {
            return { ...m, ...newModel };
          } else {
            return m;
          }
        }),
      };
    });
  }, []);

  const loadElevationModels = useCallback(({ models, modelInfo }) => {
    cachedElevationModels.current = models;
    setElevationModels({ models: models, modelInfo: modelInfo });
  }, []);

  const value = useMemo(
    () => ({
      models: elevationModels.models,
      modelsInfo: elevationModels.modelInfo,
      cachedElevationModels,
      setElevationModels,
      updateModelInfo,
      selectModel,
      updateModel,
      deselectModel,
      selectedModel: selectedElevationModel,
      addElevationModel,
      removeElevationModel,
      loadElevationModels,
    }),
    [
      elevationModels,
      cachedElevationModels,
      setElevationModels,
      updateModel,
      updateModelInfo,
      selectModel,
      deselectModel,
      selectedElevationModel,
      addElevationModel,
      removeElevationModel,
      loadElevationModels,
    ]
  );

  return <elevationModelsContext.Provider value={value}>{children}</elevationModelsContext.Provider>;
};

export function useElevationModels(props) {
  const { suppressError = false } = { ...props };

  const context = useContext(elevationModelsContext);
  if (context === undefined && !suppressError) {
    throw new Error('useElevationModels must be used within a ElevationModelsProvider');
  }
  return context || (!!suppressError && {});
}

export function withElevationModels(Component) {
  return function WrapperComponent(props) {
    const context = useElevationModels();
    return <Component {...props} {...context} />;
  };
}

export function withElevationModelsContext(Component) {
  return function WrapperComponent(props) {
    return (
      <ElevationModelsProvider>
        <Component {...props} />
      </ElevationModelsProvider>
    );
  };
}
