import React from 'react';
import PropTypes from 'prop-types';

import DateColumnSettings from './DateColumnSettings';
import FastUpload from './FastUpload';
import DownSample from './DownSample';

const ShapeSettings = ({
  geometryMethod,
  setGeometryMethod,
  highestZoom,
  setHighestZoom,
  dateColumns,
  fastUpload,
  setFastUpload,
  downsample,
  setDownsample,
  setDateColumns,
  datePatterns,
  setDatePatterns,
}) => {
  return (
    <>
      <DateColumnSettings
        dateColumns={dateColumns}
        setDateColumns={setDateColumns}
        datePatterns={datePatterns}
        setDatePatterns={setDatePatterns}
      />
      <FastUpload setFastUpload={setFastUpload} fastUpload={fastUpload} />
      {!fastUpload && <DownSample setDownsample={setDownsample} downsample={downsample} />}
    </>
  );
};

ShapeSettings.propTypes = {
  geometryMethod: PropTypes.string.isRequired,
  setGeometryMethod: PropTypes.func.isRequired,
  highestZoom: PropTypes.number.isRequired,
  setHighestZoom: PropTypes.func.isRequired,
  dateColumns: PropTypes.arrayOf(PropTypes.string),
  setDateColumns: PropTypes.func.isRequired,
  datePatterns: PropTypes.arrayOf(PropTypes.string),
  setDatePatterns: PropTypes.func.isRequired,
};

export default ShapeSettings;
