import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import ApiManager from 'ApiManager';

import { SectionTitle, useMainContext } from '../../../ReusableComponents';
import { makeStyles } from 'tss-react/mui';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from '@mui/material';
import { useAuth, useAuthFuncs } from '../../AuthContext';
import { withStyles } from 'tss-react/mui';
import { Link } from '@mui/material';
import { DangerButton } from 'MapManagement/GeneralSettings/DangerZone/DangerZone';

const StyledLink = withStyles(Link, (theme) => ({
  root: {
    padding: theme.spacing(0, 3),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    cursor: 'pointer',
    display: 'flex',
  },
}));

const useStyles = makeStyles()((theme) => ({
  emptyContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  emptyTitle: {
    textAlign: 'center',
    marginBottom: '16px',
  },
  emptySubTitle: {
    marginBottom: '16px',
  },
  newAppButton: {
    minWidth: '212px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  redirectSection: {
    marginTop: '30px',
    marginBottom: '20px',
  },
  listTitleContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '6px',
    height: 'fit-content',
  },
  listButton: {
    minWidth: 'fit-content',
  },
  listContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
  },
  listItem: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  listAction: {
    padding: 0,
  },
  secretButton: {
    marginTop: '10px',
  },
  content: {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: '6px',
    width: 'calc(100% - 32px)',
    padding: ' 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    '&+&': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  textContainer: {
    maxWidth: '400px',
  },
  groupTitle: {},
  caption: {
    marginBottom: '6px',
    marginTop: '-8px',
  },
  error: { color: theme.palette.error.main },
}));

const AccountSecurity = () => {
  const history = useHistory();
  const user = useAuth();
  const { classes, cx } = useStyles();
  const { signout, removeAccount } = useAuthFuncs();
  const { onOpenSnackbar } = useMainContext();

  const handleClick = (type) => {
    if (type === 'delete') {
      ApiManager.delete('/v3/account', undefined, user)
        .then((r) => {
          removeAccount(user, () => {
            signout(() => {
              history.replace('/');
              onOpenSnackbar({
                level: 'success',
                content: 'Your account has been queued for deletion.',
              });
            });
          });
        })
        .catch((e) => console.error(e));
    } else {
      history.push('/login?userId=' + user.id, {
        from: `/update-credentials?type=${type}`,
        update: type,
      });
    }
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [openDelete, setOpenDelete] = useState(false);
  const [text, setText] = useState('');
  const handleCloseDelete = () => {
    setOpenDelete(false);
    setText('');
  };

  console.log('userx', user);

  if (user.openId.providers.length > 0 && !user.openId.configuredPassword) {
    return (
      <div>
        <SectionTitle first={true}>
          <SectionTitle.SectionTitleText>Update security settings</SectionTitle.SectionTitleText>
        </SectionTitle>

        <div className={classes.emptyContainer}>
          <Typography className={classes.emptyTitle}>No Ellipsis Drive password configured</Typography>
          <Typography className={classes.emptySubTitle}>
            To make use of Ellipsis Drive login you have to configure an Ellipsis Drive password first.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={classes.newAppButton}
            onClick={() => {
              const body = {
                email: user.email.trim(),
              };
              ApiManager.post('/v3/account/security/password/reset', body).then((r) => {
                history.push(`/notification?type=setPasswordRequest&userId=${user.id}`);
              });
            }}
          >
            Set up Ellipsis Drive password{' '}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>Update security settings</SectionTitle.SectionTitleText>
      </SectionTitle>

      <StyledLink onClick={() => handleClick('username')}>Update username</StyledLink>
      <StyledLink onClick={() => handleClick('password')}>Update password</StyledLink>
      <StyledLink onClick={() => (user.openId.providers.length > 0 ? setOpen(true) : handleClick('email'))}>
        Update email
      </StyledLink>
      <SectionTitle border={false} style={{ marginBottom: 0 }}>
        <SectionTitle.SectionTitleText TypographyProps={{ color: 'error' }}>Danger Zone</SectionTitle.SectionTitleText>
      </SectionTitle>
      <div className={classes.content}>
        <section className={classes.section}>
          <div className={classes.textContainer}>
            <Typography
              component="h2"
              variant="subtitle2"
              className={cx(classes.groupTitle, classes.error)}
              gutterBottom
            >
              Delete Account
            </Typography>

            <Typography variant="body1" className={classes.caption}>
              Deleting your account is irreversible.
            </Typography>
          </div>
          <DangerButton onClick={() => setOpenDelete(true)} alignRight>
            Delete My Account
          </DangerButton>
        </section>
      </div>
      <Dialog open={openDelete} keepMounted onClose={handleCloseDelete}>
        <DialogTitle>DELETING ACCOUNT</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to remove your account. This step is irreversible. <br /> Type your username below to proceed.
          </DialogContentText>
          <TextField
            value={text}
            label="Username"
            onChange={(e) => setText(e?.target?.value)}
            fullWidth
            sx={{ mt: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={
              text?.length === 0
                ? 'Enter your Username'
                : user?.username !== text
                ? 'Username does not match input'
                : ''
            }
          >
            <span>
              <Button onClick={() => handleClick('delete')} color="error" disabled={user?.username !== text}>
                Yes, proceed
              </Button>
            </span>
          </Tooltip>
          <Button onClick={handleClose} color="inherit">
            No, abort
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Decoupling your openId!'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{`Updating your email would decouple your open Id. This means you will no longer be able to log in with ${user.openId?.providers[0]?.provider}. Are you sure you wish to proceed?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClick('email')} color="primary">
            Yes, proceed
          </Button>
          <Button onClick={handleClose} color="inherit">
            No, abort
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AccountSecurity;
