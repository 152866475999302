import React /* , { useMemo } */ from 'react';
import copy from 'copy-to-clipboard';

import { Link } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useMainContext } from 'ReusableComponents';
import { useContextMenu } from '../../ContextMenuContext';

const View = ({ onClose }) => {
  const { onOpenSnackbar } = useMainContext();

  const { menuOptions } = useContextMenu();
  const { /* type, */ path } = menuOptions;

  const copyToClipboard = () => {
    let link;
    if (path.type === 'bookmark') {
      link = `${window.location.host}/view?pathId=${path?.id}`;
    } else {
      link = `${window.location.host}/drive/external?pathId=${path?.id}`;
    }

    copy(link);
    onOpenSnackbar({
      content: 'link to "' + path?.name + '" copied',
      level: 'success',
    });

    onClose();
  };

  return <CombinedMenuItem icon={<Link />} action={copyToClipboard} label="Copy Sharable Link" />;
};

export default View;
