import React from 'react';

import { Power } from '@mui/icons-material';
import IntegrateComponent from '../Components/Integrate/Integrate';
import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useContextMenu } from '../../ContextMenuContext';

const Integrate = ({ onClose, setDialog }) => {
  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const manageIntegrate = () => {
    setDialog({
      title: `Integrate this ${path?.type === 'folder' ? 'folder' : path?.type === 'bookmark' ? 'bookmark' : 'layer'}`,
      content: <IntegrateComponent path={path} />,
    });

    onClose();
  };

  return <CombinedMenuItem icon={<Power />} label="Integrate" action={manageIntegrate} openDialog />;
};

export default Integrate;
