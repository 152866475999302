import { glbToGeojson } from 'Viewer/ViewerUtility';
import ApiManager from '../../../ApiManager';

///////////////////////////////////hint about what the objects should look like
/*
tilesInView = {tiles:{0:[], 1:[], ...}, zoom:zoomLevel}
tilesCache = {f
  exampleLayerId: {
    '0_0_0': { featureIds: [], amount: 0, size: 0, nextPageStart: null, fullyDone:false },
  },
}
featuresCache = {exampleFeatureId:{ lod: feature, renderKey: 1 }}

*/

let tilesCache = {};
let featuresCache = {};
let tilesInView = {
  zoom: 0,
  tiles: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: [],
    10: [],
    11: [],
    12: [],
    13: [],
    14: [],
    15: [],
    16: [],
  },
};
let pointCloudCache = {};

let updated = {};

let FAKE_ID = 0;

const MAX_AMOUNT_TILE = 8 * 1000;
const MAX_SIZE_TILE = 4 * 1000 * 1000;
const STEP_SIZE = 500;
const WAIT_RENDER_TIME = 1;
const MAX_TILES = 30;
/////////////////////////////

export const getLod = (zoom, useLod) => {
  const maxLod = 6;

  if (!useLod) {
    return maxLod;
  }

  if (zoom < 4) {
    return Math.max(1, maxLod - 5);
  } else if (zoom < 6) {
    return Math.max(1, maxLod - 4);
  } else if (zoom < 11) {
    return Math.max(1, maxLod - 3);
  } else if (zoom <= 14) {
    return Math.max(1, maxLod - 2);
  } else if (zoom < 17) {
    return Math.max(1, maxLod - 1);
  } else {
    return maxLod - 0;
  }
};

let fetching = false;
let step = 0;
export const fetchDataForLayers = async (layers, layersInfo, user, cb, useServerSide = false, overRideParams = {}) => {
  if (fetching) return;
  fetching = true;

  const vectorLayers = layers.filter((l) => l.type === 'shape');

  for (let i = 0; i < vectorLayers.length; i++) {
    const layer = vectorLayers[i];
    const layerInfo = layersInfo[layer.id];

    await fetchDataForLayer(layer, layerInfo, user, useServerSide, overRideParams);
  }

  const pointCloudLayers = layers.filter((l) => l.type === 'pointCloud');

  for (let i = 0; i < pointCloudLayers.length; i++) {
    const layer = pointCloudLayers[i];
    const layerInfo = layersInfo[layer.id];

    await fetchDataForPointCloud(layer, layerInfo, user, useServerSide, overRideParams);
  }

  step = (step + 1) % 3;
  fetching = false;
};

export const getLayerZoom = (layer, layerInfo, zoom) => {
  zoom = Math.min(layerInfo.tilePyramid.zoom, zoom);
  if (layer.yourAccess.maxZoom) {
    zoom = Math.min(layer.yourAccess.maxZoom, zoom);
  }

  return zoom;
};

const getTilesAbove = (tile, useLod, asIds = true) => {
  const initialZoom = tile.zoom;
  const initialLod = getLod(tile.zoom, useLod);
  let zoom = tile.zoom;
  let tiles = [];
  while (zoom > 0) {
    if (initialLod <= getLod(zoom, useLod)) {
      const t = {
        zoom: zoom,
        tileX: Math.floor(tile.tileX / 2 ** (initialZoom - zoom)),
        tileY: Math.floor(tile.tileY / 2 ** (initialZoom - zoom)),
      };

      if (asIds) {
        tiles.push(makeId(t));
      } else {
        tiles.push(t);
      }
    }
    zoom = zoom - 1;
  }

  return tiles;
};

const makeId = (tile) => {
  return tile.zoom + '_' + tile.tileX + '_' + tile.tileY;
};

const getNextPageStart = (tilesCacheForLayer, tile, useLod, timestampId) => {
  const tileIds = getTilesAbove(tile, useLod);
  let nextPageStart = precomputedRender[timestampId]?.nextPageStart;

  for (let i = 0; i < tileIds.length; i++) {
    const tileId = tileIds[i];
    if (tilesCacheForLayer && tilesCacheForLayer[tileId] && tilesCacheForLayer[tileId].nextPageStart) {
      nextPageStart = comparePageStarts(nextPageStart, tilesCacheForLayer[tileId].nextPageStart);
    }
  }
  return nextPageStart;
};

const comparePageStarts = (page1, page2) => {
  let newNextPageStart = page1;

  if (page2) {
    if (!page1 || ((!page1.value || page2.value >= page1.value) && page2.featureId > page1.featureId)) {
      newNextPageStart = page2;
    }
  }

  return newNextPageStart;
};

const getUseLod = (timestamp) => {
  let useLod = false;
  if (
    true ||
    (timestamp.statistics &&
      timestamp.statistics.levelsOfDetailFraction &&
      timestamp.statistics.levelsOfDetailFraction['level1'] > 0)
  ) {
    useLod = true;
  }

  return useLod;
};

export const setTilesInView = (x) => {
  tilesInView = x;
};

const precomputedRender = {};

const getPrecompute = async (layer, timestamp, layerInfo, user, timestampId, useServerSide) => {
  precomputedRender[timestampId] = { done: false };
  const style = layerInfo.style;
  let result;

  if (style.method === 'fromColorProperty' || style.method === 'singleColor') {
    result = await ApiManager.get(
      `/v3/path/${layer.id}/vector/timestamp/${timestamp.id}/compressedListFeatures`,
      null,
      user
    );
    result.done = true;

    result.gottenAll = !result.nextPageStart;
    result.nextPageStart = { featureId: result.nextPageStart };
    result.result.features = result.result.features.map((f) => {
      if (style.method === 'singleColor') {
        f.properties.color = style.parameters.color;
      } else if (!f.properties.color) {
        f.properties.color = style.parameters.defaultColor;
      }
      if (!useServerSide) {
        f.properties.color = f.properties.color?.slice(0, 7);
      }
      return f;
    });
  } else {
    result = { done: true, result: { features: [] }, gottenAll: false };
  }

  precomputedRender[timestampId] = result;
};

export const fetchDataForPointCloud = (layer, layerInfo, user, useServerSide, overRideParams) => {
  const zoom = tilesInView.zoom; //Math.min(tilesInView.zoom, layer.timestamps[0].zoom);
  const tiles = tilesInView.tiles[zoom];

  const timestamp = layerInfo.timestampRange.timestamps[layerInfo.timestampRange.end];
  if (!pointCloudCache[timestamp.id]) {
    pointCloudCache[timestamp.id] = {};
  }
  for (let i = 0; i < tiles.length; i++) {
    const tile = tiles[i];
    const tileId = tile.zoom + '_' + tile.tileX + '_' + tile.tileY;
    const url = `/v3/path/${layer.id}/pointCloud/timestamp/${timestamp.id}/tile/${tile.zoom}/${tile.tileX}/${tile.tileY}?zipTheResponse=true`;

    if (!pointCloudCache[timestamp.id][tileId]?.done) {
      pointCloudCache[timestamp.id][tileId] = { done: true };
      ApiManager.get(url, null, user)
        .then((res) => {
          const cb = (finalRes) => {
            pointCloudCache[timestamp.id][tileId] = { done: true, result: finalRes.features };
            updated[timestamp.id] = true;
          };

          glbToGeojson(res, cb, 6000, true, tile);
        })
        .catch((e) => {
          pointCloudCache[timestamp.id][tileId] = { done: true, result: [] };
        });
    }
  }
};

const hackyColor = '#010101';
export const fetchDataForLayer = async (layer, layerInfo, user, useServerSide = false, overRideParams = {}) => {
  const timestamp = layerInfo.timestampRange.timestamps[layerInfo.timestampRange.end];
  const useLod = getUseLod(timestamp);

  const zoom = getLayerZoom(layer, layerInfo, tilesInView.zoom);

  let M = overRideParams.MAX_AMOUNT_TILE ? overRideParams.MAX_AMOUNT_TILE : MAX_AMOUNT_TILE;
  let MS = overRideParams.MAX_SIZE_TILE ? overRideParams.MAX_SIZE_TILE : MAX_SIZE_TILE;

  let timestampId = timestamp.id;

  timestampId = timestampId + '_' + JSON.stringify(layerInfo.filter);

  if (useServerSide) {
    timestampId = timestampId + '_' + JSON.stringify(layerInfo.style);
  }

  if (
    !(
      layer.vector.properties.find((x) => x.private) &&
      layer.yourAccess.accessLevel < ApiManager.newAccessLevel['fullView']
    ) &&
    layerInfo.filter.length === 0 &&
    !useServerSide &&
    timestamp.statistics?.precomputed &&
    zoom <= timestamp.statistics.precomputeZoom
  ) {
    return;
  }

  const relevant = useServerSide && timestamp.statistics?.precomputed && layerInfo.filter.length === 0;

  if (!precomputedRender[timestampId] && relevant) {
    getPrecompute(layer, timestamp, layerInfo, user, timestampId, useServerSide);

    return;
  }

  if (relevant && !precomputedRender[timestampId]?.done) {
    return;
  }

  if (relevant && precomputedRender[timestampId]?.gottenAll) {
    return;
  }

  if (tilesInView.tiles[zoom].length > 24) {
    console.log('too many tiles', tilesInView.tiles[zoom].length);
  }

  let tiles = tilesInView.tiles[zoom].filter(
    (t) =>
      !tilesCache[timestampId] ||
      !tilesCache[timestampId][t.zoom + '_' + t.tileX + '_' + t.tileY] ||
      ((tilesCache[timestampId][makeId(t)].size < MS || zoom === 16) &&
        (tilesCache[timestampId][makeId(t)].amount < M || zoom === 16) &&
        !tilesCache[timestampId][makeId(t)].fullyLoaded)
  );

  let zooms = tiles.map((t) => t.zoom);
  zooms = [...new Set(zooms)];
  zooms.sort(function (a, b) {
    return a - b;
  });
  zooms = zooms.reverse();

  let keepZooms = zooms;
  if (step === 0 && zooms.length > 1) {
    keepZooms = [zooms[0], zooms[1]];
  } else if (step === 0 && zooms.length > 3) {
    keepZooms = [zooms[0], zooms[1], zoom[2], zoom[3]];
  }

  tiles = tiles.filter((t) => keepZooms.includes(t.zoom));

  let body = {
    returnType: 'all',
    zipTheResponse: true,
    pageSize: STEP_SIZE,
  };
  if (layerInfo.filter.length > 0) {
    body.propertyFilter = layerInfo.filter;
  }

  if (useServerSide) {
    body.style = layerInfo.style;
  } else {
    body.style = {
      method: 'fromColorProperty',
      parameters: {
        alpha: 0.5,
        width: 3.4822022531844965,
        radius: { method: 'constant', parameters: { value: 10.44660675955349 } },
        defaultColor: hackyColor,
        custom: true,
      },
    };
  }

  const pageStartObject = {};

  for (let i = 0; i < tiles.length; i++) {
    const t = tiles[i];
    pageStartObject[makeId(t)] = getNextPageStart(tilesCache[timestampId], t, true, timestampId);
  }

  let chunkSize = 10;
  for (let k = 0; k < tiles.length; k += chunkSize) {
    const subtiles = tiles.slice(k, k + chunkSize);
    body.tiles = subtiles.map((t) => {
      return {
        tileId: t,
        levelOfDetail: !useLod ? null : getLod(t.zoom, useLod) === 6 ? null : getLod(t.zoom, useLod),
        pageStart: getNextPageStart(tilesCache[timestampId], t, useLod),
      };
    });

    let res;
    try {
      res = await ApiManager.get(`/v3/path/${layer.id}/vector/timestamp/${timestamp.id}/featuresByTiles`, body, user);
    } catch (e) {
      let arr = Array(body.tiles.length).fill(Math.random());
      res = arr.map((i) => {
        return { size: 0, result: { type: 'FeatureCollection', features: [] }, nextPageStart: null };
      });
    }

    for (let j = 0; j < subtiles.length; j++) {
      let t = subtiles[j];
      let tileId = makeId(t);
      const lod = getLod(t.zoom, useLod);
      if (!tilesCache[timestampId]) {
        tilesCache[timestampId] = {};
      }

      let features = res[j].result.features;
      if (!useServerSide) {
        features = features.map((f, i) => {
          f.properties.color = f.properties.color?.slice(0, 7);

          if (f.properties.color === hackyColor) {
            delete f.properties.color;
          }

          return f;
        });
      }

      if (features.length > 0) {
        updated[timestampId] = true;
      }

      if (!tilesCache[timestampId][tileId]) {
        tilesCache[timestampId][tileId] = {
          featureIds: [],
          amount: 0,
          size: 0,
        };
      }

      for (let i = 0; i < features.length; i++) {
        const f = features[i];
        FAKE_ID = FAKE_ID + 1;
        f.id = FAKE_ID;
        if (!featuresCache[timestampId]) {
          featuresCache[timestampId] = {};
        }
        if (!featuresCache[timestampId][f.properties.id]) {
          featuresCache[timestampId][f.properties.id] = { [lod]: f };
        } else {
          featuresCache[timestampId][f.properties.id][lod] = f;
        }
        tilesCache[timestampId][tileId].featureIds.push({
          featureId: f.properties.id,
          fetchId: makeId(t) + '_' + JSON.stringify(pageStartObject[makeId(t)]),
        });
      }

      tilesCache[timestampId][tileId].fullyLoaded = !res[j].nextPageStart;
      tilesCache[timestampId][tileId].nextPageStart = res[j].nextPageStart;
      tilesCache[timestampId][tileId].size = tilesCache[timestampId][tileId].size + JSON.stringify(features).length;
      tilesCache[timestampId][tileId].amount = tilesCache[timestampId][tileId].amount + features.length;
    }
  }
};

let prevTiles = {};

const getTilesWithMemory = (renderTiles, timestampId, useMemory = true) => {
  if (!prevTiles[timestampId]) {
    prevTiles[timestampId] = [];
  }
  if (useMemory) {
    prevTiles[timestampId] = [...renderTiles, ...prevTiles[timestampId]];
  } else {
    prevTiles[timestampId] = renderTiles;
  }

  prevTiles[timestampId] = prevTiles[timestampId].filter(
    (t, i) => prevTiles[timestampId].findIndex((x) => makeId(x) === makeId(t)) === i
  );

  if (prevTiles[timestampId].length > MAX_TILES) {
    prevTiles[timestampId] = prevTiles[timestampId].slice(0, MAX_TILES);
  }
  return [...prevTiles[timestampId]];
};

let prevViewIdPointCloud = {};

export const getRenderPointCloud = (layer, layerInfo, useServerSide = false, overRideParams = {}) => {
  const tiles = tilesInView.tiles[tilesInView.zoom];
  const timestamp = layerInfo.timestampRange.timestamps[layerInfo.timestampRange.end];

  let currentViewId = tiles.map((tile) => tile.zoom + '_' + tile.tileX + '_' + tile.tileY).join('-');
  let T = overRideParams.WAIT_RENDER_TIME ? overRideParams.WAIT_RENDER_TIME : WAIT_RENDER_TIME;
  const currentTime = new Date();
  if (
    (!updated[timestamp.id] && prevViewIdPointCloud.key === currentViewId) ||
    Math.abs(prevTime.getTime() - currentTime.getTime()) / 1000 < T
  ) {
    return;
  }

  prevViewIdPointCloud['key'] = currentViewId;

  updated[timestamp.id] = false;
  let total_features = [];

  for (let i = 0; i < tiles.length; i++) {
    const tile = tiles[i];
    const tileId = tile.zoom + '_' + tile.tileX + '_' + tile.tileY;
    if (pointCloudCache[timestamp.id] && pointCloudCache[timestamp.id][tileId]?.result) {
      if (pointCloudCache[timestamp.id][tileId].result.length > 0) {
        total_features = [...total_features, ...pointCloudCache[timestamp.id][tileId].result];
      }
    }
  }

  return total_features;
};

let prevViewId = {};
let prevTime = new Date();
export const getRender = (layer, layerInfo, useServerSide = false, useMemory = true, overRideParams = {}) => {
  let fullRender;

  if (useServerSide) {
    fullRender = {};
  } else {
    fullRender = [];
  }

  const renderKey = Math.random();
  const timestamp = layerInfo.timestampRange.timestamps[layerInfo.timestampRange.end];

  let timestampId = timestamp.id;

  timestampId = timestampId + '_' + JSON.stringify(layerInfo.filter);
  if (useServerSide) {
    timestampId = timestampId + '_' + JSON.stringify(layerInfo.style);
  }

  const zoom = getLayerZoom(layer, layerInfo, tilesInView.zoom);

  if (
    !useServerSide &&
    layerInfo.filter.length === 0 &&
    timestamp.statistics?.precomputed &&
    zoom &&
    zoom <= timestamp.statistics.precomputeZoom
  ) {
    return;
  }
  const useLod = getUseLod(timestamp);
  const tiles = tilesInView.tiles[zoom];

  let renderTiles = tiles.map((t) => getTilesAbove(t, useLod, false));

  renderTiles = renderTiles.flat();

  let currentViewId = renderTiles.map((t) => makeId(t)).join('-') + '_' + JSON.stringify(layerInfo.filter);

  if (useServerSide) {
    currentViewId =
      currentViewId + '_' + JSON.stringify(layerInfo.style) + +'_' + JSON.stringify(layerInfo.tilePyramid);
  }

  const currentTime = new Date();

  let T = overRideParams.WAIT_RENDER_TIME ? overRideParams.WAIT_RENDER_TIME : WAIT_RENDER_TIME;

  if (
    (!updated[timestampId] && prevViewId.key === currentViewId) ||
    Math.abs(prevTime.getTime() - currentTime.getTime()) / 1000 < T
  )
    if (!overRideParams || !overRideParams.disableSkip) {
      if (useServerSide) {
        return { skip: true };
      } else {
        return;
      }
    }

  updated[timestampId] = false;
  prevViewId['key'] = currentViewId;
  prevTime = currentTime;

  renderTiles = getTilesWithMemory(renderTiles, timestampId, useMemory);
  renderTiles.sort((a, b) => a.zoom < b.zoom);

  for (let j = 0; j < renderTiles.length; j++) {
    const renderTile = renderTiles[j];
    const lod = getLod(renderTile.zoom, useLod);

    if (tilesCache[timestampId]?.[makeId(renderTile)]) {
      for (let k = 0; k < tilesCache[timestampId][makeId(renderTile)].featureIds.length; k++) {
        const featureId = tilesCache[timestampId][makeId(renderTile)].featureIds[k].featureId;
        const fetchId = tilesCache[timestampId][makeId(renderTile)].featureIds[k].fetchId;

        if (featuresCache[timestampId][featureId].renderKey !== renderKey) {
          if (useServerSide) {
            if (!fullRender[fetchId]) {
              fullRender[fetchId] = [];
            }
            fullRender[fetchId].push(featuresCache[timestampId][featureId][lod]);
          } else {
            fullRender.push(featuresCache[timestampId][featureId][lod]);
          }
          featuresCache[timestampId][featureId].renderKey = renderKey;
        }
      }
    }
  }

  if (precomputedRender[timestampId]?.done) {
    if (useServerSide) {
      fullRender['precomputed'] = precomputedRender[timestampId].result.features;
    } else {
      fullRender = fullRender.concat(precomputedRender[timestampId].result.features);
    }
  }

  return fullRender;
};

export const thumbnailInfo = (layer, layersInfo, timestamp, handlePostpone) => {
  const layerInfo = layersInfo[layer?.id];

  if (!!layerInfo) {
    const zoom = getLayerZoom(layer, layerInfo, tilesInView.zoom);

    const timestampId = timestamp?.id + '_[]';

    const tiles = tilesInView.tiles[zoom].filter(
      (t) =>
        !tilesCache[timestampId] ||
        !tilesCache[timestampId][makeId(t)] ||
        (tilesCache[timestampId][makeId(t)].size < MAX_SIZE_TILE &&
          tilesCache[timestampId][makeId(t)].amount < MAX_AMOUNT_TILE &&
          !tilesCache[timestampId][makeId(t)].fullyLoaded)
    );

    if (tiles?.length !== 0) {
      handlePostpone(layer?.id);
    }

    return { zoom, isLoaded: !!tilesCache[timestampId] && tiles?.length === 0 };
  } else {
    handlePostpone(layer?.id);

    return { isLoaded: false };
  }
};

////////////////////////////////////covering stuff///////////////////
export const boundsToTiles = async (bounds, zoom, cb) => {
  zoom = Math.max(0, zoom);
  let initialZoom = zoom;

  let xMin = Math.max(bounds.xMin, -180);
  let xMax = Math.min(bounds.xMax, 180);
  let yMin = Math.max(bounds.yMin, -85);
  let yMax = Math.min(bounds.yMax, 85);

  let zoomComp = Math.pow(2, zoom);
  let comp1 = zoomComp / 360;
  let pi = Math.PI;
  let comp2 = 2 * pi;
  let comp3 = pi / 4;

  let tileXMin = Math.floor((xMin + 180) * comp1);
  let tileXMax = Math.floor((xMax + 180) * comp1);
  let tileYMin = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (yMax / 360) * pi))));
  let tileYMax = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (yMin / 360) * pi))));
  let tilesDict = {};

  while (zoom >= 0) {
    let tiles = [];
    let x = Math.max(0, tileXMin);
    while (x <= Math.min(2 ** zoom - 1, tileXMax + 1)) {
      let y = Math.max(0, tileYMin);
      while (y <= Math.min(2 ** zoom - 1, tileYMax + 1)) {
        const border = (x === tileXMax || x === tileXMin) && (y === tileYMax || y === tileYMin);
        tiles.push({ zoom: zoom, tileX: x, tileY: y, border: border });
        y = y + 1;
      }
      x = x + 1;
    }
    tilesDict[zoom] = tiles;

    zoom = zoom - 1;
    tileXMin = Math.floor(tileXMin / 2);
    tileXMax = Math.floor(tileXMax / 2);
    tileYMin = Math.floor(tileYMin / 2);
    tileYMax = Math.floor(tileYMax / 2);
  }

  cb(tilesDict, initialZoom);
};

export const viewCenterToTiles = (x, y, initialZoom) => {
  x = Math.max(x, -180);
  x = Math.min(x, 180);
  y = Math.max(y, -85);
  y = Math.min(y, 85);
  let result = {};

  let zoomComp = Math.pow(2, initialZoom);
  let comp1 = zoomComp / 360;
  let pi = Math.PI;
  let comp2 = 2 * pi;
  let comp3 = pi / 4;

  let tileX = Math.floor((x + 180) * comp1);
  let tileY = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (y / 360) * pi))));

  let offsets = [-2, -1, 0, 1, 2];
  let tiles = [];
  for (let i = 0; i < offsets.length; i++) {
    for (let j = 0; j < offsets.length; j++) {
      tiles = [...tiles, { zoom: 15, tileX: tileX + offsets[i], tileY: tileY + offsets[j] }];
    }
  }
  tiles = tiles.filter((t) => t.tileX >= 0 && t.tileY >= 0);

  let zoom = initialZoom;

  while (zoom >= 0) {
    const d = initialZoom - zoom;
    const tempZoom = zoom;
    let newTiles = tiles.map((t) => {
      let tile = {
        zoom: tempZoom,
        tileX: Math.floor(t.tileX / 2 ** d),
        tileY: Math.floor(t.tileY / 2 ** d),
      };
      return tile;
    });

    const tileIds = newTiles.map((t) => t.tileX + '_' + t.tileY);
    newTiles = newTiles.filter((t, index) => tileIds.indexOf(t.tileX + '_' + t.tileY) === index);

    result[zoom] = newTiles;
    zoom = zoom - 1;
  }

  return { tiles: result, zoom: initialZoom };
};

export const buildingToTiles = (x, y) => {
  x = Math.max(x, -180);
  x = Math.min(x, 180);
  y = Math.max(y, -85);
  y = Math.min(y, 85);

  let zoomComp = Math.pow(2, 15);
  let comp1 = zoomComp / 360;
  let pi = Math.PI;
  let comp2 = 2 * pi;
  let comp3 = pi / 4;

  let tileX = Math.floor((x + 180) * comp1);
  let tileY = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (y / 360) * pi))));

  let offsets = [-2, -1, 0, 1, 2];
  let tiles = [];
  for (let i = 0; i < offsets.length; i++) {
    for (let j = 0; j < offsets.length; j++) {
      tiles = [...tiles, { zoom: 15, tileX: tileX + offsets[i], tileY: tileY + offsets[j] }];
    }
  }
  return tiles;
};

export const fetchDataForBuildings = async (center, cb, url, buildingsCache) => {
  const tiles = buildingToTiles(center[1], center[0]);

  for (let i = 0; i < tiles.length; i++) {
    const t = tiles[i];
    const tileId = t.zoom + '_' + t.tileX + '_' + t.tileY;
    let formattedUrl = url.replace('{x}', String(t.tileX));
    formattedUrl = formattedUrl.replace('{y}', String(t.tileY));

    let data;
    if (!buildingsCache[tileId]) {
      try {
        data = await fetch(formattedUrl, { method: 'GET' });
        data = await data.json();
        buildingsCache[tileId] = data;
        cb(true);
      } catch {
        buildingsCache[tileId] = { features: [] };
      }
    } else {
    }
  }
  cb(false);
};

export const centerToTiles = async (X, Y, zoom = 16) => {
  let x = Math.max(X, -180);
  x = Math.min(x, 180);
  let y = Math.max(Y, -85);
  y = Math.min(y, 85);

  let zoomComp = Math.pow(2, zoom);
  let comp1 = zoomComp / 360;
  let pi = Math.PI;
  let comp2 = 2 * pi;
  let comp3 = pi / 4;

  let tileX = Math.floor((x + 180) * comp1);
  let tileY = Math.floor((zoomComp / comp2) * (pi - Math.log(Math.tan(comp3 + (y / 360) * pi))));

  let tiles = Array.from(Array(17).keys()).map((z) => {
    let tx = Math.floor(tileX / 2 ** (16 - z));
    let ty = Math.floor(tileY / 2 ** (16 - z));
    return { zoom: z, tileX: tx, tileY: ty };
  });
  return tiles;
};
