import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import { Bookmark, DashboardCustomize, Preview } from '@mui/icons-material';
import CustomBookmarkConfiguration from '../CustomBookmarkConfiguration';
import { MarkdownEditor } from 'ReusableComponents/MarkdownEditor';
import MoveToAction from 'ReusableComponents/MoveTo/MoveTo';

const TYPE_KEYS = { default: 'default', presentationMode: 'presentationMode', advanced: 'advanced' };
const TYPES = [
  {
    key: TYPE_KEYS['default'],
    icon: Bookmark,
    label: 'Default Bookmark',
    description: 'Save current view for later use in the viewer.',
  },
  {
    key: TYPE_KEYS['presentationMode'],
    icon: Preview,
    label: 'Presentation Mode',
    description: 'Create a bookmark of the current view optimised for data delivery and presentation.',
    shortDescription: 'Optimised for data delivery and presentation.',
  },
  {
    key: TYPE_KEYS['advanced'],
    icon: DashboardCustomize,
    label: 'Custom Configuration',
    description: 'Have full control over which elements are present in the viewer.',
    shortDescription: 'Full control over viewer elements.',
  },
];

const DEFAULT_STATE = { type: TYPE_KEYS['default'], name: '', configuration: {}, description: '' };

export const getTypeFromViewerConfig = (viewerConfig) => {
  const key = !!viewerConfig?.presentationMode
    ? TYPE_KEYS['presentationMode']
    : Object.keys(viewerConfig)?.length > 0
    ? TYPE_KEYS['advanced']
    : TYPE_KEYS['default'];

  return TYPES.find((T) => T.key === key);
};

const SaveDialog = ({ open, handleCreateShareLink, handleClose, viewerConfig, selectedMap, isUpdate }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [bookmarkData, setBookmarkData] = useState(DEFAULT_STATE);
  const [parentId, setParentId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const type = useMemo(() => bookmarkData?.['type'] ?? 'default', [bookmarkData]);

  const setType = useCallback(
    (type) =>
      setBookmarkData((current) => {
        if (type === TYPE_KEYS['default'] || type === TYPE_KEYS['advanced']) {
          return { ...current, type, configuration: {} };
        } else {
          return { ...current, type, configuration: { hideNavbar: true } };
        }
      }),
    []
  );

  const name = useMemo(() => bookmarkData?.['name'] ?? '', [bookmarkData]);
  const setName = useCallback((name) => setBookmarkData((old) => ({ ...old, name })), []);

  const configuration = useMemo(() => bookmarkData?.['configuration'], [bookmarkData]);

  const description = useMemo(() => bookmarkData?.['description'] ?? '', [bookmarkData]);
  const setDescription = useCallback((description) => setBookmarkData((old) => ({ ...old, description })), []);

  const setConfiguration = useCallback((parameter) => {
    setBookmarkData((old) => {
      const oldConf = { ...old.configuration };
      if (oldConf[parameter]) {
        delete oldConf[parameter];
      } else {
        oldConf[parameter] = true;
      }
      return { ...old, configuration: oldConf };
    });
  }, []);

  const steps = useMemo(
    () => [
      {
        label: 'Select Bookmark Type',
        description: `Below you can select the type of bookmark that fits your situation best.`,
        component: TypeComponent,
        value: TYPES.find((x) => x.key === type)?.label,
      },
      {
        label: 'Customise viewer Elements',
        secondary: 'only for custom configuration',
        disabled: type !== TYPE_KEYS['advanced'],
        description: 'Check boxes below to hide elements from the viewer and save it to a bookmark.',
        component: CustomBookmarkConfiguration,
        hasBadge: true,
      },
      {
        label: 'Give your bookmark a name.',
        component: NameComponent,
        nextDisabled: name?.trim()?.length === 0,
        value: name,
      },
      {
        label: 'Add a description.',
        secondary: 'Optional',
        component: DescriptionComponent,
      },
      ...(isUpdate
        ? []
        : [
            {
              label: 'Pick a folder.',
              secondary: 'Optional',
              component: MoveComponent,
            },
          ]),
    ],
    [name, type, isUpdate]
  );

  const handleNext = useCallback(
    (e) => {
      e?.preventDefault();
      setActiveStep((prevActiveStep) =>
        !steps[prevActiveStep + 1]?.disabled ? prevActiveStep + 1 : prevActiveStep + 2
      );
    },
    [steps]
  );

  const handleBack = useCallback(
    (e) => {
      e?.preventDefault();
      setActiveStep((prevActiveStep) =>
        !steps[prevActiveStep - 1]?.disabled ? prevActiveStep - 1 : prevActiveStep - 2
      );
    },
    [steps]
  );

  const reset = useCallback(() => {
    handleClose();
    setActiveStep(0);
    //setBookmarkData(DEFAULT_STATE);
  }, [handleClose]);

  const close = useCallback(() => {
    //delete bookmark
    reset();
    handleClose();
  }, [handleClose, reset]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      handleCreateShareLink({
        persistent: true,
        isUpdate,
        parentId,
        name,
        description,
        viewerConfig: {
          ...configuration,
          ...(type === TYPE_KEYS['presentationMode'] ? { presentationMode: true } : {}),
        },
      });

      reset();
    },
    [handleCreateShareLink, isUpdate, name, description, type, configuration, parentId, reset]
  );

  useEffect(() => {
    setName(isUpdate && selectedMap?.name ? selectedMap?.name : '');
    setDescription(isUpdate && selectedMap?.description ? selectedMap?.description : '');
  }, [open, setName, selectedMap?.name, isUpdate, selectedMap?.description]);

  useEffect(() => {
    setType(getTypeFromViewerConfig(viewerConfig)?.key);
    setBookmarkData((old) => ({
      ...old,
      configuration: viewerConfig,
    }));
  }, [setType, viewerConfig]);

  const onSubmit = activeStep === steps.length - 1 ? handleSubmit : handleNext;

  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      sx={{
        '&>.MuiDialog-container>.MuiPaper-root': {
          m: { xs: 1, sm: 4 },
          width: { xs: 'calc(100% - 16px)', sm: 'calc(100% - 64px)' },
        },
      }}
    >
      <DialogTitle title={!!isUpdate ? 'Update Bookmark' : 'Save view to my bookmarked views'} sx={{ pb: 1 }} />
      <DialogContent
        sx={{
          padding: ({ spacing }) => ({ xs: spacing(2), sm: spacing(2.5, 3) }),
        }}
      >
        <Stepper
          orientation="vertical"
          activeStep={activeStep}
          sx={{ '& .MuiStepConnector-line': { minHeight: ({ spacing }) => spacing(2) } }}
        >
          {steps.map(
            (
              {
                label,
                secondary,
                description: stepDescription,
                component: Component,
                disabled = false,
                hasBadge,
                nextDisabled,
                value,
              },
              index
            ) => (
              <Step key={label} disabled={disabled} completed={activeStep > index && !disabled}>
                <StepLabel
                  optional={
                    !!secondary && (activeStep < index || disabled) ? (
                      <Typography variant="caption">{secondary}</Typography>
                    ) : !!value && activeStep > index ? (
                      <Typography variant="caption">{value}</Typography>
                    ) : null
                  }
                >
                  {!!hasBadge ? (
                    <Badge
                      badgeContent={Object.keys(configuration)?.length}
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: (theme) =>
                            activeStep < index && disabled ? theme.palette.action.disabled : theme.palette.primary.main,
                        },
                      }}
                      color="primary"
                    >
                      <span style={{ marginRight: 12 }}>{label}</span>
                    </Badge>
                  ) : (
                    label
                  )}
                </StepLabel>
                <StepContent>
                  <form onSubmit={onSubmit}>
                    {!!stepDescription && <Typography>{stepDescription}</Typography>}
                    {!!Component && (
                      <Component
                        type={type}
                        setDisabled={setDisabled}
                        setType={setType}
                        name={name}
                        selectedMap={selectedMap}
                        setName={setName}
                        setParentId={setParentId}
                        configuration={configuration}
                        setConfiguration={setConfiguration}
                        description={description}
                        setDescription={setDescription}
                        nextDisabled={nextDisabled}
                      />
                    )}
                  </form>
                </StepContent>
              </Step>
            )
          )}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Box sx={{ textAlign: 'right' }}>
          <Button
            color="inherit"
            onClick={activeStep === 0 ? close : handleBack}
            sx={{ mt: 1, mr: 1, color: '#1a1a1a' }}
          >
            {activeStep === 0 ? 'Cancel' : 'Back'}
          </Button>
          <Button
            variant="contained"
            onClick={onSubmit}
            sx={{ mt: 1, mr: 1 }}
            disabled={steps[activeStep]?.nextDisabled || (activeStep === steps.length - 1 && disabled)}
          >
            {activeStep === steps.length - 1 ? (isUpdate ? 'Save' : 'Save in this location') : 'Next'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SaveDialog;

const TypeComponent = ({ type, setType }) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List>
      {TYPES?.map(({ key, icon: Icon, label, description, shortDescription }) => (
        <ListItem key={key} sx={{ p: 0, '&+&': { mt: 1 } }}>
          <ListItemButton
            selected={type === key}
            onClick={() => setType(key)}
            sx={{
              borderWidth: 1,
              borderColor: 'action.active',
              borderStyle: 'solid',
              borderRadius: 2,
              '&.Mui-selected': { color: 'primary.main', borderColor: 'primary.main' },
              padding: ({ spacing }) => ({ xs: spacing(1, 1.25), sm: spacing(1, 2) }),
            }}
          >
            {!!Icon && (
              <ListItemIcon
                sx={{
                  color: type === key ? 'inherit' : 'text.secondary',
                  minWidth: ({ spacing }) => ({ xs: spacing(5), sm: spacing(7) }),
                }}
              >
                <Icon />
              </ListItemIcon>
            )}
            <ListItemText
              inset={!Icon}
              primary={label}
              secondary={!!isSM ? shortDescription : description}
              secondaryTypographyProps={{ color: type === key ? 'inherit' : 'text.secondary' }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

const NameComponent = ({ name, setName }) => (
  <TextField
    label="Name"
    autoFocus
    value={name}
    onChange={(e) => setName(e?.target?.value)}
    variant="outlined"
    fullWidth
  />
);

const DescriptionComponent = ({ description, setDescription }) => (
  <MarkdownEditor
    small
    initialValue={description}
    disableButtons
    overrideState={[description, setDescription]}
    maxLength={100}
  />
);

const MoveComponent = ({ setParentId, selectedMap, setDisabled }) => {
  let p = ['myMaps', 'sharedWithMe'].includes(selectedMap.path.root) ? selectedMap.path.path : [];
  const smaller = [...p];
  smaller.reverse();
  if (selectedMap.type !== 'folder' && smaller.length > 0) {
    smaller.pop();
  }
  const root = selectedMap.path.root === 'sharedWithMe' ? 'shared' : selectedMap.path.root;
  return (
    <MoveToAction
      setCurrentPathId={setParentId}
      currentRoot={{ root: selectedMap.path.root === 'external' ? null : root, path: smaller }}
      selectedItems={[]}
      setDisabled={setDisabled}
      type={'create'}
      bookmark={true}
      blockSubmit={false}
      loadingSubmit={false}
    />
  );
};
