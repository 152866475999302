import React, { useState, useEffect } from 'react';

import { Typography, TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useInput, useDebounce } from '../../hooks';
import { useAuth, useAuthFuncs } from '../AuthContext';
import ApiManager from '../../ApiManager';
import useStyles from '../LoginPage/useLoginPageStyles';
import { checkUsername, checkPassword, checkEmail } from '../utils';
import FullScreenLayout from '../FullScreenLayout';
import { LoadingButton } from '../../ReusableComponents';
import { useQueryParams } from '../../hooks';

const UpdateCredentials = () => {
  const { classes } = useStyles();
  const history = useHistory();

  const user = useAuth();
  const { fetchUserInfo } = useAuthFuncs();

  const [value, updateValue] = useInput();
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const debouncedValue = useDebounce(value, 500);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({
    username: '',
    password: '',
    submit: '',
  });

  const { type, validationToken, userId } = useQueryParams();

  const updateType = type === 'resetPassword' ? 'password' : type;

  useEffect(() => {
    if (updateType === 'email') {
      checkEmail(debouncedValue, setErrors);
    } else if (updateType === 'username') {
      checkUsername(debouncedValue, setErrors);
    } else if (updateType === 'password') {
      checkPassword(debouncedValue, setErrors);
    }
  }, [debouncedValue, setErrors, type]);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    let url;
    let method;
    let body;
    if (type === 'email') {
      url = '/v3/account/security/email/update';
      method = 'POST';
      body = { email: value, password: history.location.state.password };
    }
    if (type === 'password') {
      url = '/v3/account/security/password/update';
      method = 'POST';
      body = { newPassword: value, oldPassword: history.location.state.password };
    }
    if (type === 'resetPassword') {
      url = '/v3/account/security/password/validate';
      method = 'POST';
      body = { token: validationToken, newPassword: value };
    }
    if (type === 'username') {
      url = '/v3/account/username';
      method = 'PUT';
      body = { username: value };
    }

    if (type === 'email' && user.openId.providers.length > 0) {
      await ApiManager.post(`/v3/google/detach`, { password: history.location.state.password }, user);
    }

    ApiManager.fetch(method, url, body, user)
      .then((r) => {
        fetchUserInfo();

        let tempuserId = userId ? userId : user.id;
        history.push(`/notification?type=${updateType}Updated&userId=${tempuserId}`);
      })
      .catch((error) => {
        setErrors({ submit: error.message });
        setLoading(false);
      });
  };

  if (
    !['password', 'username', 'email', 'resetPassword'].includes(type) ||
    (type === 'resetPassword' && (!userId || !validationToken)) ||
    (type !== 'resetPassword' && !user)
  ) {
    history.push('/404');
  } else if (
    (!history.location || !history.location.state || !history.location.state.password) &&
    type !== 'resetPassword'
  ) {
    history.push('/login?userId=' + user.id, {
      from: `/update-credentials?type=${type}`,
      update: type,
    });
  }

  return (
    <FullScreenLayout>
      <Helmet>
        <title>
          {'Update ' + updateType} - {ApiManager?.displayName ?? 'Ellipsis Drive'}
        </title>
      </Helmet>
      <Typography className={classes.title} variant="h3">
        {'Update your ' + updateType}
      </Typography>

      {errors.submit && (
        <div className={classes.errorContainer}>
          <Typography className={classes.errorText}>{errors.submit}</Typography>
        </div>
      )}

      <form className={classes.contentContainer} onSubmit={handleSubmit}>
        <div className={classes.textFieldContainer}>
          <TextField
            label={'New ' + updateType}
            InputLabelProps={{ shrink: true }}
            placeholder={updateType === 'password' ? '••••••••' : type}
            value={value}
            onChange={updateValue}
            variant="outlined"
            type={updateType === 'password' ? 'password' : 'text'}
            className={classes.textField}
            helperText={value && errors?.[updateType]}
            error={errors?.[updateType] !== '' && value}
            autoComplete="new-password"
          />
        </div>
        {updateType === 'password' && (
          <div className={classes.textFieldContainer}>
            <TextField
              label={'Repeat new password'}
              InputLabelProps={{ shrink: true }}
              placeholder={'••••••••'}
              value={repeatedPassword}
              onChange={(e) => {
                setRepeatedPassword(e.target.value);
              }}
              variant="outlined"
              type="password"
              className={classes.textField}
              helperText={repeatedPassword && value !== repeatedPassword ? 'repeated password not the same' : ''}
              error={repeatedPassword && value !== repeatedPassword}
              autoComplete="new-password"
            />
          </div>
        )}

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={errors[updateType] !== '' || (updateType === 'password' && repeatedPassword !== value)}
          className={classes.loginButton}
          type="submit"
        >
          {'Submit'}
        </LoadingButton>
      </form>
    </FullScreenLayout>
  );
};

export default UpdateCredentials;
