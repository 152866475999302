import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import { Box, IconButton, LinearProgress, ListItemIcon, MenuItem, Typography } from '@mui/material';

import NotesIcon from '@mui/icons-material/Notes';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StarIcon from '@mui/icons-material/Star';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAuth } from '../Account/AuthContext';
import { useMainContext } from 'ReusableComponents';
import ApiManager from 'ApiManager';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

const useStyles = makeStyles({ name: 'SetupProgress' })((theme) => ({
  progressBar: {
    height: 6,
    '& .MuiLinearProgress-dashed': {
      display: 'none',
    },
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#4CAF50',
    },
    '& .MuiLinearProgress-bar2Buffer': {
      backgroundColor: '#F7A04A',
    },
  },
  greenCheck: {
    color: '#4CAF50',
  },
  setupProgressContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
  },
}));

export const SetupProgress = ({ steps }) => {
  const { classes } = useStyles();

  const firstNotDoneElement = steps.findIndex((el) => !el.isDone);

  const [step, setStep] = useState(firstNotDoneElement === -1 ? 0 : firstNotDoneElement);

  const doneAmount = steps.reduce((acc, value) => {
    if (value.isDone) {
      acc++;
    }
    return acc;
  }, 0);

  const StepIcon = steps[step].icon;

  const prevClick = useCallback(
    (e) => {
      const prevStep = step === 0 ? steps.length - 1 : step - 1;
      if (step !== 0) {
        setStep(prevStep);
      }
      e.stopPropagation();
    },
    [step, steps]
  );

  const nextClick = useCallback(
    (e) => {
      const nextStep = step === steps.length - 1 ? 0 : step + 1;
      if (step !== steps.length - 1) {
        setStep(nextStep);
      }
      e.stopPropagation();
    },
    [step, steps]
  );

  const onClickAction = useCallback(() => {
    steps[step].action();
  }, [step, steps]);

  return (
    <MenuItem className={classes.setupProgressContainer} onClick={onClickAction}>
      <Typography variant="caption">Account Setup Progress</Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          margin: (theme) => theme.spacing(1, 'auto'),
          gap: 1,
        }}
      >
        <IconButton aria-label="previous" onClick={prevClick}>
          {step !== 0 && <ChevronLeft fontSize="small" />}
        </IconButton>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'break-spaces' }}>
          <ListItemIcon color="inherit">
            {steps[step].isDone ? <CheckCircleIcon className={classes.greenCheck} /> : <StepIcon />}
          </ListItemIcon>
          {steps[step].text}
        </Box>
        <IconButton aria-label="next" onClick={nextClick}>
          {step !== steps.length - 1 && <ChevronRight fontSize="small" />}
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 1 }}>
        <Box sx={{ width: '100%', backgroundColor: '#00000014', borderRadius: 1, overflow: 'hidden' }}>
          <LinearProgress
            variant="buffer"
            value={(doneAmount / steps.length) * 100}
            valueBuffer={steps[step].isDone ? 0 : ((doneAmount + 1) / steps.length) * 100}
            className={classes.progressBar}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary" align="right">{`${Math.round(
            (doneAmount / steps.length) * 100
          )}%`}</Typography>
        </Box>
      </Box>
    </MenuItem>
  );
};

export const SetupProgressWithSteps = ({ setAccountMenuOpen, setAccountSetup }) => {
  const authUser = useAuth();
  const history = useHistory();

  const { handleOpenNewProjectDialog, setInvite } = useMainContext();

  const mapsCreated = useMemo(
    () =>
      authUser && authUser.history
        ? authUser.history.reduce((acc, value) => {
            acc += value.createdMaps;
            return acc;
          }, 0)
        : 0,
    [authUser]
  );

  const usersShared = useMemo(
    () =>
      authUser && authUser.history
        ? authUser.history.reduce((acc, value) => {
            acc += value.numberOfInvitedUsers;
            return acc;
          }, 0)
        : 0,
    [authUser]
  );

  const steps = useMemo(() => {
    if (ApiManager.cloud !== 'ellipsis') {
      return [
        {
          text: 'Upload your picture',
          icon: AccountCircleIcon,
          isDone: !!authUser.picture,
          action: () => {
            setAccountMenuOpen(false);
            history.push('/account-settings/profile');
          },
        },
        {
          text: 'Tell something about yourself',
          icon: NotesIcon,
          action: () => {
            setAccountMenuOpen(false);
            history.push('/account-settings/profile');
          },
          isDone: !!authUser.about,
        },
        {
          text: 'Create a layer',
          icon: AddCircleIcon,
          isDone: !!mapsCreated,
          action: () => {
            setAccountMenuOpen(false);
            handleOpenNewProjectDialog();
          },
        },
      ];
    }

    return [
      {
        text: 'Upload your picture',
        icon: AccountCircleIcon,
        isDone: !!authUser.picture,
        action: () => {
          setAccountMenuOpen(false);
          history.push('/account-settings/profile');
        },
      },
      {
        text: 'Tell something about yourself',
        icon: NotesIcon,
        action: () => {
          setAccountMenuOpen(false);
          history.push('/account-settings/profile');
        },
        isDone: !!authUser.about,
      },
      {
        text: 'Create a layer',
        icon: AddCircleIcon,
        isDone: !!mapsCreated,
        action: () => {
          setAccountMenuOpen(false);
          handleOpenNewProjectDialog();
        },
      },
      {
        text: 'Invite your friends!',
        icon: PersonAddIcon,
        isDone: !!usersShared,
        action: () => {
          setAccountMenuOpen(false);
          setInvite(true);
        },
      },
      {
        text: 'Link a credit card',
        icon: CreditCardIcon,
        isDone: !!authUser.paymentMethod,
        action: () => {
          setAccountMenuOpen(false);
          history.push('/balance');
        },
      },
    ];
  }, [authUser, handleOpenNewProjectDialog, history, mapsCreated, setAccountMenuOpen, setInvite, usersShared]);

  useEffect(() => {
    if (steps.find((s) => !s.isDone)) {
      setAccountSetup && setAccountSetup(false);
    }
  }, [steps, setAccountSetup]);

  if (setAccountSetup) {
    return null;
  }

  if (!steps.find((x) => !x.isDone)) {
    return null;
  }
  return <SetupProgress steps={steps} />;
};
