import React, { useCallback, useState } from 'react';

import { Badge, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import Geofence from '../Geofence/Geofence';
import { useMinimalAuth } from 'hooks';
import useEmptyStyles from 'hooks/useEmptyStyles';

const GlobeIcon = ({ color }) => {
  const { theme } = useEmptyStyles();

  return (
    <>
      <path d="M19.92 11C19.96 11.33 20 11.66 20 12C20 14.08 19.2 15.97 17.9 17.39C17.64 16.58 16.9 16 16 16H15V13C15 12.45 14.55 12 14 12H8V10H10C10.55 10 11 9.55 11 9V7H13C14.1 7 15 6.1 15 5V2.46C14.05 2.16 13.05 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 11.66 21.98 11.33 21.95 11H19.92ZM11 19.93C7.05 19.44 4 16.08 4 12C4 11.38 4.08 10.79 4.21 10.21L9 15V16C9 17.1 9.9 18 11 18V19.93Z" />
      <path
        className="lock"
        fill={theme.palette?.[color]?.main}
        d="M23 3V2.5C23 1.12012 21.8828 0 20.5 0C19.1172 0 18 1.12012 18 2.5V3C17.4531 3 17 3.4502 17 4V8C17 8.5498 17.4531 9 18 9H23C23.3359 9 23.6328 8.83154 23.8125 8.5752C23.9297 8.41211 24 8.21338 24 8V4C24 3.4502 23.5469 3 23 3ZM22.1953 3H18.7969V2.5C18.7969 1.55957 19.5547 0.799805 20.5 0.799805C20.9375 0.799805 21.3359 0.963867 21.6328 1.23389C21.9766 1.54492 22.1953 1.99658 22.1953 2.5V3Z"
      />
    </>
  );
};

const useStyles = makeStyles({})(() => ({
  badge: { zoom: 0.75, '.Mui-disabled>&>span.MuiBadge-badge': { opacity: 0.5 } },
  geoFenceIcon: { zoom: 1.33, '.Mui-disabled>span>&>path.lock': { opacity: 0.5 } },
}));

const GeoFenceElement = ({
  disabled,
  tiles,
  maxZoom,
  handleChange,
  path,
  type,
  targetUser,
  tooltipLabel,
  skeleton,
}) => {
  const { classes: styles } = useStyles();
  const user = useMinimalAuth();

  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen((old) => !old), []);

  return (
    <>
      <Tooltip title={tooltipLabel}>
        <span>
          <IconButton disabled={skeleton || disabled} onClick={handleOpen} size="small">
            <Badge
              className={styles.badge}
              badgeContent={tiles?.length || maxZoom}
              color={tiles?.length ? 'primary' : 'secondary'}
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
              <SvgIcon className={styles?.geoFenceIcon}>
                <GlobeIcon color={tiles?.length > 0 || maxZoom ? 'secondary' : undefined} />
              </SvgIcon>
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      {!skeleton && !disabled && (
        <Geofence
          map={path}
          open={open}
          onCancel={handleOpen}
          tiles={tiles}
          maxZoom={maxZoom}
          user={user}
          isPublic={type === 'public'}
          updatePublic={type === 'public' ? handleChange : undefined}
          updateUser={type === 'user' ? handleChange : undefined}
          targetUser={targetUser}
        />
      )}
    </>
  );
};

export default GeoFenceElement;
