import { useLocalStorage } from 'hooks';
import React, { createContext, useContext, useState, useCallback } from 'react';

let isMobile = window.innerWidth < 600;

export const PANE_TYPES = {
  presentationMode: 'PRESENTATION_MODE',
  communityLibrary: 'COMMUNITY_LIBRARY',
  mainPane: 'MAIN_PANE',
  individualLayer: 'INDIVIDUAL_LAYER',
  feature: 'FEATURE',
  changelog: 'CHANGELOG',
  messages: 'MESSAGES',
  featureMessages: 'FEATURE_MESSAGES',
  externalLayer: 'EXTERNAL',
  model: 'MODEL',
  shareView: 'SHARE_VIEW',
  Geoprocessing: 'GEOPROCESSING',
};

const paneContentContext = createContext();
const paneContentFuncsContext = createContext();

/* const initialContent = {
  type: PANE_TYPES['mainPane'],
  paneProps: {},
}; */

export const PaneContentProvider = ({ children }) => {
  //const [content, _setContent] = useState(initialContent);
  const [showPane, _setOpenPane] = useState(!isMobile);
  const [pane, _setPane] = useState();
  const [viewerSections, setViewerSections] = useLocalStorage('viewerSections', {});

  const setContent = useCallback(({ type, props = {}, showPane = true }) => {
    const possibleTypes = Object.values(PANE_TYPES);

    if (!possibleTypes.includes(type)) {
      throw new Error(`Pane 'type' must be one of ${possibleTypes.join(', ')}. Received type: ${type}`);
    }

    _setPane({ type, props });

    _setOpenPane(showPane);
  }, []);

  const openPane = useCallback(() => _setOpenPane(true), []);
  const hidePane = useCallback(() => _setOpenPane(false), []);

  return (
    <paneContentContext.Provider value={{ pane, viewerSections, showPane }}>
      <paneContentFuncsContext.Provider value={{ setContent, hidePane, openPane, setViewerSections }}>
        {children}
      </paneContentFuncsContext.Provider>
    </paneContentContext.Provider>
  );
};

export function usePaneContent() {
  const context = useContext(paneContentContext);
  if (context === undefined) {
    throw new Error('usePaneContent must be used within a PaneContentProvider');
  }
  return context;
}

export function usePaneContentFuncs() {
  const context = useContext(paneContentFuncsContext);
  if (context === undefined) {
    throw new Error('usePaneContentFuncs must be used within a PaneContentFuncsProvider');
  }
  return context;
}

export function withPaneContent(Component) {
  return function WrapperComponent(props) {
    const paneContent = usePaneContent();
    return <Component {...props} paneContent={paneContent} />;
  };
}

export function withPaneContentFuncs(Component) {
  return function WrapperComponent(props) {
    const paneContentFuncs = usePaneContentFuncs();
    return <Component {...props} {...paneContentFuncs} />;
  };
}

export function withPaneContentContext(Component) {
  return function WrapperComponent(props) {
    return (
      <PaneContentProvider>
        <Component {...props} />
      </PaneContentProvider>
    );
  };
}
