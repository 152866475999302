import React, { createContext, useContext, useState, useMemo } from 'react';

const notificationContextState = createContext();
const notificationSetterContext = createContext();

const NotificationProvider = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const memoizedMenuOpen = useMemo(() => menuOpen, [menuOpen]);
  const memoizedSetMenuOpen = useMemo(() => setMenuOpen, [setMenuOpen]);

  return (
    <notificationContextState.Provider value={memoizedMenuOpen}>
      <notificationSetterContext.Provider value={memoizedSetMenuOpen}>{children}</notificationSetterContext.Provider>
    </notificationContextState.Provider>
  );
};

function useNotificationState() {
  const context = useContext(notificationContextState);
  if (context === undefined) {
    throw new Error('useUser must be used within a NotificationProvider');
  }
  return context;
}

function useNotificationSetter() {
  const context = useContext(notificationSetterContext);
  if (context === undefined) {
    throw new Error('useNotificationSetter must be used within a NotificationProvider');
  }
  return context;
}

function withNotificationState(Component) {
  return function WrapperComponent(props) {
    const menuOpen = useNotificationState();
    return <Component {...props} notificationMenuOpen={menuOpen} />;
  };
}

function withNotificationSetter(Component) {
  return function WrapperComponent(props) {
    const setMenuOpen = useNotificationSetter();
    return <Component {...props} setNotificationMenuOpen={setMenuOpen} />;
  };
}

export {
  NotificationProvider,
  useNotificationState,
  useNotificationSetter,
  withNotificationState,
  withNotificationSetter,
};
