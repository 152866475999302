export const hiddenHubspotInputs = [
  'fc_campaign',
  'fc_channel',
  'fc_content',
  'fc_landing',
  'fc_medium',
  'fc_referrer',
  'fc_source',
  'fc_term',
  'lc_campaign',
  'lc_channel',
  'lc_content',
  'lc_landing',
  'lc_medium',
  'lc_referrer',
  'lc_source',
  'lc_term',
  'OS',
  'GA_Client_ID',
  'gclid',
  'all_traffic_sources',
  'browser',
  'city',
  'country',
  'device',
  'page_visits',
  'pages_visited_list',
  'region',
  'time_zone',
  'time_passed',
  'latitude',
  'longitude',
];

export const jobRoles = [
  'BD / Sales / CS / Product Manager',
  'Project Manager',
  'GIS Professional',
  'Data Scientist / Data Analyst',
  'Developer / Data Engineer',
  'CEO / COO',
  'CTO / CIO / Tech Lead',
  /* 'Other', */
];

export const industries = [
  'Earth Observation satellite operator',
  'Drone/Unmanned Aerial Vehicle (UAV) operator',
  'Aircraft based aerial imagery or Lidar vendor',
  'Satellite data reseller',
  'Remote Sensing and/or Spatial Analytics Product Vendor',
  'Consultancy Services',
  'Surveying & Geotechnical Company',
  'NGO',
  'Municipal / City / Provincial Governmental agency',
  'National / State Governmental agency',
  /* 'Other', */
];

export const registeringReasons = [
  'I want to start using Ellipsis Drive',
  'I have been invited by another user',
  'I want to stay informed about Ellipsis Drive',
  'I want to test Ellipsis Drive',
];
