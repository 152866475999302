import React from 'react';

import SectionMenuItemDownload from './SectionMenuItemDownload';
import SectionMenuItemInvite from './SectionMenuItemInvite';
import SectionMenuItemShare from './SectionMenuItemShare';
import SectionMenuItemEngagement from './SectionMenuItemEngagement';
import SectionMenuItemImports from './SectionMenuItemImports';

function SectionMenuItem({ type, ...otherProps }) {
  if (type === 'download') {
    return <SectionMenuItemDownload {...otherProps} />;
  } else if (type === 'invite') {
    return <SectionMenuItemInvite {...otherProps} />;
  } else if (type === 'engagement') {
    return <SectionMenuItemEngagement {...otherProps} />;
  } else if (type === 'imports') {
    return <SectionMenuItemImports {...otherProps} />;
  } else {
    return <SectionMenuItemShare {...otherProps} />;
  }
}

export default SectionMenuItem;
