import React, { createContext, useContext, useState } from 'react';

const addedLayersContext = createContext();

export const AddedLayersProvider = ({ children }) => {
  const [addedLayers, setAddedLayers] = useState([]);
  const [addedElevationModels, setAddedElevationModels] = useState([]);

  return (
    <addedLayersContext.Provider value={{ addedLayers, setAddedLayers, addedElevationModels, setAddedElevationModels }}>
      {children}
    </addedLayersContext.Provider>
  );
};

export function useAddedLayers() {
  const context = useContext(addedLayersContext);
  if (context === undefined) {
    throw new Error('useAddedLayers must be used within a AddedLayersProvider');
  }
  return context;
}

export function withAddedLayers(Component) {
  return function WrapperComponent(props) {
    const context = useAddedLayers();
    return <Component {...props} {...context} />;
  };
}

export function withAddedLayersContext(Component) {
  return function WrapperComponent(props) {
    return (
      <AddedLayersProvider>
        <Component {...props} />
      </AddedLayersProvider>
    );
  };
}
