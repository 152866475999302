import React, { useContext } from 'react';

export const MainContext = React.createContext({});

export const useMainContext = () => {
  const context = useContext(MainContext);
  if (context === undefined) {
    throw new Error('useMainContext must be used with a MainContextProvider');
  }
  return context;
};

export const withMainContext = (Component) => {
  return function WrapperComponent(props) {
    const mainContext = useMainContext();
    return <Component {...props} {...mainContext} />;
  };
};
