import React from 'react';

import { Edit } from '@mui/icons-material';

import RenameComponent from '../Components/Rename/Rename';
import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useContextMenu } from '../../ContextMenuContext';

const View = ({ setDialog, closeDialog, onClose }) => {
  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const rename = () => {
    setDialog({
      title: 'Rename ' + path?.name,
      content: <RenameComponent closeDialog={closeDialog} />,
    });

    onClose();
  };

  return <CombinedMenuItem icon={<Edit />} label="Rename" action={rename} openDialog />;
};

export default View;
