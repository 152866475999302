import React from 'react';

import { Typography, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useHistory, Link } from 'react-router-dom';

import { SectionTitle, useMainContext } from '../../../ReusableComponents';
import { useInput, useMinimalAuth, useAuthFuncs } from '../../../hooks';
import ApiManager from '../../../ApiManager';

const useStyles = makeStyles()((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridGap: '5px',
      height: 'fit-content',
      paddingBottom: '10px',
    },
    gridGap: '35px',
    alignItems: 'center',
    height: '58px',
    // borderBottom: "1px solid rgba(0,0,0,0.05)",
    color: theme.palette.text.primary,
    paddingBottom: '6px',
  },
  textField: {},
  textFieldInput: {
    height: '20px',
    padding: '8px 14px',
  },
}));

const NewOAuthApp = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const user = useMinimalAuth();
  const { fetchUserInfo } = useAuthFuncs();
  const { onOpenSnackbar } = useMainContext();

  const [appURL, handleAppURLChange] = useInput('');
  const handleSubmit = async () => {
    let body = { redirectUri: [appURL], password: history.location.state?.password };
    try {
      const res = await ApiManager.post('/v3/oauth/register', body, user);
      await fetchUserInfo();

      if (!!res) {
        history.push('/account-settings/developers', {
          newClient: true,
          clientSecret: res.clientSecret,
          password: history.location.state?.password,
        });
      }
    } catch (error) {
      onOpenSnackbar({
        level: 'error',
        content: error.message,
      });
    }
  };

  if (!history.location.state?.password) {
    history.push('/login?userId=' + user.id, {
      from: '/account-settings/developers/new-oauth-app',
      update: 'developer settings',
    });
  }

  return (
    <div>
      <SectionTitle first={true}>
        <SectionTitle.SectionTitleText>
          <Link
            to={{ pathname: `/account-settings/developers`, state: { password: history.location.state?.password } }}
            className={classes.link}
          >
            OAuth Apps
          </Link>
          {' > '}
          OAuth Apps
        </SectionTitle.SectionTitleText>
        <SectionTitle.SectionTitleAction onClick={handleSubmit}>Register OAuth app</SectionTitle.SectionTitleAction>
      </SectionTitle>
      <section className={classes.section}>
        <Typography className={classes.title}>Application callback URL:</Typography>
        <TextField
          placeholder="https://foo.bar"
          value={appURL}
          onChange={handleAppURLChange}
          variant="outlined"
          className={classes.textField}
          inputProps={{ className: classes.textFieldInput }}
          autoFocus
        />
      </section>
    </div>
  );
};

export default NewOAuthApp;
