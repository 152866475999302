import React /* , { useMemo } */ from 'react';

import { DeleteForever } from '@mui/icons-material';

// import { handleDeleteItems } from 'Drive/itemUtils';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useSelectedItems } from 'Drive/selectedItemContext';
// import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMainContext } from 'ReusableComponents';
import { useMinimalAuth } from 'hooks';
import ApiManager from 'ApiManager';
import { useDriveContext } from 'Drive/DriveContext';
import { CircularProgress, DialogContent, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useContextMenu } from '../../ContextMenuContext';
import PermanentlyDeleteComponent from '../Components/PermanentlyDelete/PermanentlyDelete';
import { removeFolder } from 'Drive/itemUtils';

const PermanentlyDelete = ({ onClose, setDialog }) => {
  const { onOpenSnackbar } = useMainContext();
  const { selectedItems, resetSelectedItems } = useSelectedItems();

  const { menuOptions } = useContextMenu();
  const { type, path } = menuOptions;

  const user = useMinimalAuth();
  const { handleOptimisticUpdate } = useDriveContext();

  const isMap = path?.type !== 'folder';
  const onClick = () => {
    setDialog({
      title: `Permanently delete ${type === 'multiple' ? 'items' : isMap ? 'layer' : 'folder'}`,
      content: (
        <PermanentlyDeleteComponent
          isMap={isMap}
          cancel={() => setDialog(null)}
          confirm={() => {
            onConfirm();
            onClose();
          }}
        />
      ),
    });

    onClose();
  };

  const onConfirm = () => {
    setDialog({
      title: `Permanently removing items`,
      content: (
        <DialogContent>
          <ListItem>
            <ListItemIcon>{<CircularProgress />}</ListItemIcon>
            <ListItemText>This can take several seconds</ListItemText>
          </ListItem>
        </DialogContent>
      ),
    });

    let items;
    if (type === 'multiple') {
      items = selectedItems;
    } else {
      items = [path];
    }
    resetSelectedItems();
    const f = async () => {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'folder') {
          await removeFolder(items[i].id, user);
        } else {
          await ApiManager.delete(`/v3/path/${items[i].id}`, null, user);
        }
      }
    };
    const apiCall = f();
    const onSuccess = () => {
      setDialog(null);

      resetSelectedItems && resetSelectedItems();
      onOpenSnackbar({
        level: 'success',
        content: `Successfully deleted ${items.length} item${items.length > 1 ? 's' : ''}`,
      });
    };

    const onError = (e) => {
      setDialog(null);
      const message = 'An error ocurred';
      onOpenSnackbar({
        level: 'error',
        content: e?.message ? message + `: ${e.message}` : message,
      });
    };

    const pathIds = items.map((l) => l.id);
    const updateMapsFunc = (maps) => {
      return maps.filter((item) => !pathIds.includes(item.id));
    };
    const updateFilesFunc = (files) => {
      return files.filter((item) => !pathIds.includes(item.id));
    };

    const updateFoldersFunc = (folders) => {
      return folders.filter((item) => !pathIds.includes(item.id));
    };

    let params = {
      updateMaps: updateMapsFunc,
      updateFolders: updateFoldersFunc,
      updateFiles: updateFilesFunc,
      apiCall,
      onSuccess,
      onError,
    };

    handleOptimisticUpdate(params);
  };

  return (
    <CombinedMenuItem
      icon={<DeleteForever />}
      label={type === 'multiple' ? 'Delete ' + selectedItems.length + ' items ' : 'Delete'}
      action={onClick}
    />
  );
};

export default PermanentlyDelete;
