import React, { useRef, useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import AvatarEditor from 'react-avatar-editor';

import { DropZoneArea } from '../../../ReusableComponents';

const useStyles = makeStyles()((theme) => ({
  content: {
    width: '500px',
    [theme.breakpoints.down('lg')]: {
      width: '260px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewImg: {
    maxHeight: '200px',
    aspectRatio: 1,
    border: `1px solid ${theme.palette.action.focus}`,
    borderRadius: '50%',
    objectFit: 'contain',
  },
}));

const ProfilePictureDialog = ({ open, handleClose, newPicture, setNewPicture, onSubmit }) => {
  const { classes } = useStyles();
  const editor = useRef(null);
  const submit = useRef(false);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);
  const [circleColor, setCircleColor] = useState([255, 255, 255, 0.6]);

  useEffect(() => {
    if (submit.current && editor.current) {
      submit.current = false;
      setNewPicture(editor.current.canvas.toDataURL('image/jpeg'));
      onSubmit(editor.current.canvas.toDataURL('image/jpeg'));
    }
  }, [circleColor, setNewPicture, onSubmit]);

  useEffect(() => {
    if (open) {
      setCircleColor([255, 255, 255, 0.6]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Upload your profile picture</DialogTitle>
      <DialogContent className={classes.content}>
        {newPicture == null ? (
          <>
            <DropZoneArea
              onChange={(e) => {
                setNewPicture(e);
                setRotate(0);
                setScale(1);
              }}
            />
          </>
        ) : (
          <>
            <AvatarEditor
              ref={editor}
              image={newPicture && newPicture[0]}
              width={200}
              height={200}
              color={circleColor} // RGBA
              borderRadius={150}
              border={0}
              scale={scale}
              style={{ backgroundColor: 'rgb(0,0,0)' }}
              rotate={rotate}
            />
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <Typography paragraph>Zoom</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Slider
                    defaultValue={1}
                    valueLabelDisplay="auto"
                    step={0.1}
                    min={0.8}
                    max={10}
                    onChange={(e, v) => setScale(v)}
                    marks={[
                      { value: 0.8, label: '0.8' },
                      { value: 10, label: '10' },
                    ]}
                    valueLabelFormat={(x) => `${x}x`}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <Typography paragraph>Rotation</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Slider
                    defaultValue={0}
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={360}
                    onChange={(e, v) => setRotate(v)}
                    marks={[
                      { value: 0, label: '0' },
                      { value: 180, label: '180' },
                      { value: 360, label: '360' },
                    ]}
                    valueLabelFormat={(x) => `${x}°`}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Cancel
        </Button>
        <Button
          disabled={!newPicture}
          color="primary"
          autoFocus
          onClick={() => {
            submit.current = true;
            setCircleColor([0, 0, 0, 0]);
          }}
        >
          Upload profile picture
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProfilePictureDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  newPicture: PropTypes.string,
  setNewPicture: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProfilePictureDialog;
