import React, { useCallback /* , useState */ } from 'react';

import { EditNote } from '@mui/icons-material';

import { useContextMenu } from '../../ContextMenuContext';
import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';

import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useDriveContext } from 'Drive/DriveContext';
import { handleDescription } from 'Drive/itemUtils';

import { useMainContext } from 'ReusableComponents';

import ApiManager from 'ApiManager';
import { useMinimalAuth } from 'hooks';

const Description = ({ setDialog, closeDialog, onClose }) => {
  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const user = useMinimalAuth();

  const { onOpenSnackbar } = useMainContext();
  const { currentFolderInfo, updatePath } = useCurrentFolder();
  const { handleOptimisticUpdate } = useDriveContext();

  const handleSubmit = useCallback(
    (res) => {
      if (currentFolderInfo && currentFolderInfo.id === path?.id) {
        ApiManager.patch(`/v3/path/${path.id}/metadata`, { description: res }, user)
          .then((r) => {
            updatePath(path?.id, { description: res });
            onOpenSnackbar({ level: 'success', content: 'description updated' });
          })
          .catch((e) => {
            onOpenSnackbar({ level: 'error', content: e.message });
          });
      } else {
        handleDescription({
          description: res,
          path,
          onOpenSnackbar,
          handleOptimisticUpdate,
          user,
        });
      }

      closeDialog();
    },
    [closeDialog, currentFolderInfo, handleOptimisticUpdate, onOpenSnackbar, path, updatePath, user]
  );

  return (
    <>
      <CombinedMenuItem
        icon={<EditNote />}
        label="Description"
        action={() => {
          onClose();
          setDialog({
            type: 'description',
            title: 'Description of ' + path?.name,
            action: handleSubmit,
            initialValue: path?.description,
          });
        }}
        openDialog
      />
    </>
  );
};

export default Description;
