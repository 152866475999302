import React from 'react';

import { ExitToApp } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useContextMenu } from '../../ContextMenuContext';

const View = ({ onClose }) => {
  const { onFolderClick, mainMode, onToLocationClick } = useCurrentFolder();

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  return (
    <React.Fragment>
      <CombinedMenuItem
        icon={<ExitToApp />}
        label={`Open ${path?.type === 'file' ? 'File' : path?.type === 'folder' ? 'Folder' : 'Layer'}`}
        onClick={() => {
          onClose();
          if (mainMode === '/drive/search') {
            onToLocationClick(path);
          } else {
            onFolderClick(path);
          }
        }}
        openNew
      />
    </React.Fragment>
  );
};

export default View;
