import { makeStyles } from 'tss-react/mui';

const useChipStyles = makeStyles({
  name: 'TypeChip',
})(() => ({
  root: {
    padding: '0 12px',
    gap: 8,
    '& .MuiChip-icon': { margin: 0 },
    '& .MuiChip-label': { lineHeight: 1, padding: 0, textTransform: 'capitalize' },
    '& .MuiChip-deleteIcon': { margin: 0 },
  },
}));

export default useChipStyles;
