import React, { useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const InfoRow = ({ title, value }) => {
  return (
    <Box>
      <Typography variant="overline">{title}</Typography>
      <Typography variant="body1">{value}</Typography>
    </Box>
  );
};

const BillingInfoDisplay = ({ billingInfo }) => {
  const data = useMemo(
    () => [
      ...(billingInfo?.isCompany ? [{ title: 'Name', value: billingInfo?.contactName }] : []),
      ...(!billingInfo?.isCompany
        ? [{ title: 'Name', value: billingInfo?.name }]
        : [{ title: 'Company name', values: billingInfo?.companyName }]),
      { title: 'Street', value: `${billingInfo?.address?.street}, ${billingInfo?.address?.number}` },
      { title: 'City', value: billingInfo?.address?.city },
      { title: 'Zip code', value: billingInfo?.address?.zip },
      { title: 'Country', value: billingInfo?.country },
      ...(billingInfo?.vatNumber ? [{ title: 'Vat number', value: billingInfo?.vatNumber }] : []),
    ],
    [
      billingInfo?.address?.city,
      billingInfo?.address?.number,
      billingInfo?.address?.street,
      billingInfo?.address?.zip,
      billingInfo?.companyName,
      billingInfo?.contactName,
      billingInfo?.country,
      billingInfo?.isCompany,
      billingInfo?.name,
      billingInfo?.vatNumber,
    ]
  );

  if (billingInfo == null) {
    return <CircularProgress />;
  }

  const GAP = 2;

  return (
    <Box
      sx={{
        display: 'grid',
        rowGap: 1,
        columnGap: GAP,
        gridTemplateColumns: (theme) => `repeat(auto-fill, minmax(120px, calc(50% - ${theme.spacing(GAP / 2)})))`,
      }}
    >
      {data?.map((d) => (
        <InfoRow key={`InfoRow_${d?.title}_${d?.value}`} {...d} />
      ))}
    </Box>
  );
};

export default BillingInfoDisplay;
