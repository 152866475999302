import React from 'react';

import { components } from 'react-select';
import { ArrowDropDown } from '@mui/icons-material';

import { UserSelect } from 'ReusableComponents';
import { useProfileContext } from 'ReusableComponents/ProfileContext';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <ArrowDropDown
      sx={{
        color: (theme) => theme.palette.action.active,
        transform: `rotate(${props?.selectProps?.menuIsOpen ? 180 : 0}deg)`,
      }}
    />
  </components.DropdownIndicator>
);

const User = ({ input, searchVal, setSearchVal }) => {
  const { profile } = useProfileContext(searchVal);

  return (
    <UserSelect
      sx={{ width: '100%', height: (theme) => theme.spacing(6) }}
      placeholder={input?.placeholder}
      value={!!profile ? { id: profile?.id, username: profile?.username } : input?.initialValue}
      onChange={(res) => setSearchVal(res?.id)}
      components={{ DropdownIndicator, IndicatorSeparator: null }}
    />
  );
};

export default User;
