import React from 'react';

import { Paper, ButtonBase, Typography /* , Divider */ } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import ProjectTypeIcon from '../../../ReusableComponents/ProjectTypeIcon';

const useStyles = makeStyles({ name: 'ProjectTypeStep' })((theme, _params, classes) => ({
  buttonContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    width: '100%',
    gap: 2,
    borderRadius: theme.spacing(1),
  },
  button: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    minWidth: 32,
    boxSizing: 'content-box',
    gap: theme.spacing(1),
    [`&:first-of-type`]: { borderRadius: theme.spacing(1, 0, 0, 1) },
    [`&:last-of-type`]: { borderRadius: theme.spacing(0, 1, 1, 0) },
    [`&.${classes.selected}`]: { boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}` },
  },
  buttonIcon: {
    width: '100%',
    minWidth: 24,
    maxWidth: 64,
    aspectRatio: 1,
  },
  buttonLabel: {
    textAlign: 'center',
  },
}));

const ProjectTypeStep = ({ selectedType, setSelectedType }) => {
  const { classes, cx } = useStyles();

  return (
    <Paper className={classes.buttonContainer} elevation={4}>
      <ButtonBase
        onClick={() => setSelectedType('map')}
        className={cx(classes.button, selectedType === 'map' && classes.selected)}
      >
        <ProjectTypeIcon className={classes.buttonIcon} type={'map'} />
        <Typography className={classes.buttonLabel} variant="subtitle2">
          Raster
        </Typography>
      </ButtonBase>
      <ButtonBase
        onClick={() => setSelectedType('shape')}
        className={cx(classes.button, selectedType === 'shape' && classes.selected)}
      >
        <ProjectTypeIcon className={classes.buttonIcon} type={'shape'} />
        <Typography className={classes.buttonLabel} variant="subtitle2">
          Vector
        </Typography>
      </ButtonBase>
      <ButtonBase
        onClick={() => setSelectedType('pointCloud')}
        className={cx(classes.button, selectedType === 'pointCloud' && classes.selected)}
      >
        <ProjectTypeIcon className={classes.buttonIcon} type={'pointCloud'} />
        <Typography className={classes.buttonLabel} variant="subtitle2">
          Point Cloud
        </Typography>
      </ButtonBase>
    </Paper>
  );
};

ProjectTypeStep.propTypes = {
  selectedType: PropTypes.string,
  setSelectedType: PropTypes.func.isRequired,
};

export default ProjectTypeStep;
