import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FileCopy } from '@mui/icons-material';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import { useStyles as useSectionStyles } from '../../../ReusableComponents';

const useStyles = makeStyles()((theme) => ({
  sectionContainer: {
    '&:not(:last-child)': {
      marginBottom: '8px',
    },
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  linkText: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    flexGrow: 1,
    marginRight: '24px',
    padding: '6px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
  },
  copyButton: {
    width: '110px',
    minWidth: '110px',
    boxSizing: 'border-box',
  },
}));

const LinkSection = ({ titleText, link, disableTitle = false }) => {
  const sectionClasses = useSectionStyles();

  const { classes, theme } = useStyles();

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    copy(link);
    setIsClicked(true);
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setIsClicked(false);
    }, 2000);
    return () => clearTimeout(id);
  }, [isClicked]);

  return (
    <section className={classes.sectionContainer}>
      {!disableTitle && (
        <Typography variant="subtitle2" className={sectionClasses.groupTitle} gutterBottom>
          {titleText}
        </Typography>
      )}
      <div className={classes.linkContainer}>
        <Typography className={classes.linkText}>{link}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClick}
          className={classes.copyButton}
          startIcon={<FileCopy />}
          style={{ backgroundColor: isClicked && theme.palette.success.main }}
        >
          {isClicked ? 'Copied!' : 'Copy'}
        </Button>
      </div>
    </section>
  );
};

LinkSection.propTypes = {
  titleText: PropTypes.string,
  link: PropTypes.string.isRequired,
  disableTitle: PropTypes.bool,
};

export default LinkSection;
