import React, { useEffect } from 'react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const ServiceWorker = ({ children }) => {
  /* useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;
        waitingServiceWorker?.postMessage({ type: 'SKIP_WAITING' });
      },
    });

    return () => {
      serviceWorkerRegistration.unregister();
    };
  }, []); */

  useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA

    serviceWorkerRegistration?.unregister();
  }, []);

  return <>{children}</>;
};

export default ServiceWorker;
