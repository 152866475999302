import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { withAuthFuncs, withAuth } from 'Account';
import { withMainContext } from 'ReusableComponents/MainContext';

import { PayPalButton } from 'react-paypal-button-v2';
import ApiManager from 'ApiManager';

import PageRedirect from 'ReusableComponents/PageRedirect';

import { Dialog, DialogActions, DialogContent, Button, Typography, Slide, CircularProgress, Box } from '@mui/material';
import BillingInfoCard from 'ReusableComponents/BillingInfoCard/BillingInfoCard';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Pay extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.hadSuccess = false;
    this.state = {
      waiting: false,
      pay: this.props.pay,
    };

    this.originalAmount = this.props.authUser.balance - this.props.authUser.plan.maxNegative;
  }

  componentDidMount() {
    ApiManager.get(`/v3/pricing/public`)
      .then((r) => {
        this.setState({ vat: r.countries });
      })
      .catch((e) => {
        console.log(e);
        this.props.onClose();
      });

    if (!this.state.pay && this.props.newPlan && this.props.authUser.billingInformation) {
      this.getPay();
    }

    this.pollUser = setInterval(() => {
      if (this.state.waiting) {
        this.props.fetchUserInfo();
      }
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.newPlan && this.props.authUser.billingInformation && !this.state.pay) {
      this.getPay();
    }

    if (this.state.waiting) {
      let r = this.calculateCosts();
      console.log(r);
      if (r.canPayNow && !this.hadSuccess) {
        this.hadSuccess = true;
        console.log('running success');
        this.props.setHideNavbar(false);
        this.props.onSuccess();
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollUser);
  }

  getPay = () => {
    console.log('fetching them now');
    ApiManager.patch('/v3/account/plan', this.props.newPlan, this.props.authUser).then((result) => {
      console.log(result);
      this.setState({ pay: result.price - result.refund, refund: result.refund });
    });
  };

  calculateCosts = () => {
    let vatPercent = 0;
    let toPay;
    let cost;

    if (!this.props.newPlan && this.props.authUser.billingInformation && this.state.vat) {
      vatPercent = this.state.vat.find((c) => c.name === this.props.authUser.billingInformation.country);
      if (this.props.authUser.billingInformation.isCompany) {
        vatPercent = vatPercent.companyVat;
      } else {
        vatPercent = vatPercent.vat;
      }
      vatPercent = this.props.payAll ? 0 : vatPercent;
    }

    if (this.props.payAll) {
      toPay = Number(this.state.pay.toFixed(2));
      cost = Number(this.state.pay.toFixed(2));
    } else {
      cost = Number((this.state.pay * (1 + vatPercent)).toFixed(2));
      toPay = Number((cost - this.originalAmount).toFixed(2));
      if (toPay > 0) {
        toPay = Math.max(toPay, 1);
      }
    }
    let canPay = toPay <= 0 || !!this.props.authUser.paymentMethod;
    let canPayNow = cost + this.props.authUser.plan.maxNegative <= this.props.authUser.balance;
    return { cost: cost, toPay: toPay, vatPercent: vatPercent, canPay: canPay, canPayNow: canPayNow };
  };

  render() {
    console.log('hoi');
    console.log(this.state.pay);
    let r = this.calculateCosts();
    let toPay = r.toPay;
    let cost = r.cost;
    // let vatPercent = r.vatPercent;
    let canPay = r.canPay;
    // let canPayNow = r.canPayNow;

    console.log('heeeee');
    console.log(this.props.authUser);

    if (this.state.waiting) {
      return (
        <PageRedirect
          message={'Processing payment, keep this window open. (This may take a minute, do not close this window)'}
        />
      );
    }
    // both this.props.payAll && !this.props.authUser.invoiceDetails
    if (!this.props.payAll && !this.props.authUser.billingInformation) {
      //in this case return a dialog where the user needs to fill in his/her billing details
      //once it is filled in submit it to the API and use fetchUserInfo
      //after fetching the userInfo the condition will be false and user will automatically progress to the next step
      return (
        <Dialog TransitionComponent={Transition} open={true} onClose={this.props.onClose}>
          <DialogTitle>Add Billing address</DialogTitle>
          <DialogContent>
            <BillingInfoCard isDialog onClose={this.props.onClose} />
          </DialogContent>
        </Dialog>
      );
    } else if (!this.state.pay && this.state.pay !== 0) {
      return (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={true}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      );
    } else if (!this.props.payAll && canPay) {
      return (
        <Dialog
          maxWidth="xs"
          fullWidth
          open={true}
          aria-labelledby="form-dialog-title"
          TransitionComponent={Transition}
        >
          <DialogTitle onClose={this.props.onClose} id="form-dialog-title">
            Make payment
          </DialogTitle>
          <DialogContent>
            <Typography>
              {cost > 0 ? 'You will be charged' : 'You will be refunded'}{' '}
              {Math.abs(cost).toLocaleString(undefined, {
                style: 'currency',
                currency: 'EUR',
              })}
              {!this.props.payAll && ' incl. VAT'}
            </Typography>
            {this.state.refund && this.state.refund > 0 && cost > 0
              ? ' (A refund of ' +
                this.state.refund.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'EUR',
                }) +
                ' from your previous subscription has been deducted)'
              : null}
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.props.onSuccess();
              }}
              color="primary"
            >
              {cost < 0 ? 'Accept' : 'Pay now'}
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog className="deposit" open={true} TransitionComponent={Transition}>
          <DialogTitle onClose={this.props.onClose}>Choose payment method</DialogTitle>
          <DialogContent>
            <Box display={'flex'} flexDirection={'column'}>
              <Box>
                <Typography variant="subtitle1" component="h2">
                  Top up your balance with {toPay.toLocaleString(undefined, { style: 'currency', currency: 'EUR' })}{' '}
                </Typography>

                {!this.props.payAll && (
                  <Typography>
                    {` to pay the due amount of ${cost.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'EUR',
                    })} incl. VAT`}
                  </Typography>
                )}

                {this.state.refund && this.state.refund > 0
                  ? '(A refund of ' +
                    this.state.refund.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'EUR',
                    }) +
                    ' from your previous subscription has been deducted)'
                  : null}

                <Typography py={2}>
                  Tip: On the{' '}
                  <Link
                    to="/storage"
                    onClick={(e) => {
                      if (this.props.onClose) {
                        this.props.onClose();
                      }

                      if (this.props.closeStorage) {
                        this.props.closeStorage();
                      }
                    }}
                  >
                    Storage
                  </Link>{' '}
                  page you can couple a credit card to avoid manual payments.
                </Typography>
              </Box>
              {this.state.waiting ? (
                <Box display={'flex'} justifyContent="center" alignItems="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Box width={'100%'}>
                  <PayPalButton
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              currency_code: 'EUR',
                              value: toPay,
                              breakdown: {
                                item_total: { value: toPay, currency_code: 'EUR' },
                              },
                            },
                            items: [
                              {
                                name: 'Ellipsis Balance',
                                unit_amount: { value: 0.01, currency_code: 'EUR' },
                                quantity: toPay * 100,
                                description: this.props.authUser.username,
                              },
                            ],
                          },
                        ],
                        application_context: {
                          shipping_preference: 'NO_SHIPPING',
                        },
                      });
                    }}
                    onSuccess={(details, data) => {
                      console.log('RUNNING SUCCESSS');
                      this.props.setHideNavbar(true);
                      this.setState({ waiting: true });
                    }}
                    onError={(err) => {
                      console.error(err);
                    }}
                    catchError={(err) => {
                      console.error(err);
                    }}
                    options={{
                      currency: 'EUR',
                      clientId: 'Aa-0MSzbq7vWNzbGZgd0o34Mmvitdo5P0XhLpIw6uP6x7DgjuvttTthcr5GFc8ANaJ5ZiOpCRfJTKLpO',
                    }}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      );
    }
  }
}

export default withMainContext(withAuth(withAuthFuncs(withRouter(Pay))));
