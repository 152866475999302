import React, { useEffect, useRef } from 'react';

import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import { useMainContext } from 'ReusableComponents';
import { makeStyles } from 'tss-react/mui';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';

const MAP_ZOOM = 24;

const useStyles = makeStyles({ name: 'MapWrapper' })((theme, _params, classes) => ({
  root: {
    width: '100%',
    height: 200,
    position: 'relative',
    [`&.${classes.active}`]: { '& canvas:hover': { cursor: 'crosshair' } },
  },
  active: {},
}));

const MapWrapper = ({ active, searchVal, handleChange }) => {
  const map = useRef(null);
  const { classes: styles, cx } = useStyles();
  const mapContainer = useRef(null);
  const mapboxDraw = useRef(null);
  const { onOpenSnackbar } = useMainContext();

  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once

    map.current = new maplibregl.Map({
      renderWorldCopies: false,
      container: mapContainer.current,
      style: {
        version: 8,
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        sources: {
          osm: {
            type: 'raster',
            tiles: ['https://www.google.com/maps/vt?lyrs=y@189&x={x}&y={y}&z={z}'],
            tileSize: 256,
            maxzoom: 18,
          },
          search: {
            type: 'geojson',
            data: !!searchVal ? createFeatureCollection(searchVal) : { type: 'FeatureCollection', features: [] },
          },
        },
        layers: [
          {
            id: 'osm',
            type: 'raster',
            source: 'osm',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
          {
            id: 'search-fill',
            type: 'fill',
            paint: { 'fill-opacity': 0.4, 'fill-color': 'yellow' },
            layout: { visibility: 'visible' },
            source: 'search',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
          {
            id: 'search-line',
            type: 'line',
            paint: { 'line-opacity': 1, 'line-color': 'yellow', 'line-width': 2 },
            layout: { visibility: 'visible' },
            source: 'search',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
        ],
      },
      center: [0, 0],
      zoom: 0.1,
      maxZoom: MAP_ZOOM,
      maxPitch: 0,
      dragRotate: false,
    });

    map.current.addControl(new maplibregl.NavigationControl({ showCompass: false }));

    const modes = MapboxDraw.modes;
    modes.draw_rectangle = DrawRectangle;
    mapboxDraw.current = new MapboxDraw({
      modes: modes,
      displayControlsDefault: false,
      controls: { line_string: false, polygon: false, point: false, trash: false },
    });

    map.current.addControl(mapboxDraw.current);

    function onCreated(e) {
      let feature = bbox(e.features[0]);

      handleChange(createBbox(feature));
      mapboxDraw.current.deleteAll();
    }

    map.current.on('draw.create', onCreated);
  }, [active, handleChange]);

  useEffect(() => {
    return () => {
      map?.current?.remove();
    };
  }, []);

  useEffect(() => {
    if (!map.current || !active) return;
    if (active) {
      mapboxDraw.current.changeMode('draw_rectangle');
      onOpenSnackbar({ content: 'Draw rectangle on the map', level: 'success' });
    } else {
      handleChange(null);
    }
  }, [active, handleChange, onOpenSnackbar]);

  useEffect(() => {
    if (!map.current?.getSource('search')) return;

    if (!!map?.current?.getSource('search') && !searchVal) {
      map.current.getSource('search').setData({ type: 'FeatureCollection', features: [] });
    } else if (!!searchVal) {
      map.current.getSource('search').setData(createFeatureCollection(searchVal));
    }
  }, [searchVal]);

  return <div ref={mapContainer} className={cx(styles.root, active && styles.active)} />;
};

export default MapWrapper;

export const createFeatureCollection = (coordinates) => {
  return {
    type: 'FeatureCollection',
    features: [
      coordinates ? bboxPolygon([coordinates?.xMin, coordinates?.yMin, coordinates?.xMax, coordinates?.yMax]) : [],
    ],
  };
};

export const createBbox = (feature) => {
  const round = (coord) => Math.round(coord * 1000000) / 1000000;

  return {
    xMin: round(feature[0]),
    yMin: round(feature[1]),
    xMax: round(feature[2]),
    yMax: round(feature[3]),
  };
};
