import React, { useState, useCallback } from 'react';

import lowerCase from 'lodash/lowerCase';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

const DangerConfirmationDialog = ({
  open,
  handleClose,
  title,
  legend,
  confirmationText,
  onClick,
  cancelText = 'cancel',
  agreeText = 'confirm',
}) => {
  const [confirmation, setConfirmation] = useState('');

  const handleConfirmationReset = () => setConfirmation('');

  const handleChange = useCallback((e) => setConfirmation(e.target.value), []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onClick();
          handleConfirmationReset();
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{legend}</DialogContentText>
          <TextField
            variant="standard"
            autoFocus
            margin="dense"
            id="name"
            label="Layer name"
            type="text"
            fullWidth
            value={confirmation}
            onChange={handleChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              handleConfirmationReset();
            }}
            color="primary"
          >
            {cancelText}
          </Button>
          <Button
            color="primary"
            autoFocus
            disabled={lowerCase(confirmation) !== lowerCase(confirmationText)}
            type="submit"
          >
            {agreeText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DangerConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  confirmationText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  agreeText: PropTypes.string,
};

export default DangerConfirmationDialog;
