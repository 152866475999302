import React, { Component } from 'react';
import { Close, ThreeSixty, Panorama, TurnedInNot, TurnedIn, OpenInNew } from '@mui/icons-material';

import ApiManager from '../ApiManager';
import './ModalView.css';

import { IconButton, Tooltip } from '@mui/material';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';
import { retryableLazy } from 'globalUtils';

const Pannellum = retryableLazy(() => import('pannellum-react'), 'Pannellum');

class ModalView extends Component {
  constructor(props) {
    super(props);
    const getstorage = () => {
      try {
        return JSON.parse(localStorage.getItem('panorama'));
      } catch {
        return false;
      }
    };
    this.state = {
      uploading: false,
      message: '',
      mode360: getstorage(),
      isPanorama: undefined,
      makingCall: false,
      open: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.map) {
      if (this.props?.map?.viewSettings?.isPanorama !== prevProps.map?.viewSettings?.isPanorama) {
        this.setState({ isPanorama: this.props.map.viewSettings.isPanorama });
      }
    }
  }

  switch360 = (newval) => {
    this.setState({ mode360: !this.state.mode360 });
    try {
      localStorage.setItem('panorama', JSON.stringify(!this.state.mode360));
    } catch (e) {
      console.warn(e);
    }
  };

  closeModal = () => {
    this.props.onModalChange(null, null, false);
  };

  makeDefaultSetting = async () => {
    this.setState({ isPanorama: this.state.mode360 });

    this.props.onOpenSnackbar({
      content: 'Preference saved.',
      level: 'success',
    });
    this.setState({ makingCall: false });
  };

  getCorrectIcon = () => {
    return this.state.isPanorama === this.state.mode360 ? <TurnedIn /> : <TurnedInNot />;
  };

  render() {
    let modalContent = this.props.modalContent;

    let image = this.props.modalContent.content;

    let showMakeDefaultButton = false;
    if (typeof this.props?.map?.accessLevel !== 'undefined') {
      showMakeDefaultButton = this.props.map.accessLevel >= ApiManager.accessLevels.customPolygonLayers;
    }

    return (
      <div className="modal-view">
        <Tooltip title={'close window'}>
          <div className="modal-view-close-button-container">
            <IconButton color="secondary" onClick={this.closeModal} size="large">
              <Close />
            </IconButton>
          </div>
        </Tooltip>
        {!this.props.modalContent.isImage ? (
          <div className="modal-view-content">
            <iframe
              style={{ width: '95%', height: '100%' }}
              src={modalContent.content}
              title={modalContent.content}
            ></iframe>
          </div>
        ) : this.state.mode360 ? (
          <div className="modal-view-content">
            <SuspenseContainer displayName="modal-view">
              <Pannellum
                width="100%"
                height="100%"
                image={image}
                pitch={10}
                yaw={180}
                hfov={110}
                author=""
                title=""
                autoLoad
              />
            </SuspenseContainer>
          </div>
        ) : (
          <div className="modal-view-content">
            <div className="geomessage-lightbox">
              <img className="geomessage-lightbox-image" src={image} alt="geoMessage lightbox"></img>
            </div>
          </div>
        )}
        {modalContent.isImage ? (
          <Tooltip title={this.state.mode360 ? 'unwrap' : 'as panorama'}>
            <div className="modal-view-switch-mode-button">
              <IconButton color="secondary" onClick={this.switch360} size="large">
                {this.state.mode360 ? <Panorama /> : <ThreeSixty />}
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="save preferred view mode">
            <div className="modal-view-switch-mode-button">
              <IconButton
                color="secondary"
                onClick={() => {
                  window.open(modalContent.content);
                }}
                size="large"
              >
                <OpenInNew />
              </IconButton>
            </div>
          </Tooltip>
        )}

        {showMakeDefaultButton && this.props.modalContent.isImage ? (
          <Tooltip title={'Open url in new window'}>
            <div className="modal-view-make-standard-button">
              <IconButton color="secondary" onClick={this.makeDefaultSetting} size="large">
                {this.getCorrectIcon()}
              </IconButton>
            </div>
          </Tooltip>
        ) : null}
      </div>
    );
  }
}

export default ModalView;
