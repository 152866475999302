import React, { createContext, useContext, useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { usePaneContentFuncs } from 'Viewer/DetailsPane/PaneContentContext';

const libraryContext = createContext();

export const LibraryProvider = ({ children }) => {
  const { openPane, hidePane } = usePaneContentFuncs();
  const [searchBounds, setSearchBounds] = useState();
  const [selectedSearch, setSelectedSearch] = useState();
  const [blueVisibleBounds, setBlueVisibleBounds] = useState([]);

  const [importPaneTab, setImportPaneTab] = useState('browse');

  const emitSearch = useCallback(() => setSelectedSearch(null), []);

  const handleMapBoundClick = useCallback(
    (value) => {
      if (selectedSearch?.id === value.map.id) {
        setSelectedSearch(value.map);
        openPane();
        return;
      }
      openPane();
      setSelectedSearch(value.map);
    },
    [openPane, selectedSearch]
  );

  const value = useMemo(
    () => ({
      importPaneTab,
      setImportPaneTab,
      setSearchBounds,
      searchBounds,
      handleMapBoundClick,
      emitSearch,
      selectedSearch,
      setSelectedSearch,
      blueVisibleBounds,
      setBlueVisibleBounds,
    }),
    [
      emitSearch,
      importPaneTab,
      searchBounds,
      setSearchBounds,
      handleMapBoundClick,
      selectedSearch,
      blueVisibleBounds,
      setBlueVisibleBounds,
    ]
  );

  return <libraryContext.Provider value={value}>{children}</libraryContext.Provider>;
};

export function useLibrary() {
  const context = useContext(libraryContext);
  if (context === undefined) {
    throw new Error('useLibrary must be used within an libraryProvider');
  }
  return context;
}

export function withLibrary(Component) {
  return function WrapperComponent(props) {
    const context = useLibrary();
    return <Component {...props} {...context} />;
  };
}

export function withLibraryContext(Component) {
  return function WrapperComponent(props) {
    return (
      <LibraryProvider>
        <Component {...props} />
      </LibraryProvider>
    );
  };
}
