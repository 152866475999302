import React from 'react';

import { IconButton } from '@mui/material';

import CustomAvatar from 'ReusableComponents/CustomAvatar';

import { useUserInfoDispatch } from './userInfoDialogContext';

const AvatarWithInfo = ({ user, disabled, size, className, ButtonProps, ...props }) => {
  const setUserInfoDialogOpen = useUserInfoDispatch();

  if (Object.keys(props).length) {
    console.log({ ...props });
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setUserInfoDialogOpen({
      open: true,
      user,
    });
  };

  return (
    <IconButton disabled={disabled} onClick={handleOnClick} className={className} {...ButtonProps}>
      <CustomAvatar user={user} size={size} {...props} />
    </IconButton>
  );
};
export default AvatarWithInfo;
