import React from 'react';

import { /* ButtonGroup, */ Tooltip, /* Button, */ IconButton } from '@mui/material';
import { List as ListIcon, ViewModule as ViewModuleIcon } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useDriveContext } from 'Drive/DriveContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useStyles = makeStyles({ name: 'ViewButtonGroup' })((theme) => ({
  root: {
    flexShrink: 0,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    boxShadow: theme.shadows[1],
    marginTop: theme.spacing(0.5),
  },
  button: { padding: 0, width: theme.spacing(6), height: theme.spacing(6), borderRadius: 0 },

  icon: { marginTop: theme.spacing(1) },
}));

const ViewButtonGroup = ({ className }) => {
  const { view, setView } = useDriveContext();
  const { mainMode, currentFolderInfo } = useCurrentFolder();
  const { classes: styles } = useStyles();

  return (
    <Tooltip title={view === 'grid' ? 'Grid' : 'List'} arrow>
      <span>
        <IconButton
          className={styles.icon}
          onClick={() => setView(view === 'grid' ? 'list' : 'grid')}
          disabled={(currentFolderInfo && currentFolderInfo.type !== 'folder') || mainMode === '/drive/trash'}
        >
          {view !== 'grid' ? <ViewModuleIcon /> : <ListIcon />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ViewButtonGroup;
