import React from 'react';
import { Link } from 'react-router-dom';
import { useMainContext } from 'ReusableComponents';
import { ArrowRightAlt } from '@mui/icons-material';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useButtonStyles = makeStyles({ name: 'NoResults-DriveButton' })((theme) => ({
  root: {
    flex: `1 0 calc(50% - 6px)`,
    minWidth: 155,
  },
  iconButton: {
    '& .MuiButton-label': { paddingTop: 2 },
    '& .MuiButton-endIcon': { transition: theme.transitions.create(['margin-right', 'margin-left']), marginTop: -2 },
    '&:hover .MuiButton-endIcon': { marginLeft: 16, marginRight: -12 },
  },
  demo: {
    flexBasis: '100%',
  },
}));

export const DriveButton = ({ variant = 'contained', color = 'primary', label = 'My Drive', classes, ...props }) => {
  const { classes: styles, cx } = useButtonStyles();

  return (
    <Button className={cx(styles.root, classes)} variant={variant} color={color} {...props}>
      {label}
    </Button>
  );
};

const LinkButton = ({ location, ...props }) => <DriveButton component={Link} to={location} {...props} />;

export const BuyNowButton = (props) => <DriveButton onClick={props.onClick} label={props.label} />;

export const MyDriveButton = (props) => <LinkButton location="/drive/me" label="My Drive" {...props} />;

export const SharedWithMeButton = (props) => <LinkButton location="/drive/shared" label="Shared with Me" {...props} />;

export const FavoritesButton = (props) => <LinkButton location="/drive/favorites" label="Favorites" {...props} />;

export const SearchButton = (props) => <LinkButton location="/search" label="Community Catalog" {...props} />;

export const LoginButton = (props) => (
  <LinkButton label={props.overRideText ? props.overRideText : 'Login'} {...props} />
);

export const RegisterButton = (props) => <LinkButton location="/register" label="Register" {...props} />;

export const DemoButton = ({ type = 'demo', ...props }) => {
  const { classes: styles } = useButtonStyles();
  const { setTourOpen } = useMainContext();

  return (
    <DriveButton
      onClick={() => setTourOpen(type)}
      label="Show me around"
      classes={[styles.iconButton, styles.demo]}
      endIcon={<ArrowRightAlt fontSize="small" />}
      variant="text"
      {...props}
    />
  );
};

export const NewMapButton = (props) => {
  const { handleOpenNewProjectDialog } = useMainContext();

  return <DriveButton onClick={handleOpenNewProjectDialog} label="New layer" {...props} />;
};
