import React from 'react';

import { Typography } from '@mui/material';

import { CenteredBox } from '../../CenteredBox';
import { DemoButton, LoginButton, RegisterButton } from '../DriveButtons';
import { makeStyles } from 'tss-react/mui';
import { useLocation } from 'react-router';

const useStyles = makeStyles({ name: 'UserNotAuthorized' })((theme) => ({
  root: {
    display: 'flex',
    flex: '1 0 100%',
    flexDirection: 'column',
    height: '100%',
  },
  img: { maxWidth: '33vw', maxHeight: '33vh', marginTop: '1rem', marginBottom: '1.5rem' },
  buttonContainer: {
    display: 'flex',
    maxWidth: '50vw',
    gap: 12,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
}));

const UserNotAuthorized = () => {
  const location = useLocation();
  const { classes: styles } = useStyles();

  return (
    <div className={styles.root}>
      <CenteredBox>
        <Typography variant="h1" align="center">
          You are currently not logged in.
        </Typography>
        <img className={styles.img} src="/images/drive/Astronaut seated_2.svg" alt="Not logged in" />
        <div className={styles.buttonContainer}>
          <RegisterButton variant="outlined" classes="tutorialRegister" />
          <LoginButton location={{ pathname: '/login', state: { from: `${location.pathname}${location.search}` } }} />
          <DemoButton />
        </div>
      </CenteredBox>
    </div>
  );
};

export default UserNotAuthorized;
