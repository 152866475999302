import React, { useState, useEffect, useCallback } from 'react';

import { Box, Button, CircularProgress } from '@mui/material';
import { useMainContext } from 'ReusableComponents';

import ApiManager from 'ApiManager';
import BillingInfoDisplay from './BillingInfoDisplay';
import EditBillingInfo from './EditBillingInfo';
// import { useAuth, useAuthFuncs } from 'hooks';
import { useAuth, useAuthFuncs } from 'Account/AuthContext';

// onClose; function used if the element is a dialog
const BillingInfoCard = ({ isDialog = false, onClose = () => {}, backOffice = false, editUser }) => {
  // const { classes } = useStyles();
  const { onOpenSnackbar } = useMainContext();
  const user = useAuth();
  if (!backOffice) {
    editUser = user;
  }

  const { fetchUserInfo } = useAuthFuncs();

  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [countries, setCountries] = useState(null);
  const [tab, setTab] = useState(0);
  let tabChoice = editUser.billingInformation == null || editUser.billingInformation?.isCompany ? 0 : 1;

  const fetchCountriesAndInfo = useCallback(async () => {
    setLoading(true);
    try {
      let data = await ApiManager.get(`/v3/pricing/public`);
      setCountries(data.countries);
      setTab(tabChoice);
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCountriesAndInfo();
  }, [edit, fetchCountriesAndInfo]);

  const updateBillingInfo = async (body) => {
    setLoading(true);
    try {
      if (backOffice) {
        body.userId = editUser.id;
        await ApiManager.put('/v3/backoffice/billingInformation', body, user);
      } else {
        await ApiManager.put('/v3/account/billingInformation', body, user);
      }

      await fetchCountriesAndInfo();
      fetchUserInfo();
      setLoading(false);
      onOpenSnackbar({
        level: 'success',
        content: 'Billing address successfully updated!',
      });
      if (!isDialog || backOffice) {
        onClose();
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      // onClose();
      onOpenSnackbar({
        level: 'error',
        content: err?.message,
      });
    }
  };
  const onCancel = () => {
    if (isDialog) {
      onClose();
    } else {
      setEdit(false);
    }
  };
  if (isDialog) {
    return (
      <EditBillingInfo
        tab={tab}
        countries={countries}
        billingInformation={editUser.billingInformation}
        updateBillingInfo={updateBillingInfo}
        setEdit={setEdit}
        onCancel={onCancel}
        setTab={setTab}
      />
    );
  }

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : user.billingInformation == null || edit ? (
        <EditBillingInfo
          tab={tab}
          countries={countries}
          billingInformation={editUser.billingInformation}
          updateBillingInfo={updateBillingInfo}
          setEdit={setEdit}
          onCancel={onCancel}
          setTab={setTab}
        />
      ) : (
        <>
          <BillingInfoDisplay billingInfo={editUser.billingInformation} />
          <Box display="flex" justifyContent={'center'} mt={2} mb={-1}>
            <Button variant="outlined" color="primary" onClick={() => setEdit(!edit)}>
              Update Billing address
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default BillingInfoCard;
