import React, { useRef } from 'react';
import { Steps } from 'intro.js-react';

const TourSteps = ({ onCloseTour, enabled, onBeforeChange, steps, tourOpen }) => {
  const stepRef = useRef();
  const startRef = useRef(false);

  const handleBeforeChange = (step) => {
    onBeforeChange(step);

    if (enabled) {
      stepRef.current?.updateStepElement(step);
    }
  };

  const onClose = (completed) => {
    if (!!startRef.current) {
      console.log('onExit', enabled, completed);
      startRef.current = false;
      onCloseTour(completed);
    }
  };

  return (
    <Steps
      style={{ zIndex: 1000000 }}
      enabled={enabled}
      steps={steps}
      ref={stepRef}
      onExit={() => onClose(false)}
      initialStep={0}
      onBeforeChange={handleBeforeChange}
      onStart={() => {
        console.log('onStart', enabled, tourOpen);
        onBeforeChange(0);
        startRef.current = true;
      }}
      onComplete={() => onClose(true)}
      options={{
        showStepNumbers: true,
        showProgress: true,
        disableInteraction: true,
        keyboardNavigation: false,
        showBullets: false,
        exitOnOverlayClick: false,
        scrollToElement: true,
      }}
    />
  );
};

export default TourSteps;
