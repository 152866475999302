import React /* , { useState } */ from 'react';

import { NoteAdd /* , PhotoCamera */ } from '@mui/icons-material';
// import { Tooltip, IconButton, Button } from '@mui/material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useMainContext } from 'ReusableComponents';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import ApiManager from 'ApiManager';
// import { uploadToAPI } from 'FilesManagement/utils';
import { useAuthFuncs, useMinimalAuth } from 'hooks';
import { useUploadLogic } from 'UploadDialog';
// import { useDriveContext } from 'Drive/DriveContext';
import VersionUtility from 'VersionUtility';
import { uploadFormats as rasterFormats } from 'FilesManagement/UploadPage/RasterUpload';
import { uploadFormats as vectorFormats } from 'FilesManagement/UploadPage/VectorUpload';
import { parseExtension } from 'Drive/FileItem/FilesUtils';
import SpatialFileComponent from '../Components/SpatialFile/SpatialFile';

const NewFile = React.forwardRef(({ close, setDialog }, ref) => {
  const { handleOpenNewProjectDialog } = useMainContext();

  const { currentFolderInfo, mainMode } = useCurrentFolder();
  const user = useMinimalAuth();
  const { fetchUserInfo } = useAuthFuncs();

  const { handleStartUpload } = useUploadLogic();
  const { addPath } = useMainContext();

  const disabled =
    ((mainMode === '/drive/shared' || mainMode === '/drive/favorites') && !currentFolderInfo) ||
    (currentFolderInfo && currentFolderInfo.type !== 'folder') ||
    mainMode === '/drive/trash' ||
    mainMode === '/drive/search' ||
    (currentFolderInfo && currentFolderInfo?.yourAccess?.accessLevel < ApiManager.newAccessLevel['edit+']);

  const onUploadSuccess = (res) => {
    ApiManager.get(`/v3/path/${res.id}`, null, user)
      .then((r) => {
        r = VersionUtility.convertPathInfo(r);
        addPath({ parentId: currentFolderInfo?.id, path: r, id: r.id, mainMode: mainMode });
      })
      .catch((e) => {
        console.log(e);
      });

    fetchUserInfo();
  };

  const onUploadError = () => {};

  const onSelectFile = (event) => {
    let types = [];
    for (let i = 0; i < vectorFormats.length; i++) {
      types = types.concat(vectorFormats[i].formats);
    }
    for (let i = 0; i < rasterFormats.length; i++) {
      types = types.concat(rasterFormats[i].formats);
    }

    types = types.map((t) => `.${t}`);

    let found;
    for (let i = 0; i < event.target.files.length; i++) {
      const name = event.target.files[i].name;
      let { fileExtension } = parseExtension(name);

      if (types.includes(fileExtension)) {
        found = name;
      }
    }
    if (found) {
      onDialog(event);
    } else {
      onUpload(event);
    }
    close();
  };

  const onUpload = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      let name = file.name;
      name = name.replaceAll(' ', '');
      if (name.length > 64) {
        name = name.slice(name.length - 60);
      }
      let body = { name: name };
      if (currentFolderInfo?.id) {
        body.parentId = currentFolderInfo?.id;
      }
      handleStartUpload(file, body, user, 'file', null, onUploadSuccess, onUploadError);
    }
  };

  const onDialog = (event) => {
    setDialog({
      title: `Some of your uploaded files might be spatial`,
      content: (
        <SpatialFileComponent
          cancel={() => {
            setDialog(null);
            handleOpenNewProjectDialog();
          }}
          confirm={() => {
            setDialog(null);
            onUpload(event);
          }}
        />
      ),
    });
  };

  return (
    <>
      <span>
        <CombinedMenuItem
          icon={<NoteAdd color="inherit" />}
          label="New File"
          component={'label'}
          disabled={disabled}
          ref={ref}
        >
          <input hidden multiple type="file" onChange={onSelectFile} />
        </CombinedMenuItem>
      </span>
    </>
  );
});

NewFile.displayName = 'NewFile';

export default NewFile;
