import React, { createContext, useContext, useState, useMemo } from 'react';

import DialogControl from './DialogControl';

const dialogContextState = createContext();
const dialogSetterContext = createContext();

const DialogProvider = ({ children }) => {
  const [dialogOpts, setDialogOpts] = useState({});

  const memoizedDialogOpts = useMemo(() => dialogOpts, [dialogOpts]);
  const memoizedSetDialogOpts = useMemo(() => setDialogOpts, [setDialogOpts]);

  return (
    <dialogContextState.Provider value={memoizedDialogOpts}>
      <dialogSetterContext.Provider value={memoizedSetDialogOpts}>
        {children}
        <DialogControl {...dialogOpts} />
      </dialogSetterContext.Provider>
    </dialogContextState.Provider>
  );
};

function useDialogState() {
  const context = useContext(dialogContextState);
  if (context === undefined) {
    throw new Error('useUser must be used within a DialogProvider');
  }
  return context;
}

function useDialogSetter() {
  const context = useContext(dialogSetterContext);
  if (context === undefined) {
    throw new Error('useDialogSetter must be used within a DialogProvider');
  }
  return context;
}

function withDialogState(Component) {
  return function WrapperComponent(props) {
    const dialogOpts = useDialogState();
    return <Component {...props} dialogOpts={dialogOpts} />;
  };
}

function withDialogSetter(Component) {
  return function WrapperComponent(props) {
    const setDialogOpts = useDialogSetter();
    return <Component {...props} setDialogOpts={setDialogOpts} />;
  };
}

export { DialogProvider, useDialogState, useDialogSetter, withDialogState, withDialogSetter };
