import { Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import CompanyCardContent from './CompanyCardContent';
import IndividualCardContent from './IndividualCardContent';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  buttonActive: {
    boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
    width: '100px',
  },
  buttonInactive: {
    background: '#F2F2F2',
    color: '#828282',
    width: '100px',
  },
}));
const EditBillingInfo = ({ tab, countries, billingInformation, updateBillingInfo, setEdit, onCancel, setTab }) => {
  const { classes } = useStyles();
  return (
    <>
      <Box display={'flex'} alignItems={'center'} gap={2} mb={2}>
        <Typography variant="subtitle2" component="p">
          User Type:
        </Typography>
        <Stack direction="row" gap={1}>
          <Button
            className={tab === 0 ? classes.buttonActive : classes.buttonInactive}
            variant={tab === 0 ? 'outlined' : null}
            color="primary"
            onClick={() => setTab(0)}
          >
            Company
          </Button>
          {billingInformation?.isCompany ? (
            <Tooltip title="Can't switch back from Company to Individual">
              <span>
                <Button
                  className={tab === 1 ? classes.buttonActive : classes.buttonInactive}
                  disabled={billingInformation?.isCompany}
                  variant={tab === 1 ? 'outlined' : null}
                  color="primary"
                  onClick={() => setTab(1)}
                >
                  Individual
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Button
              className={tab === 1 ? classes.buttonActive : classes.buttonInactive}
              variant={tab === 1 ? 'outlined' : null}
              color="primary"
              onClick={() => setTab(1)}
            >
              Individual
            </Button>
          )}
        </Stack>
      </Box>
      {tab === 0 && (
        <CompanyCardContent
          countries={countries}
          billingInfo={billingInformation}
          setEdit={setEdit}
          updateBillingInfo={updateBillingInfo}
          onCancel={onCancel}
        />
      )}
      {tab === 1 && (
        <IndividualCardContent
          countries={countries}
          billingInfo={billingInformation}
          setEdit={setEdit}
          updateBillingInfo={updateBillingInfo}
          onCancel={onCancel}
        />
      )}
    </>
  );
};
export default EditBillingInfo;
