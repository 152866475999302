import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  form: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const CompanyCardContent = ({ countries, billingInfo, updateBillingInfo, onCancel, setEdit }) => {
  const { classes } = useStyles();

  const [error, setError] = useState(false);
  const [name, setName] = useState(billingInfo?.companyName);
  const [contact, setContact] = useState(billingInfo?.contactName);
  const [street, setStreet] = useState(billingInfo?.address?.street);
  const [number, setNumber] = useState(billingInfo?.address?.number);
  const [city, setCity] = useState(billingInfo?.address?.city);
  const [zip, setZip] = useState(billingInfo?.address?.zip);
  const [vat, setVat] = useState(billingInfo?.vatNumber);
  const [inputCountry, setInputCountry] = useState(billingInfo?.country);
  const [country, setCountry] = useState(billingInfo?.country);
  const [isEu, setIsEu] = useState(countries?.find((c) => c?.name === billingInfo?.country)?.isEu);

  let validFields =
    ((vat != null && vat !== '' && isEu) || !isEu) &&
    contact != null &&
    contact !== '' &&
    name != null &&
    name !== '' &&
    city != null &&
    city !== '' &&
    zip != null &&
    zip !== '' &&
    street != null &&
    street !== '' &&
    number != null &&
    number !== '' &&
    country != null &&
    country !== '' &&
    inputCountry != null &&
    inputCountry !== '';

  const onSubmit = () => {
    const body = {
      companyName: name,
      contactName: contact,
      country: country?.name?.name ?? country?.name ?? country,
      isCompany: true,
      address: { city: city, zip: zip, street: street, number: number },
      vatNumber: vat !== '' ? vat : null,
    };

    console.log(body);

    if (validFields) {
      setEdit(false);
      updateBillingInfo(body);
      setError(false);
    } else {
      setError(true);
    }
  };
  if (!Array.isArray(countries)) {
    return null;
  }
  return (
    <Box>
      <form className={classes.form}>
        <TextField
          label="Name"
          required
          variant="outlined"
          fullWidth
          error={contact === ''}
          defaultValue={billingInfo?.contactName}
          onChange={(e) => {
            setContact(e.target.value);
          }}
        ></TextField>
        <TextField
          label="Company name"
          required
          variant="outlined"
          fullWidth
          error={name === ''}
          defaultValue={billingInfo?.companyName}
          onChange={(e) => setName(e.target.value)}
        ></TextField>
        <TextField
          label="Street"
          required
          variant="outlined"
          fullWidth
          error={street === ''}
          defaultValue={billingInfo?.address?.street}
          onChange={(e) => setStreet(e.target.value)}
        ></TextField>
        <Box display={'flex'} justifyContent="space-between">
          <TextField
            label="Number"
            defaultValue={billingInfo?.address?.number}
            required
            error={number === ''}
            variant="outlined"
            style={{ marginRight: '15px' }}
            fullWidth
            onChange={(e) => setNumber(e.target.value)}
          ></TextField>
          <TextField
            label="City"
            defaultValue={billingInfo?.address?.city}
            required
            error={city === ''}
            variant="outlined"
            fullWidth
            onChange={(e) => setCity(e.target.value)}
          ></TextField>
        </Box>
        <TextField
          label="Zip code"
          defaultValue={billingInfo?.address?.zip}
          required
          error={zip === ''}
          variant="outlined"
          fullWidth
          onChange={(e) => setZip(e.target.value)}
        ></TextField>
        <Autocomplete
          defaultValue={{ name: billingInfo?.country }}
          onInputChange={(_, newValue) => {
            setIsEu(countries?.find((c) => c.name === newValue)?.isEu);
            setInputCountry(newValue);
          }}
          onChange={(_, newValue) => newValue && setCountry({ name: newValue })}
          options={countries || []}
          getOptionLabel={(option) => option.name || ''}
          style={{ width: 'auto' }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              label="Country"
              variant="outlined"
              required
              error={country === '' || inputCountry === ''}
            />
          )}
        />
        <TextField
          label="Vat number"
          onChange={(e) => setVat(e.target.value)}
          defaultValue={billingInfo?.vatNumber}
          required={isEu}
          variant="outlined"
          error={vat === '' && isEu}
          fullWidth
        ></TextField>
        {error && (
          <Box py={1}>
            <Typography color="error">Some fields are missing or invalid</Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="row-reverse" mt={2}>
          <Button disabled={!validFields} variant={'contained'} color="primary" onClick={onSubmit}>
            Submit
          </Button>
          <Box px={1} />
          {billingInfo != null && (
            <Button variant={'outlined'} color="primary" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default CompanyCardContent;
