import React from 'react';
import { createMakeAndWithStyles } from 'tss-react';
import { useTheme } from '@mui/material/styles';

const { useStyles: useEmptyStyles } = createMakeAndWithStyles({ useTheme });

export function withEmptyStyles(Component) {
  return function EmptyStyles(props) {
    const emptyStyles = useEmptyStyles();
    return <Component {...props} {...emptyStyles} />;
  };
}

export default useEmptyStyles;
