import React, { useState } from 'react';

import { Button, DialogActions, DialogContent, InputAdornment, TextField } from '@mui/material';

import { handleRename } from 'Drive/itemUtils';

import { useMinimalAuth } from 'hooks';
import { useMainContext } from 'ReusableComponents';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useDriveContext } from 'Drive/DriveContext';
import { useContextMenu } from '../../../ContextMenuContext';

import { parseExtension } from 'Drive/FileItem/FilesUtils';

import ApiManager from 'ApiManager';

const MAX_CHARACTERS = 64;

const errorText = { empty: 'Name may not be empty', long: 'Name too long' };

const Rename = ({ closeDialog }) => {
  const user = useMinimalAuth();
  const { onOpenSnackbar } = useMainContext();
  const { currentFolderInfo, updatePath } = useCurrentFolder();
  const { handleOptimisticUpdate } = useDriveContext();

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const { fileName = path?.name, fileExtension = '' } =
    path?.type === 'file' ? parseExtension(path?.name) : { fileName: path?.name, fileExtension: '' };

  //const fileExtension = path?.type === 'file' ? '.' + path?.name.split('.')[path?.name.split('.')?.length - 1] : '';

  const [name, setName] = useState(fileName);
  const [error, setError] = useState(null);

  const handleInput = (e, value) => {
    if (!!error) {
      setError(null);
    }
    if (e?.target?.value?.length === 0) {
      setError(errorText.empty);
    }
    if (e?.target?.value?.length + fileExtension?.length > MAX_CHARACTERS) {
      setError(errorText.long);
    }

    setName(!e.target.value ? '' : e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    closeDialog();

    let trimmedName = (name + '').trim();
    if (path.type === 'file' && fileExtension) {
      trimmedName = trimmedName + fileExtension;
    }

    if (trimmedName === '') {
      onOpenSnackbar({ level: 'error', content: errorText.empty });
    }
    console.log('name', trimmedName);

    if (currentFolderInfo && currentFolderInfo.id === path?.id) {
      ApiManager.put(`/v3/path/${path?.id}/name`, { name: trimmedName }, user)
        .then((r) => {
          updatePath(path?.id, { name: trimmedName });
        })
        .catch((e) => {
          onOpenSnackbar({ level: 'error', content: e.message });
        });
    } else {
      handleRename({
        trimmedName,
        path,
        onOpenSnackbar,
        handleOptimisticUpdate,
        user,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <TextField
          variant="outlined"
          autoComplete="rename map-name"
          autoFocus
          id="name"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={handleInput}
          error={!!error}
          helperText={`${name?.length + fileExtension?.length ?? 0}/${MAX_CHARACTERS}${
            typeof error === 'string' ? ' - ' + error : ''
          }`}
          InputProps={{
            endAdornment:
              fileExtension?.length > 0 ? <InputAdornment position="end">{fileExtension}</InputAdornment> : undefined,
          }}
          InputLabelProps={{ shrink: !!name }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color={'inherit'}>
          Cancel
        </Button>
        <Button type="submit" color="primary" disabled={!!error || path?.name?.trim() === name?.trim()}>
          Rename
        </Button>
      </DialogActions>
    </form>
  );
};

export default Rename;
