import React from 'react';
import { Button, Checkbox, FormControlLabel, Slider, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import PropTypes from 'prop-types';
import { InfoOutlined, LinearScale, Info } from '@mui/icons-material';

import { isValidTimestamp } from 'ReusableComponents/ValidLayer/ValidLayer';
import './index.css';
import { StyledAccordion } from 'ReusableComponents';
import { PaneContentProvider } from 'Viewer/DetailsPane/PaneContentContext';
import { useTheme } from '@mui/system';

const CustomLabel = ({ labelText, subLabel, icon, iconColor }) => (
  <span className="custom-label-wrapper">
    <span className="custom-label-main">{labelText}</span>
    {subLabel && (
      <span className="custom-label-sub">
        <span className="custom-label-icon" style={{ backgroundColor: iconColor }}>
          {icon}
        </span>
        <span>{subLabel}</span>
      </span>
    )}
  </span>
);

CustomLabel.propTypes = {
  labelText: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  icon: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
};

const CustomCheckBox = ({ labelText, subLabel, icon, iconColor, isChecked, valueId, setChecked }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          value={valueId}
          checked={!!isChecked}
          color="primary"
          onChange={() => {
            setChecked(valueId);
          }}
        />
      }
      label={<CustomLabel labelText={labelText} subLabel={subLabel} icon={icon} iconColor={iconColor} />}
    />
  );
};

CustomCheckBox.propTypes = {
  labelText: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  icon: PropTypes.node.isRequired,
  iconColor: PropTypes.string,
};

const MapControls = ({ timestampIndex, updateTimestamp, selectedStyleId, setChecked, map }) => {
  const theme = useTheme();
  const convertDate = (dateString) => dateString.replace('T', ' ').substring(0, 16);
  const timestamps = map.timestamps?.filter((t) => isValidTimestamp(t).available);

  return (
    <div className="controls-raster">
      <PaneContentProvider>
        {timestamps.length === 0 && (
          <ListItem>
            <ListItemIcon>
              <Info />
            </ListItemIcon>
            <ListItemText>No active timestamps to visualise</ListItemText>
          </ListItem>
        )}
        {timestamps.length > 0 && (
          <StyledAccordion title={'Timestamps'} startIcon={<LinearScale />} defaultExpanded={false}>
            <div className="accordion-data" color={theme.palette.common.gray1}>
              <div className="accordion-data__selected">Currently selected</div>
              <div className="accordion-data__date">
                {convertDate(timestamps[timestampIndex].dateFrom)} - {convertDate(timestamps[timestampIndex].dateTo)}
              </div>
            </div>
            {timestamps.length > 1 && (
              <Slider value={timestampIndex} onChange={updateTimestamp} min={0} max={timestamps.length - 1} />
            )}
          </StyledAccordion>
        )}
      </PaneContentProvider>
    </div>
  );
};

const ButtonControlsWrapper = ({ setChosenTiles, chosenTiles, mapType, setMaxZoom, maxZoom }) => {
  return (
    <div className="button-controls">
      <Button
        disabled={chosenTiles?.length === 0}
        className="control-button-wrapper"
        onClick={setChosenTiles}
        variant="outlined"
        color="primary"
      >
        Clear all tiles
      </Button>
      {mapType === 'map' ? (
        <Button className="control-button-wrapper" variant="contained" color="secondary" onClick={setMaxZoom}>
          {maxZoom ? 'reset' : 'set as max zoom'}
        </Button>
      ) : null}
    </div>
  );
};

const UserInfoBox = ({ maxZoom, currentZoom }) => {
  const theme = useTheme();

  let text = '';
  let isWarn = false;
  if (maxZoom && currentZoom > maxZoom) {
    text = 'User will not be able to zoom this high';
    isWarn = true;
  } else {
    text = maxZoom ? 'User will be able to zoom this high' : '';
  }
  const backgroundColor = isWarn ? theme.palette.error.main : theme.palette.info.dark;
  return (
    text && (
      <div className="info-box" style={{ backgroundColor }}>
        <InfoOutlined className="info-circle" /> {text}
      </div>
    )
  );
};

export { MapControls, ButtonControlsWrapper, UserInfoBox };
