import React, { useCallback, useState } from 'react';

import { Button, Collapse, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { ArrowDropDown, Crop } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { MapWrapper } from '../Helpers';

const ACTIVE = 0.3;
const HOVER = 0.3;

const useStyles = makeStyles({ name: 'Bounds' })((theme, _params, classes) => ({
  root: {
    overflow: 'hidden',
    padding: 0,
    border: `1px solid rgba(0,0,0,${ACTIVE})`,
    borderRadius: theme.spacing(1),
    '&:hover': { borderColor: `rgba(0,0,0,${ACTIVE + HOVER})` },
    [`&.${classes.open}`]: { borderColor: theme.palette.action.main },
  },
  button: {
    padding: theme.spacing(1),
    height: 48,
    color: `rgba(0,0,0,${ACTIVE + HOVER})`,
    '&:hover': { color: `rgba(0,0,0,${ACTIVE + HOVER * 1.5})` },
    [`& .${classes.text}`]: { lineHeight: 1, '&>span': { textTransform: 'capitalize' } },
  },
  listItem: {
    [`&.${classes.text}`]: { paddingLeft: theme.spacing(1) },
  },
  text: {},
  fullWidth: { width: '100%' },
  collapseIcon: {
    transition: theme.transitions.create('transform'),
    [`&.${classes.open}`]: { transform: 'rotate(180deg)' },
  },
  open: {},
}));

const Bounds = ({ input: { label }, searchVal, setSearchVal, initialValue }) => {
  const { classes: styles, cx } = useStyles();
  const [open, setOpen] = useState(!!searchVal);
  const [active, setActive] = useState(false);

  const handleChange = useCallback(
    (newValue) => {
      setSearchVal(newValue);
      setActive(false);
    },
    [setSearchVal]
  );

  return (
    <List className={cx(styles.root, styles.fullWidth, open && styles.open)}>
      <ListItemButton className={cx(styles.button, styles.fullWidth)} onClick={() => setOpen((old) => !old)}>
        <ListItemText className={styles.text} primary={label} />
        <ArrowDropDown className={cx(styles.collapseIcon, open && styles.open)} />
      </ListItemButton>
      <Collapse in={open}>
        <ListItem
          secondaryAction={
            <IconButton color="primary" edge="end" onClick={() => setActive(true)}>
              <Crop fontSize="inherit" />
            </IconButton>
          }
          className={cx(styles.listItem, styles.text)}
        >
          <ListItemText
            primary="Select your area of interest on the map below by drawing a shape."
            primaryTypographyProps={{ align: 'left' }}
          />
        </ListItem>
        <MapWrapper searchVal={searchVal} handleChange={handleChange} active={active} />
        <ListItem>
          <Button onClick={() => setSearchVal(initialValue)}>Clear</Button>
        </ListItem>
      </Collapse>
    </List>
  );
};

export default Bounds;
