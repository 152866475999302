import ApiManager from '../ApiManager';

/**
 * Open a global snackbar to display feedback messages.
 *
 * @callback onOpenSnackbar
 * @param {Object} message - the message to display
 * @param {string} employee.content - the content of the message
 * @param {string} employee.level - the type of message (success or error)
 */

/**
 * Update the current map at the top level of the application
 *
 * @callback onSelectMap
 * @param {Object} mapId - the map id of the map to fetch the new information from
 */

/**
 * Submit to backend API, for the `/settings` route
 * @param {string} path - the path of the url to call (`/settings/${path}`)
 * @param {object} body - the body of the post request
 * @param {object} user - the user used for auth. Must contain a `token` field
 * @param {string} [successMsg] - the message to display in case of success
 * @param {onOpenSnackbar} [onOpenSnackbar] - global callback to display the feedback messages in a snackbar
 * @param {onSelectMap} [onSelectMap] - global callback to update the global map on success
 * @returns {object} the API response
 */
const submitSettingsToAPI = (method, path, body, user, successMsg, onOpenSnackbar, onSelectMap) => {
  return ApiManager.fetch(method, path, body, user)
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }
      return res;
    })
    .catch((error) => {
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
      return error;
    });
};

const submitToAPI = (method, path, body, user, successMsg, onOpenSnackbar, onSelectMap) => {
  return ApiManager.fetch(method, path, body, user)
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }
      return res;
    })
    .catch((error) => {
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
    });
};

const parseValue = (properties, propertyName, value) => {
  const type = properties.reduce((acc, curr) => (curr.name === propertyName ? curr.type : acc), properties[0]?.type);
  switch (type) {
    case 'integer':
      return parseInt(value);
    case 'float':
      return parseFloat(value);
    default:
      return value;
  }
};

const getSimilarProp = (properties, pasteProperties, name) => {
  let oldProp = pasteProperties.find((x) => x.name === name);

  let newProp = properties.find((x) => !x.deleted && x.name === oldProp.name);

  if (!newProp) {
    newProp = properties.find((x) => !x.deleted && x.type === oldProp.type);
  }

  if (!newProp) {
    newProp = properties.find((x) => (x.type === 'float' || x.type === 'int') && !x.deleted);
  }

  if (!newProp) {
    newProp = properties.find((x) => x.type === 'date' && !x.deleted);
  }
  if (!newProp) {
    newProp = properties.find((x) => !x.deleted);
  }

  return newProp;
};

const uploadToAPI = (type, body, user, file, events) => {
  if (type === 'PATH_CREATION') {
    return ApiManager.upload(`/v3/path/setUpTask`, file, user, events, body, 'data');
  } else if (type === 'file') {
    return ApiManager.upload(`/v3/path/file`, file, user, events, body, 'data');
  } else {
    return ApiManager.upload(
      `/v3/path/${body.mapId}/${
        type === 'raster' ? 'raster' : type === 'pointCloud' ? 'pointCloud' : 'vector'
      }/timestamp/${body.timestampId}/file`,
      file,
      user,
      events,
      body
    );
  }
};

/**
 * @param {string} type - the type of upload you want to get (`geometry` or `raster`)
 * @param {object} body - the body of the post request
 * @param {object} user - the user used for auth. Must contain a `token` field
 * @returns {array} an array of uploads
 */

const extractDataFromUploads = (uploads) => {
  return uploads.map((upload) => ({
    sourceLayer: upload.sourceLayer,
    progressStatus: typeof upload.progressStatus === 'number' ? 'progress' : upload.progressStatus,
    id: upload.id,
    filename: upload.name,
  }));
};

const getAccessNameFromAccessLevel = (level) => {
  if (level === 0) return 'No access';
  if (level <= 300) return 'View';
  if (level <= 599) return 'Edit';
  if (level <= 900) return 'Maintain';
  else return 'Admin';
};

export {
  submitSettingsToAPI,
  uploadToAPI,
  extractDataFromUploads,
  getAccessNameFromAccessLevel,
  submitToAPI,
  parseValue,
  getSimilarProp,
};
