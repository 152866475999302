import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  Menu,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';

import { makeStyles } from 'tss-react/mui';

import ActionsPerMinuteComponent from './ActionsPerMinuteComponent';

import { MINIMUM, TRANCHES } from './ActionsPerMinuteUtils';

const SLIDER_WIDTH = 4;

const useStyles = makeStyles({ name: 'ActionsPerMinuteMenu' })((theme) => ({
  menu: { padding: theme.spacing(1) },
  subheader: { lineHeight: theme.spacing(4) },
  sliderContainer: {
    minWidth: 210,
    padding: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  slider: {
    '&.MuiSlider-root.MuiSlider-vertical': {
      width: SLIDER_WIDTH,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: '100%',
      position: 'relative',
      boxSizing: 'border-box',
      '& .MuiSlider-mark': { display: 'none' },
      '&>.MuiSlider-track': { border: 'none', transform: 'rotate(180deg)', left: SLIDER_WIDTH / 2 },

      '&>.MuiSlider-thumb, &>.MuiSlider-mark, &>.MuiSlider-rail': { left: SLIDER_WIDTH },
    },

    '& .MuiSlider-markLabel': { left: theme.spacing(4), right: 0 },
    '& .MuiSlider-markLabel .AccessLevelComponent': { opacity: '50%' },
    '& .MuiSlider-markLabelActive.MuiSlider-markLabel .AccessLevelComponent': { opacity: '100%' },
  },
}));

const ActionsPerMinuteMenu = ({ setMenuOptions, menuOptions, actionsPerMinute: actionsInitial }) => {
  const [sliderValue, setSliderValue] = useState(-1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [error, setError] = useState(null);
  const [customAmount, setCustomAmount] = useState(actionsInitial);

  const { targetEl, actionsPerMinute, onChange, minUserActionsMonth } = { ...menuOptions };
  const { classes: styles } = useStyles();

  const disabled = sliderValue === -2;

  const marks = useMemo(
    () =>
      [...TRANCHES].reverse().map((x, i) => ({
        value: i,
        label: <LabelItem actionsPerMinuteObj={x} disabled={x?.ApM < minUserActionsMonth} />,
        actions: x.ApM,
        type: x.type,
      })),
    [minUserActionsMonth]
  );

  const onClose = (e) => {
    e?.stopPropagation();

    setSliderValue(-1);
    setMenuOptions('reset');
  };

  const setInitValue = useCallback(() => {
    setSliderValue(marks.find((x) => x.actions === actionsPerMinute)?.value ?? 0);
  }, [marks, actionsPerMinute]);

  useEffect(() => {
    setInitValue();
  }, [setInitValue]);

  const handleSlideCommit = (e, value) => {
    e?.stopPropagation();

    let apm = marks[value].actions;
    if (apm && minUserActionsMonth && apm < minUserActionsMonth) {
      return;
    }

    const target = marks[value];

    if (target?.type === 'custom') {
      setSliderValue(value);
      setDialogOpen(true);
    } else {
      setSliderValue(value);
      onChange(target?.actions);
      onClose();
    }
  };

  const handleTextFieldChange = (e) => {
    e?.stopPropagation();

    let value = Number(e.target.value);
    setCustomAmount(value);
  };

  const closeDialog = (e) => {
    e?.stopPropagation();

    setDialogOpen(false);
  };

  const confirmDialog = (e) => {
    e?.stopPropagation();

    setDialogOpen(false);
    onChange(customAmount);
    onClose();
  };

  const handleSlide = (e, value) => {
    e?.stopPropagation();

    let apm = marks[value].actions;
    if (apm && minUserActionsMonth && apm < minUserActionsMonth) {
      return;
    }

    setSliderValue(value);
  };

  return (
    !!targetEl && (
      <React.Fragment>
        <Menu
          PaperProps={{ className: styles.menu }}
          open={!!targetEl}
          anchorEl={targetEl}
          onClose={onClose}
          onClick={(e) => e?.stopPropagation()}
        >
          <ListItem className={styles.sliderContainer} style={{ height: marks?.length ? 40 * marks.length : 250 }}>
            <Slider
              className={styles.slider}
              orientation="vertical"
              track="inverted"
              marks={marks}
              min={marks[0]?.value}
              max={marks[marks.length - 1]?.value}
              onChangeCommitted={handleSlideCommit}
              onChange={handleSlide}
              value={sliderValue}
              disabled={disabled}
            />
          </ListItem>
        </Menu>
        <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle>{"Custom Pu's per Month"}</DialogTitle>
          <DialogContent sx={{ overflowY: 'initial' }}>
            <TextField
              variant="outlined"
              autoFocus
              label="PU's"
              fullWidth
              type="number"
              inputProps={{
                min: Math.max(MINIMUM, minUserActionsMonth),
                step: 10000000,
              }}
              onChange={handleTextFieldChange}
              value={customAmount}
              error={!!error}
              helperText={error}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} color="inherit">
              Cancel
            </Button>
            <Button color="primary" onClick={confirmDialog}>
              Edit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  );
};

export default ActionsPerMinuteMenu;

const useLabelItemStyles = makeStyles({ name: 'LabelItem' })((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.common.gray1,
  },
  disabled: {
    opacity: '50%',
  },
}));

const LabelItem = ({ actionsPerMinuteObj, disabled }) => {
  const { classes: styles } = useLabelItemStyles();

  return (
    <div className={styles.root + ' ' + (disabled ? styles.disabled : '')}>
      <ActionsPerMinuteComponent
        actionsPerMinute={actionsPerMinuteObj?.ApM}
        disabled={disabled}
        suppressInherit
        labelProps={{ component: Typography, variant: 'button' }}
      />
      {actionsPerMinuteObj?.tooltip && (
        <Tooltip
          arrow
          title={
            <>
              <b>{actionsPerMinuteObj.tooltip}</b>
              {disabled && <br />}
              {disabled && 'Disabled by inherited Processing Units'}
            </>
          }
        >
          <InfoOutlined fontSize="small" className={styles.icon} />
        </Tooltip>
      )}
    </div>
  );
};
