import React, { useState } from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { AvatarWithInfo, LoadingButton } from '../../../ReusableComponents';

const useApplicationListItemStyles = makeStyles()((theme) => ({
  container: {
    padding: '16px',
    display: 'grid',
    gridTemplateColumns: 'min-content 1fr max-content',
    gridGap: '20px',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  title: {
    gridColumn: 2,
  },
  button: {
    gridColumn: 3,
  },
}));

const ApplicationListItem = ({ user, handleDelete }) => {
  const { classes } = useApplicationListItemStyles();

  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    await handleDelete();
  };

  return (
    <div className={classes.container}>
      {user && <AvatarWithInfo user={user} size={28} />}
      <Typography className={classes.title}>{user?.username}</Typography>
      <LoadingButton
        aria-label="delete user"
        loading={loading}
        className={classes.button}
        onClick={handleClick}
        variant="outlined"
        color="primary"
      >
        {user ? 'Revoke authorization' : 'Add new key'}
      </LoadingButton>
    </div>
  );
};

ApplicationListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  handleDelete: PropTypes.func.isRequired,
};
export default ApplicationListItem;
