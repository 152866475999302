import React from 'react';

import { Dialog } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';
import ManageSharingDialog from 'ReusableComponents/ManageSharingDialog';
import { useDriveContext } from 'Drive/DriveContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { MarkdownDialog } from 'ReusableComponents/MarkdownEditor';
import Constellation from 'Constellation/Constellation';
import Process from 'Process/Process';

const useStyles = makeStyles({ name: 'DriveMenuDialog' })((theme) => ({ root: { paddingTop: 64 } }));

const DriveMenuDialog = ({ dialog, setDialog, closeDialog }) => {
  const { classes } = useStyles();
  const { getPath } = useDriveContext();
  const { currentFolderInfo: currentFolder } = useCurrentFolder();

  const path =
    dialog?.type === 'sharing' &&
    (currentFolder?.id === dialog?.content?.id ? currentFolder : getPath(dialog?.content?.id));

  const DynamicComponent = dialog?.content;
  return (
    <>
      {/* Manage Sharing */}
      <ManageSharingDialog open={dialog?.type === 'sharing'} openFunc={setDialog} suppressContextError path={path} />

      {/* Description */}
      <MarkdownDialog
        open={dialog?.type === 'description'}
        close={closeDialog}
        title={dialog?.title}
        initialValue={dialog?.initialValue}
        cb={dialog?.action}
      />

      <Dialog open={dialog?.type === 'constellation'} fullScreen sx={{ p: { xs: 1, sm: 3 } }}>
        <DialogTitle color="primary" onClose={closeDialog}>
          Constellation
        </DialogTitle>
        <Constellation onClose={closeDialog} />
      </Dialog>

      <Dialog open={dialog?.type === 'process'} fullWidth>
        <DialogTitle onClose={closeDialog}>{dialog?.edit ? 'Edit process' : 'Create a new process'}</DialogTitle>
        <Process onClose={closeDialog} edit={dialog?.edit} path={dialog?.path} />
      </Dialog>

      {/* Other Cases */}
      <Dialog
        open={
          !!dialog &&
          dialog?.type !== 'sharing' &&
          dialog?.type !== 'description' &&
          dialog?.type !== 'constellation' &&
          dialog?.type !== 'process'
        }
        onClose={closeDialog}
        maxWidth="sm"
        fullWidth
        className={classes.root}
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle title={dialog?.title} close={closeDialog} />
        {dialog?.type === 'geodatabase' ||
        dialog?.type === 'geopackage' ||
        dialog?.type === 'shape' ||
        dialog?.type === 'nc' ? (
          <DynamicComponent
            type={dialog?.type}
            description={dialog.description}
            uploadFormats={dialog.uploadFormats}
            onClose={closeDialog}
          />
        ) : (
          dialog?.type !== 'sharing' && dialog?.content
        )}
      </Dialog>
    </>
  );
};
export default DriveMenuDialog;
