import React from 'react';

import { Box } from '@mui/material';
import ApiManager from 'ApiManager';
import useFileImage, { STATUSES } from 'ReusableComponents/FileIcon/useFileImage';

const SIZE = 24;
const REPEAT = 200;
const OFFSET = 2;

export const GET_GRADIENT = ({ type, color, width = 1, backgroundColor = 'transparent' }) =>
  type === undefined
    ? {}
    : type === 'checkerboard'
    ? {
        backgroundColor: type === 'solid' ? color : backgroundColor,
        backgroundImage: `repeating-linear-gradient(45deg, ${color} 25%, transparent 25%, transparent 75%, ${color} 75%, ${color}),
          repeating-linear-gradient(45deg, ${color} 25%, transparent 25%, transparent 75%, ${color} 75%, ${color})`,
        backgroundSize: `${Math.round(SIZE / (REPEAT / width) + OFFSET)}px ${Math.round(
          SIZE / (REPEAT / width) + OFFSET
        )}px`,
        backgroundPosition: `0 0, ${Math.round(SIZE / (REPEAT / width) + OFFSET) / 2}px ${
          Math.round(SIZE / (REPEAT / width) + OFFSET) / 2
        }px`,
        backgroundRepeat: 'repeat',
      }
    : {
        backgroundColor: type === 'solid' ? color : backgroundColor,
        backgroundImage: `repeating-linear-gradient(
          ${type === 'diagonal' ? '-45deg' : type === 'horizontal' ? '180deg' : type === 'vertical' ? '90deg' : ''},
          ${color} 0px,
          ${color} ${Math.round(SIZE / (REPEAT / width) + OFFSET)}px,
          ${backgroundColor} ${Math.round(SIZE / (REPEAT / width) + OFFSET)}px,
          ${backgroundColor} ${Math.round(SIZE / (REPEAT / width) + OFFSET) * 2}px,
          ${color} ${Math.round(SIZE / (REPEAT / width) + OFFSET) * 2 + 1}px
        )`,
      };

const PatternIcon = ({ type = 'solid', color, width, backgroundColor, borderRadius = 1, icon, pathId, sx = {} }) => {
  const isIcon = icon?.type !== 'drive' && icon?.name;

  const thumbnail = useFileImage(icon?.pathId ?? pathId);

  const hasThumbnail = !!thumbnail && !Object.values(STATUSES).includes(thumbnail);

  const params = new URLSearchParams({ color: color, alpha: 1, width: 1, ...icon }).toString();

  const url = ApiManager.apiUrl + '/v3/image/icon?' + params;

  return (
    <>
      {!!isIcon ? (
        <Box component={'img'} alt={icon?.name} src={url} height={SIZE} width={SIZE} sx={sx} />
      ) : (
        <Box
          {...(!!hasThumbnail ? { component: 'img', src: thumbnail } : {})}
          sx={(theme) => ({
            height: SIZE,
            width: SIZE,
            borderRadius,
            ...GET_GRADIENT({
              type: hasThumbnail ? undefined : type,
              color: color ?? theme.palette.action.active,
              backgroundColor: backgroundColor,
              width,
            }),
            ...sx,
          })}
        />
      )}
    </>
  );
};

export default PatternIcon;
