import VersionUtility from 'VersionUtility';
import ApiManager from '../ApiManager';

/**
 * Open a global snackbar to display feedback messages.
 *
 * @callback onOpenSnackbar
 * @param {Object} message - the message to display
 * @param {string} employee.content - the content of the message
 * @param {string} employee.level - the type of message (success or error)
 */

/**
 * Update the current map at the top level of the application
 *
 * @callback onSelectMap
 * @param {Object} mapId - the map id of the map to fetch the new information from
 */

/**
 * Submit to backend API, for the `/settings` route
 * @param {string} path - the path of the url to call (`/settings/${path}`)
 * @param {object} body - the body of the post request
 * @param {object} user - the user used for auth. Must contain a `token` field
 * @param {string} [successMsg] - the message to display in case of success
 * @param {onOpenSnackbar} [onOpenSnackbar] - global callback to display the feedback messages in a snackbar
 * @param {onSelectMap} [onSelectMap] - global callback to update the global map on success
 * @returns {object} the API response
 */
const submitSettingsToAPI = (method, path, body, user, successMsg, onOpenSnackbar, onSelectMap) => {
  return ApiManager.fetch(method, path, body, user)
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }
      return res;
    })
    .catch((error) => {
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
      return error;
    });
};

const submitToAPI = (method, path, body, user, successMsg, onOpenSnackbar, onSelectMap) => {
  return ApiManager.fetch(method, path, body, user)
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }
      return res;
    })
    .catch((error) => {
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
    });
};

const parseValue = (properties, propertyName, value) => {
  const type = properties.reduce((acc, curr) => (curr.name === propertyName ? curr.type : acc), properties[0]?.type);
  switch (type) {
    case 'integer':
      return parseInt(value);
    case 'float':
      return parseFloat(value);
    default:
      return value;
  }
};

const getSimilarProp = (properties, pasteProperties, name) => {
  let oldProp = pasteProperties.find((x) => x.name === name);

  let newProp = properties.find((x) => !x.deleted && x.name === oldProp.name);

  if (!newProp) {
    newProp = properties.find((x) => !x.deleted && x.type === oldProp.type);
  }

  if (!newProp) {
    newProp = properties.find((x) => (x.type === 'float' || x.type === 'int') && !x.deleted);
  }

  if (!newProp) {
    newProp = properties.find((x) => x.type === 'date' && !x.deleted);
  }
  if (!newProp) {
    newProp = properties.find((x) => !x.deleted);
  }

  return newProp;
};

/**
 * @param {string} type - the type of upload you want to get (`geometry` or `raster`)
 * @param {object} body - the body of the post request
 * @param {object} user - the user used for auth. Must contain a `token` field
 * @returns {array} an array of uploads
 */
const getUploads = async (type, body, user, all = false) => {
  let pages = [];
  try {
    const getPage = async (pageStart = null) => {
      let res;

      res = await ApiManager.get(
        `/v3/path/${body.mapId}/${type === 'raster' ? 'raster' : 'vector'}/${
          type === 'raster' ? 'timestamp' : 'layer'
        }/${type === 'raster' ? body.timestampId : body.layerId}/file`,
        { pageStart: pageStart },
        user
      );
      res = VersionUtility.convertUploads(res);
      return res;
    };

    if (all) {
      let cont = true;

      let pageStart;
      while (cont) {
        let page = await getPage(pageStart);

        pages = pages.concat(page.result);
        pageStart = page.nextPageStart;
        if (!pageStart) {
          cont = false;
        }
      }
    } else {
      pages = await getPage();
      pages = pages.result;
    }
  } catch (e) {
    console.log(e);
    pages = false;
  }

  return pages;
};

const deleteUpload = (body, user, successMsg, onOpenSnackbar, onSelectMap) => {
  let url;
  if (body.timestampId) {
    url = `/v3/path/${body.mapId}/raster/timestamp/${body.timestampId}/file/${body.uploadId}`;
  } else {
    url = `/v3/path/${body.mapId}/vector/timestamp/${body.layerId}/file/${body.uploadId}`;
  }
  return ApiManager.delete(url, null, user)
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }
      return res;
    })
    .catch((error) => {
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
    });
};

const revertUpload = (body, user, successMsg, onOpenSnackbar, onSelectMap) => {
  return ApiManager.post(
    `/v3/path/${body.mapId}/vector/timestamp/${body.layerId}/upload/${body.uploadId}/revert`,
    body,
    user
  )
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }
      return res;
    })
    .catch((error) => {
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
    });
};

const getGeometries = ({
  mapId,
  user,
  bounds = null,
  layerId,
  styleId = null,
  style = undefined,
  maxSize = 8,
  pageSize = 1000,
  returnType = 'geometry',
  deleted = false,
  history = null,
}) => {
  return ApiManager.get(
    `/v3/path/${mapId}/vector/${layerId}/featuresByBounds`,
    {
      bounds,
      pageSize,
      returnType,
      deleted,
      history,
      styleId,
      style,
    },
    user
  ).catch((err) => false);
};

const getGeometryCount = ({ mapId, user, layer, deleted, history, returnType }) => {
  return ApiManager.post(
    '/geometry/get',
    {
      mapId,
      layer,
      deleted,
      history,
      returnType,
      pageSize: 10000,
    },
    user
  ).catch((err) => false);
};

const deleteGeometry = ({ mapId, user, revert, geometryIds }) => {
  return ApiManager.post(
    '/geometry/delete',
    {
      mapId,
      revert,
      geometryIds,
    },
    user
  ).catch((err) => false);
};

const moveGeometry = ({ mapId, user, geometryIds, newLayer }) => {
  return ApiManager.post(
    '/geometry/delete',
    {
      mapId,
      geometryIds,
      newLayer,
    },
    user
  ).catch((err) => false);
};

/**
 * Add `transparency` to `color`
 * @param {string} color - the color to update (hex)
 * @param {number} transparency - the transparency to add, int between 0 and 100
 * @param {boolean} [includeHashtag=true] - wether to include a hastag in the returned string
 * @returns {string} the modified color (hex)
 */
const addTransparencyToColor = (color, transparency, includeHashtag = true) => {
  let newColor = color;
  if (newColor.slice(0, 1) === '#') {
    newColor = newColor.slice(1);
  }
  if (newColor.length > 6) {
    newColor = newColor.slice(0, 6);
  }
  const naiveTransparencyHex = Math.round(transparency * 2.55).toString(16);
  let transparencyHex = naiveTransparencyHex.length === 1 ? '0' + naiveTransparencyHex : naiveTransparencyHex;

  return `${includeHashtag ? '#' : ''}${newColor}${transparencyHex}`;
};

const extractDataFromUploads = (uploads) => {
  return uploads.map((upload) => ({
    sourceLayer: upload.sourceLayer,
    progressStatus: typeof upload.progressStatus === 'number' ? 'progress' : upload.progressStatus,
    id: upload.id,
    filename: upload.name,
  }));
};

const getAccessNameFromAccessLevel = (level) => {
  if (level === 0) return 'No access';
  if (level <= 300) return 'View';
  if (level <= 599) return 'Edit';
  if (level <= 900) return 'Maintain';
  else return 'Admin';
};

export {
  submitSettingsToAPI,
  getUploads,
  deleteUpload,
  revertUpload,
  getGeometries,
  getGeometryCount,
  deleteGeometry,
  moveGeometry,
  addTransparencyToColor,
  extractDataFromUploads,
  getAccessNameFromAccessLevel,
  submitToAPI,
  parseValue,
  getSimilarProp,
};
