import React, { useState, useEffect, useCallback } from 'react';

import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  ListItemText,
  Skeleton,
  Typography,
} from '@mui/material';
import { Folder, FolderRounded, InsertDriveFile } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useMinimalAuth } from 'hooks';

import SharingComponent from 'ReusableComponents/SharingComponent';

import VersionUtility from 'VersionUtility';
import ApiManager from 'ApiManager';
import { FileIcon } from 'ReusableComponents/FileIcon';

const useStyles = makeStyles()((theme, _, classes) => ({
  content: {
    gap: theme.spacing(1),
    display: 'grid',
    maxWidth: '100%',
    minWidth: 0,
    alignItems: 'center',
    alignContent: 'flex-start',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',

    '& $folderContainer+.MuiAlert-root': {
      marginTop: 12,
    },
    color: theme.palette.action.active,
  },
  subgrid: {
    display: 'grid',
    gridTemplateColumns: 'subgrid',
    gridColumn: '1 / -1',
  },
  folderButton: {
    textTransform: 'none',
    overflowWrap: 'anywhere',
    textAlign: 'left',
    justifyContent: 'flex-start',
    gap: theme.spacing(0.5),
  },
  folder: {
    boxShadow: 'unset',
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.primary.surface,
    border: '1px solid transparent',
    borderColor: theme.palette.primary.mainOpaque,

    '& .MuiButton-startIcon': { color: theme.palette.action.active },
    '& .MuiTypography-root': { color: theme.palette.text.primary },
    '&:hover': {
      backgroundColor: theme.palette.primary.surface,
      boxShadow: 'unset',
      borderColor: theme.palette.primary.main,
    },
  },
  file: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    '&>.MuiBox-root': { height: 'calc(100% + 5px)', width: 'calc(100% + 30px)' },
  },
  media: {
    height: 0,
    position: 'relative',
    flexGrow: 1,
    paddingTop: 'calc(56.25% + 30px)',
    marginTop: -5,
    [`&&>.${classes.container}`]: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
  },
  buttonText: { color: theme.palette.text.primary },
  breadcrumbContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 3),
    paddingTop: 0,
  },
  breadcrumbIcon: {
    color: theme.palette.action.active,
    marginRight: '1rem',
    marginTop: 6,
  },
  emptyText: {
    color: theme.palette.text.secondary,
    padding: '16px',
    gridColumn: '1 / -1',
  },
  container: {},
}));

const rootNameMapping = {
  myMaps: 'My Drive',
  shared: 'Shared with me',
  sharedWithMe: 'Shared with me',
  favorites: 'Favorites',
};

const MoveTo = ({
  handleMove,
  setCurrentPathId,
  closeDialog,
  selectedItems,
  type,
  buttonText,
  fileTypes,
  blockSubmit,
  bookmark,
  setDisabled,
  handleSelect,
  currentRoot,
  loadingSubmit,
  hideAccept,
  fileSubType,
  fileTest,
  currentAccess,
}) => {
  const { classes } = useStyles();
  const user = useMinimalAuth();

  const [folders, setFolders] = useState(null);
  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [root, setRoot] = useState(currentRoot);

  useEffect(() => {
    if (bookmark) {
      setCurrentPathId(root.path.length > 0 ? root.path[root.path.length - 1].id : null);
    }
  }, [root, bookmark, setCurrentPathId]);

  const [selectedPath, setSelectedPath] = useState(null);

  const fetchFiles = useCallback(
    (pathId = null, nextPageStart = null) => {
      ApiManager.get(
        pathId
          ? `/v3/path/${pathId}/list`
          : `/v3/account/root/${ApiManager.rootNames[root.root] ? ApiManager.rootNames[root.root] : root.root}`,
        {
          type: fileTypes,
          pageSize: 50,
          pageStart: nextPageStart,
        },
        user
      )
        .then((res) => {
          setFiles(
            res.result
              .map((f) => VersionUtility.convertPathInfo(f))
              .filter((f) => {
                return f.name.includes(fileSubType);
              })
          );
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);

          setFiles([]);
        });
    },
    [root, fileTypes, user, fileSubType]
  );

  const fetchFolders = useCallback(
    async (pathId = null, nextPageStart = null) => {
      if (!root.root && !pathId) {
        return;
      }
      try {
        if (pathId) {
          let res = VersionUtility.convertPathInfo(await ApiManager.get(`/v3/path/${pathId}`, null, user));
          setSelectedPath(res);
        } else {
          setSelectedPath(null);
        }

        setLoading(true);
        let res = await ApiManager.get(
          pathId
            ? `/v3/path/${pathId}/list`
            : `/v3/account/root/${ApiManager.rootNames[root.root] ? ApiManager.rootNames[root.root] : root.root}`,
          {
            type: ['folder'],
            pageSize: 50,
            pageStart: nextPageStart,
          },
          user
        );

        res = VersionUtility.convertListRoot(res);
        res.result = res.result?.filter((folder) => !folder?.deleted);
        setFolders((folders) => (!!nextPageStart ? [...folders, ...res.result] : res.result));

        if (!!res?.nextPageStart) {
          fetchFolders(pathId, res.nextPageStart);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setLoading(false);

        setFolders({ result: null, nextPageStart: null });
      }
    },
    [root, user]
  );

  const handleMoveFolder = (id, name) => {
    setRoot((prev) => {
      return { ...prev, path: [...prev.path, { id, name }] };
    });
  };

  const handleBreadCrumbClick = (index) => {
    if (index === root.path.length - 1) {
      return;
    }

    setRoot((prev) => {
      if (index || index === 0) {
        let x = [...prev.path];
        x = x.slice(0, index + 1);

        return { ...prev, path: x };
      } else {
        return { ...prev, path: [] };
      }
    });
  };

  useEffect(() => {
    fetchFolders(root.path[root.path.length - 1]?.id);
    if (fileTypes) {
      fetchFiles(root.path[root.path.length - 1]?.id);
    }
  }, [fetchFiles, fetchFolders, fileTypes, root.path]);

  const access = selectedPath ? selectedPath.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'] : false;

  const owner = selectedPath?.user;
  const originPath = currentRoot.path.length > 0 ? currentRoot.path[currentRoot.path.length - 1] : null;

  const moveOwner = selectedPath
    ? selectedPath.yourAccess.accessLevel >= ApiManager.newAccessLevel['admin'] &&
      currentAccess >= ApiManager.newAccessLevel['admin']
    : currentAccess >= ApiManager.newAccessLevel['admin'];

  const alert = fileSubType
    ? null
    : !root.root
    ? 'Cannot place an item here'
    : root.root === 'shared' && root.path?.length === 0
    ? 'You cannot place items in shared with me'
    : root.root === 'favorites' && root.path?.length === 0
    ? 'You cannot place items in favorites'
    : !access && root.path.length > 0
    ? 'No permission to place items here, you need to have at least edit+'
    : (selectedItems[0]?.user.id !== owner?.id || (!owner && selectedItems[0]?.user.id !== user.id)) &&
      type !== 'create' &&
      !moveOwner
    ? 'You need to have admin access to both folders, when moving an element to a folder with of a different owner'
    : root.length > 0 && root.path[root.path.length - 1].id === selectedItems[0]?.id && type !== 'create'
    ? 'Not allowed to move path to the same location'
    : type !== 'create' && (selectedPath?.id === originPath?.id || (!selectedPath && !originPath))
    ? 'Cannot move to the original folder'
    : null;

  useEffect(() => {
    if (bookmark) {
      if (alert) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [alert, bookmark]);

  const pathIds = selectedItems?.map((i) => i.id);

  return (
    <>
      <div className={classes.breadcrumbContainer}>
        <Folder className={classes.breadcrumbIcon} />
        <Breadcrumbs aria-label="folder breadcrumb">
          <Button color={!root ? 'primary' : 'inherit'} onClick={() => setRoot({ root: null, path: [] })}>
            Ellipsis
          </Button>

          {!!rootNameMapping?.[root?.root] && (
            <Button color={root.path.length === 0 ? 'primary' : 'inherit'} onClick={() => handleBreadCrumbClick(null)}>
              {rootNameMapping[root.root]}
            </Button>
          )}
          {root.path
            ? [...root.path]?.map(({ id, name }, i) => (
                <Button
                  color={i === root.path.length - 1 ? 'primary' : 'inherit'}
                  key={id}
                  className={classes.folderButton}
                  onClick={() => handleBreadCrumbClick(i)}
                >
                  {name}
                </Button>
              ))
            : null}
        </Breadcrumbs>
      </div>
      {alert && <Alert severity="warning">{alert}</Alert>}
      <DialogContent className={classes.content} dividers>
        {!loading ? (
          <>
            {!root.root ? (
              <>
                <CustomButton
                  onClick={() => {
                    setRoot({ root: 'myMaps', path: [] });
                  }}
                  type="folder"
                  label="My Drive"
                />
                <CustomButton
                  onClick={() => {
                    setRoot({ root: 'shared', path: [] });
                  }}
                  type="folder"
                  label="Shared with Me"
                />
              </>
            ) : folders?.filter((folder) => !pathIds.includes(folder.id))?.length > 0 ? (
              folders
                ?.filter((folder) => !pathIds.includes(folder.id))
                ?.map(({ id, name }, i) => (
                  <CustomButton key={id} onClick={() => handleMoveFolder(id, name)} type="folder" label={name} />
                ))
            ) : (
              <Typography className={classes.emptyText}>No folders</Typography>
            )}
            <Box className={classes.subgrid}>
              {!fileTypes ? null : !loading && files.length > 0 ? (
                files.map((f) => {
                  const disabled = typeof fileTest === 'function' && fileTest(f);

                  return (
                    <CustomButton
                      key={f.id}
                      type="file"
                      onClick={() => handleSelect(f)}
                      color="primary"
                      label={f.name}
                      secondary={
                        <SharingComponent map={f} disabled={!!disabled?.reason} style={{ fontSize: '0.7rem' }} />
                      }
                      disabled={disabled}
                      file={f}
                    />
                  );
                })
              ) : !loading && files.length === 0 ? (
                <Typography className={classes.emptyText}>
                  {fileSubType === '.png' ? 'There are no PNG`s in this folder' : 'There are no styles in this folder'}
                </Typography>
              ) : null}
            </Box>
          </>
        ) : (
          <SkeletonItems />
        )}
      </DialogContent>
      {!bookmark && (
        <DialogActions>
          {loadingSubmit ? (
            <CircularProgress />
          ) : (
            <React.Fragment>
              <Button onClick={closeDialog}>Cancel</Button>
              {!hideAccept && (
                <Button
                  onClick={() =>
                    handleMove(
                      selectedPath,
                      (owner && selectedItems[0]?.user.id !== owner.id) ||
                        (!owner && selectedItems[0]?.user.id !== user.id)
                    )
                  }
                  color="primary"
                  disabled={!!alert || blockSubmit}
                >
                  {buttonText ? buttonText : type === 'create' ? 'Create Layer' : 'Move here'}
                </Button>
              )}
            </React.Fragment>
          )}
        </DialogActions>
      )}
    </>
  );
};

export default MoveTo;

const CustomButton = (p = {}) => {
  const { children, label, secondary, disabled, type, file, ...props } = p;

  const { cx, classes } = useStyles();

  return (
    <Button
      variant={type === 'folder' ? 'contained' : 'outlined'}
      className={cx(classes.folderButton, type === 'file' && classes.file, type === 'folder' && classes.folder)}
      fullWidth
      disabled={!!disabled?.reason}
      startIcon={type === 'file' ? undefined : type === 'folder' ? <FolderRounded /> : <InsertDriveFile />}
      {...props}
    >
      {type === 'file' && (
        <Box className={classes.media}>
          <Box className={classes.container}>
            <FileIcon file={file} type="drive" />
          </Box>
        </Box>
      )}
      <ListItemText
        sx={type === 'file' ? { flexGrow: 0 } : {}}
        primary={label}
        secondary={secondary}
        primaryTypographyProps={{ /* sx: type === 'file' ? { fontSize: '90%' } : {} */ variant: 'caption' }}
      />
      {!!disabled?.reason && (
        <Typography variant="caption" color="error">
          {disabled?.reason}
        </Typography>
      )}
    </Button>
  );
};

const SkeletonItems = () => new Array(6).fill(null).map((_, i) => <Skeleton key={i} />);
