import React from 'react';

import { MultiSelectComponent } from '../Helpers';
import { Typography } from '@mui/material';
import { TypeIcon } from 'ReusableComponents';
import ApiManager from 'ApiManager';

const EndElement = ({ active = false }) => (
  <Typography
    display="block"
    component="span"
    sx={{ fontWeight: 500 }}
    variant="overline"
    color={active ? 'success.main' : 'text.secondary'}
  >
    Spatial Search
  </Typography>
);

const MIN_WIDTH = 51;

const TYPES = [
  {
    key: 'raster',
    label: 'Raster',
    spatialSearch: true,
    color: 'map',
    icon: <TypeIcon type="raster" />,
    secondary: <EndElement active />,
  },
  {
    key: 'vector',
    label: 'Vector',
    spatialSearch: true,
    color: 'shape',
    icon: <TypeIcon type="vector" />,
    secondary: <EndElement active />,
  },
  {
    key: 'pointCloud',
    label: 'PointCloud',
    spatialSearch: true,
    color: 'pointCloud',
    icon: <TypeIcon type="pointCloud" />,
    secondary: <EndElement active />,
  },
  {
    key: 'folder',
    label: 'Folders',
    spatialSearch: false,
    color: 'folder',
    icon: <TypeIcon type="folder" />,
    secondary: <EndElement />,
  },
  {
    key: 'file',
    label: 'Files',
    spatialSearch: false,
    color: 'file',
    icon: <TypeIcon type="file" />,
    secondary: <EndElement />,
  },
  {
    key: 'bookmark',
    label: 'bookmarks',
    spatialSearch: false,
    color: 'bookmark',
    icon: <TypeIcon type="bookmark" />,
    secondary: <EndElement />,
  },
  ...(ApiManager?.cloud === 'esa'
    ? [
        {
          key: 'process',
          label: 'process',
          spatialSearch: false,
          color: 'process',
          icon: <TypeIcon type="process" />,
          secondary: <EndElement />,
        },
      ]
    : []),
];

const Type = ({ searchVal, setSearchVal }) => {
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;

    setSearchVal(value);
  };

  return (
    <MultiSelectComponent
      value={searchVal || []}
      items={TYPES}
      handleChange={handleChange}
      minWidth={MIN_WIDTH}
      chipType="TypeChip"
    />
  );
};

export default Type;
