/* import React from 'react';
import { Typography, Paper } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, _params, classes) => ({
  section: {
    marginTop: '0.5rem',
    marginBottom: '1rem',
  },
  interpText: {
    color: theme.palette.text.secondary,
    fontSize: '1.1rem',
    marginBottom: '10px',
  },
  cardContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '20px',
  },
  card: {
    width: '300px',
    cursor: 'pointer',
    padding: '16px',
    boxSizing: 'border-box',
    borderWidth: '1px',
    [`&.${classes.selected}`]: {
      borderColor: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main}09`,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}04`,
    },
  },
  cardTitle: {
    fontSize: '1.3rem',
    lineHeight: '1.334',
  },
  cardSubtitle: {
    textAlign: 'left',
  },

  selected: {},
})); */

const FastUpload = ({ fastUpload, setFastUpload }) => {
  // const { classes, cx } = useStyles();
  return null;
  /* return (
    <section className={classes.section}>
      <Typography className={classes.interpText}>{`Upload method`}</Typography>
      <div className={classes.cardContainer}>
        <Paper
          variant="outlined"
          className={cx(classes.card, fastUpload && classes.selected)}
          onClick={() => setFastUpload(true)}
        >
          <Typography className={classes.cardTitle}>Fast upload</Typography>
          <Typography color="textSecondary" className={classes.cardSubtitle}>
            Recommended for files containing over 100.000 features. While data is being added layer will be temporarily
            disabled.
          </Typography>
        </Paper>
        <Paper
          variant="outlined"
          className={cx(classes.card, !fastUpload && classes.selected)}
          onClick={() => setFastUpload(false)}
        >
          <Typography className={classes.cardTitle}> Streaming upload </Typography>
          <Typography color="textSecondary" className={classes.cardSubtitle}>
            Recommended for files containing under 100.000 features. Layer will remain fully operational.
          </Typography>
        </Paper>
      </div>
    </section>
  ); */
};

export default FastUpload;
