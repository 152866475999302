import React from 'react';

import { Typography, LinearProgress, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr 200px',
    alignItems: 'center',
    padding: '16px',
    paddingLeft: '24px',
  },
  progressContainer: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    height: '25px',
  },
  progressTextContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
  legend: {
    fontSize: '0.9rem',
    fontWeight: '500',
  },
  progressRoot: {
    borderRadius: '2px',
  },
  progressBar: {
    borderRadius: '2px',
  },
  button: {
    textTransform: 'none',
    height: '25px',
    fontSize: '0.9rem',
    width: '100%',
  },
}));

const ProgressBarSubSection = ({
  progress,
  maxProgress,
  titleText,
  legendText,
  buttonText,
  onClick,
  style: userStyles = {},
}) => {
  const { classes } = useStyles();
  return (
    <section className={classes.section} style={userStyles}>
      <div className={classes.progressContainer}>
        <div className={classes.progressTextContainer}>
          <Typography color="textSecondary" className={classes.title}>
            {titleText}
          </Typography>
          <Typography color="textSecondary" className={classes.legend}>
            {legendText}
          </Typography>
        </div>
        <LinearProgress
          variant="determinate"
          value={(progress / maxProgress) * 100}
          classes={{ root: classes.progressRoot, bar: classes.progressBar }}
        />
      </div>
      <Button variant="outlined" color="primary" className={classes.button} onClick={onClick}>
        {buttonText}
      </Button>
    </section>
  );
};

export default ProgressBarSubSection;
