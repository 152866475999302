import React, { useCallback, useEffect, useState } from 'react';

import { CircularProgress, DialogContent, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { parseSize } from 'Storage/BalanceUtility';

import { getStorage } from 'Drive/itemUtils';
import { useMinimalAuth } from 'hooks';
import { useContextMenu } from '../../../ContextMenuContext';

const StorageSize = () => {
  const user = useMinimalAuth();
  const [totalStorage, setTotalStorage] = useState(null);
  const [loading, setLoading] = useState(true);

  const { menuOptions } = useContextMenu();
  const { path } = menuOptions;

  const sumStorage = useCallback(async () => {
    const isMap = path?.type !== 'folder';
    let storage = await getStorage(0, path, user, isMap, setTotalStorage);

    setTotalStorage(storage);
    setLoading(false);
  }, [setLoading, setTotalStorage, path, user]);

  useEffect(() => {
    sumStorage();
  }, [sumStorage]);

  return (
    <DialogContent>
      <ListItem>
        <ListItemIcon>{loading ? <CircularProgress /> : null}</ListItemIcon>
        <ListItemText>{totalStorage || totalStorage === 0 ? parseSize({ value: totalStorage }) : null}</ListItemText>
      </ListItem>
    </DialogContent>
  );
};

export default StorageSize;
