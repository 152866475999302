import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Button, Box, Grid, useMediaQuery, IconButton, Tooltip } from '@mui/material';
import { AddFeatureProperties } from './AddFeatureProperties';
import ApiManager from 'ApiManager';
import { useMinimalAuth } from 'hooks';
import { Edit } from '@mui/icons-material';

import { filterProperties } from 'Viewer/ViewerUtility';
import { useMainContext } from 'ReusableComponents/MainContext';
import EditDialog from 'Viewer/PropertyTable/EditDialog';

import { FilterList } from '@mui/icons-material';

// I reused here Daniel's code without any refactor because I had no time to refactor everything
export const AddFeaturePropertiesForm = ({
  hidePane,
  layer: selectedLayer,
  isEdit,
  selectedElement,
  setEditing,
  makeBackup,
  isExternal,
  isUpload,
  layerInfo,
  addGeometry,
  onStartDraw,
  isPresentationMode,
}) => {
  const [errors, setErrors] = useState({});
  const [alterLoading, setAfterLoading] = useState(false);
  // I assume this is mutated somewhereselectedLayer
  // const [propertyValues, setPropertyValues] = useState({});
  const [, setPropertyValues] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const { onOpenSnackbar } = useMainContext();
  const [properties, setProperties] = useState(
    filterProperties(
      selectedElement?.type === 'external' || selectedElement?.type === 'upload'
        ? Object.keys(selectedElement.feature.properties).map((key) => ({ name: key }))
        : !!selectedLayer?.vector.properties
        ? selectedLayer?.vector.properties
        : [],
      false,
      selectedElement?.type === 'layer' && selectedLayer
    )
  );

  const isMobile = useMediaQuery(`(max-width: 1000px)`);
  const user = useMinimalAuth();
  // for 3d viewer lines/polygons drawing
  let editing = selectedElement?.type === 'draw';

  const checkRequired = () => {
    let returnVal = false;
    let error = {};

    for (var i = 0; i < properties.length; i++) {
      let property = properties[i];
      if (property.required && selectedElement.feature.properties[property.name] === null) {
        error[property.name] = property.name + ' is required.';
        returnVal = true;
      }
    }

    if (returnVal) {
      onOpenSnackbar({
        level: 'error',
        content: error,
      });
    }
    return returnVal ? error : false;
  };

  const addCustomPolygon = () => {
    let propertiesObj = {};

    for (let i = 0; i < properties.length; i++) {
      if (selectedElement.feature.properties[properties[i].name] !== null) {
        propertiesObj[properties[i].name] = selectedElement.feature.properties[properties[i].name];
      }
    }

    if (selectedElement.feature.properties.color) {
      propertiesObj['color'] = selectedElement.feature.properties.color;
    }
    selectedElement.feature.properties = propertiesObj;

    let timestampId = layerInfo.timestampRange.timestamps[layerInfo.timestampRange.end].id;
    let mapId = selectedLayer.id;
    let feature = selectedElement.feature;
    feature.properties = copiedProperties;
    let body = {
      features: [feature],
    };

    ApiManager.post(`/v3/path/${mapId}/vector/timestamp/${timestampId}/feature`, body, user)
      .then((result) => {
        addGeometry(mapId, result[0], timestampId, null, false);
        onStartDraw('stop');

        setAfterLoading(false);
        setPropertyValues({});

        onOpenSnackbar({
          content: 'Geometry added',
          level: 'success',
        });
      })
      .catch((err) => {
        console.error(err);
        setAfterLoading(false);

        onOpenSnackbar({
          content: err.message,
          level: 'error',
        });
        // dunno why, just reused
        setAfterLoading(false);
        setPropertyValues({});
      });
  };

  const editCustomPolygon = () => {
    let element = { ...selectedElement };
    let body;
    let newProperties = { ...copiedProperties };
    delete newProperties.id;
    delete newProperties.userId;
    delete newProperties.radius;
    delete newProperties.attribution;
    delete newProperties.trashed;
    delete newProperties.x;
    delete newProperties.y;

    body = {
      changes: [
        {
          featureId: element.feature.properties.id,
          newProperties: newProperties,
          newGeometry: selectedElement.feature.geometry,
          newLevelsOfDetail: [],
        },
      ],
    };

    element.feature.properties = { ...element.feature.properties, ...newProperties };

    ApiManager.patch(`/v3/path/${element.mapId}/vector/timestamp/${element.timestampId}/feature`, body, user)
      .then((result) => {
        addGeometry(element.mapId, element.feature.properties.id, element.timestampId);
        setAfterLoading(false);

        setPropertyValues({});

        makeBackup(element);
        onStartDraw('stop');

        onOpenSnackbar({
          content: 'changes submitted',
          level: 'success',
        });
      })
      .catch((err) => {
        onOpenSnackbar({
          content: err.message,
          level: 'error',
        });
        setAfterLoading(false);
      });
  };

  // we should use some form handler here like formik or whatever
  // also have no idea why isMove is not used

  const onSubmit = (isMove) => {
    onStartDraw('stop');

    setAfterLoading(true);
    let error = checkRequired();

    if (!error) {
      if (!isEdit) {
        addCustomPolygon();
      } else {
        editCustomPolygon();
      }

      if (isMobile) {
        hidePane();
      }
    } else {
      setAfterLoading(false);
      setErrors(error);
    }
  };

  const [copiedProperties, setCopiedProperties] = useState({ ...selectedElement?.feature?.properties });

  const featureRef = useRef({
    id: selectedElement?.feature?.properties?.id,
    type: selectedElement?.type,
  });
  useEffect(() => {
    if (
      selectedElement?.feature?.properties?.id !== featureRef.current.id ||
      selectedElement?.type !== featureRef.current.type
    ) {
      setCopiedProperties(selectedElement?.feature?.properties);
      featureRef.current = {
        id: selectedElement?.feature?.properties?.id,
        type: selectedElement?.type,
      };
    }
  }, [selectedElement]);

  const onPropertyValueChange = useCallback(
    (value, property) => {
      let error = errors;
      if (error[property.name]) {
        delete error[property.name];
      }

      if (isNaN(value) && (property.type === 'integer' || property.type === 'float')) {
        error[property.name] = 'Not a valid number.';
        value = '';
      }

      setCopiedProperties((prev) => {
        let x = { ...prev };
        if (value !== null) {
          x[property.name] = value;
        } else {
          delete x[property.name];
        }

        return x;
      });
    },
    [errors]
  );

  if (!isEdit && !editing) {
    return null;
  }

  let notDisabled =
    (user && selectedElement?.accessLevel >= ApiManager.newAccessLevel['edit+']) ||
    (selectedElement && user && user.id === selectedElement?.feature?.properties?.userId);

  let disabled = !notDisabled;

  return (
    <React.Fragment>
      {selectedElement?.type === 'layer' && (
        <Box sx={{ textAlign: 'right' }}>
          <Tooltip title="Manage visibility">
            <span>
              <IconButton
                onClick={() => {
                  setEditOpen(true);
                }}
                variant="outlined"
                disabled={properties?.length <= 1}
              >
                <FilterList />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      )}
      {
        <AddFeatureProperties
          properties={properties}
          copiedProperties={selectedElement.type === 'version' ? selectedElement.feature.properties : copiedProperties}
          layerInfo={layerInfo}
          onPropertyValueChange={onPropertyValueChange}
          errors={errors}
          layerId={selectedLayer?.id}
          isEdit={isEdit}
          isUpload={isUpload}
          isExternal={isExternal}
          editing={editing}
        />
      }

      {/* Maybe it should be a single Button with different usages but I have reused available code */}

      {!isExternal && !isUpload && !isPresentationMode && selectedElement?.type !== 'deleted' && (
        <Grid container fullwidth="true" spacing={2} style={{ marginTop: 12 }}>
          {!!editing ? (
            !!isEdit ? (
              <React.Fragment>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    data-test-id="submitNewPropertyButton"
                    color="primary"
                    onClick={() => onSubmit(true)}
                    disabled={!selectedLayer}
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid item xs={6}>
                  <Button
                    color="inherit"
                    fullWidth
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    data-test-id="submitNewPropertyButton"
                    onClick={() => onSubmit(false)}
                    disabled={
                      alterLoading ||
                      !selectedElement ||
                      !selectedElement.feature ||
                      selectedElement.feature.isDrawingFinished === false ||
                      !selectedElement.feature.geometry ||
                      (!isEdit && !selectedLayer)
                    }
                    fullWidth
                  >
                    Submit
                  </Button>
                </Grid>
              </React.Fragment>
            )
          ) : (
            <Grid item xs={6}>
              <Button
                disabled={disabled || selectedElement.type === 'version' || !selectedElement?.feature?.geometry}
                color="primary"
                startIcon={<Edit />}
                variant="outlined"
                onClick={() => setEditing(true)}
                fullWidth
              >
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {selectedElement?.type === 'layer' && (
        <EditDialog
          open={editOpen}
          onClose={() => {
            setEditOpen(false);
          }}
          properties={properties}
          layer={selectedLayer}
          parsedProperties={properties.filter((p) => !p.hidden)}
          setProperties={setProperties}
          sortProperty={null}
        />
      )}
    </React.Fragment>
  );
};
