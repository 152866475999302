import React, { useState, useRef } from 'react';

import { Typography, Button, Dialog, DialogContent, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Check, Close, OpenInNew } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ProjectTypeIcon from 'ReusableComponents/ProjectTypeIcon';
import { ConfirmationDialog } from 'MapManagement/core';
import { useAuthFuncs } from 'Account/AuthContext';
import Pay from 'ReusableComponents/Pay';
import { useMainContext } from 'ReusableComponents';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { downloadFile } from 'Drive/itemUtils';
// import VersionUtility from 'VersionUtility';
import ApiManager from 'ApiManager';
import CustomAvatar from 'ReusableComponents/CustomAvatar';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

const useStyles = makeStyles()((theme) => ({
  container: {},
  projectTypeIcon: {
    height: '40px',
    width: '40px',
  },
  textContainer: {},
  title: {},
  legend: {},
  value: {},
  ownerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
  button: {},
  declineButton: {
    color: theme.palette.text.secondary,
  },
}));

const SectionMenuItemShare = ({
  projectType,
  title,
  sharedByUser,
  value,
  fee,
  user,
  pathId,
  handleClose,
  inviteId,
}) => {
  const { classes, cx } = useStyles({ type: 'invite' });
  const history = useHistory();
  const [paying, setPaying] = useState(false);

  const [inviteStatus, setInviteStatus] = useState('pending');
  const [dialog, setDialog] = useState({ open: false });
  const { fetchUserInfo } = useAuthFuncs();

  const { setViewerKey, setDriveContentKey } = useMainContext();

  const { handleAccept } = useCurrentFolder();

  const hassubmitted = useRef(false);
  const onSubmit = async (accept) => {
    if (hassubmitted.current) return;

    hassubmitted.current = true;

    const res = await ApiManager.post(`/v3/path/${pathId}/invite/${inviteId}/accept`, { accept }, user);

    if (!!res) {
      if (accept) {
        handleAccept();
        setViewerKey();
        setDriveContentKey();
        setInviteStatus(pathId);
        fetchUserInfo();
      } else {
        setInviteStatus('declined');
      }
    }
  };

  const handleButtonClick = () => {
    let redirectLocation;

    if (projectType === 'bookmark') {
      redirectLocation = `/view?pathId=${pathId}`;
    } else {
      redirectLocation = `/drive/shared?pathId=${pathId}`;
    }

    handleClose();

    const currentLocation = `${history.location.pathname}${history.location.search}`;
    if (redirectLocation && currentLocation !== redirectLocation) {
      history.push(redirectLocation);
    }
  };

  return (
    <>
      <ListItem
        className={classes.container}
        secondaryAction={
          <Typography variant="overline" sx={{ marginRight: -0.5 }}>
            {value}
          </Typography>
        }
      >
        <ListItemIcon>
          <ProjectTypeIcon type={projectType} className={classes.projectTypeIcon} />
        </ListItemIcon>
        <ListItemText
          primary={title}
          secondary={
            <>
              Invited by:
              <CustomAvatar sx={{ ml: 0.5 }} user={sharedByUser} size={15} />
              <Typography className={classes.legend} style={{ marginLeft: '3px' }}>
                {sharedByUser.username}
              </Typography>
            </>
          }
          primaryTypographyProps={{ noWrap: true }}
          secondaryTypographyProps={{ className: classes.ownerContainer }}
        />
      </ListItem>

      <div className={classes.buttonContainer}>
        {inviteStatus === 'pending' ? (
          <>
            <Button
              variant="text"
              className={cx(classes.declineButton, classes.button)}
              startIcon={<Close />}
              onClick={() => setDialog({ open: true, type: 'decline', amount: null })}
            >
              Decline
            </Button>
            <Button
              variant="text"
              color="primary"
              className={classes.button}
              onClick={value !== 'Free' ? () => setPaying(true) : () => onSubmit(true)}
              startIcon={<Check />}
            >
              Accept
            </Button>
          </>
        ) : inviteStatus === 'declined' ? (
          <Typography style={{ marginRight: '10px' }}>You declined the invite</Typography>
        ) : (
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={handleButtonClick}
            startIcon={<OpenInNew />}
          >
            {['folder', 'file', 'bookmark', 'process'].includes(projectType) ? `Open ${projectType}` : 'Open layer'}
          </Button>
        )}
      </div>
      {paying && (
        <Dialog open={true} maxWidth="md" className="deposit" onClose={() => setPaying(false)}>
          <DialogTitle>Choose payment method</DialogTitle>
          <DialogContent>
            <Pay
              onSuccess={() => {
                onSubmit(true);
                setPaying(false);
              }}
              pay={fee}
              onClose={() => {
                setPaying(false);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
      <ConfirmationDialog
        style={{ zIndex: 2001 }}
        open={dialog.open}
        handleClose={() => setDialog((prev) => ({ ...prev, open: false }))}
        title={
          dialog.type === 'accept'
            ? 'Are you sure you want to accept this invitation?'
            : 'Are you sure you want to decline this invitation?'
        }
        legend={dialog.type === 'accept' ? `You will be billed ${dialog.amount}.` : undefined}
        cancelText="Cancel"
        agreeText={dialog.type === 'accept' ? 'Pay now' : 'Decline invitation'}
        onClick={() => {
          setDialog({ open: false });

          onSubmit(dialog.type === 'accept');
        }}
      />
    </>
  );
};

SectionMenuItemShare.propTypes = {
  projectType: PropTypes.oneOf(['map', 'shape', 'folder']).isRequired,
  title: PropTypes.string.isRequired,
  pathId: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default SectionMenuItemShare;
