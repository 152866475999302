import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import AppUtility from '../AppUtility';
import TourSteps from './TourSteps';
import { useMinimalAuth } from '../Account/AuthContext';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useMainContext } from 'ReusableComponents';
import ApiManager from 'ApiManager';
import { CLOUDS } from 'ApiUrls';
import { useConsentValue } from 'ConsentContext';

const Demo = () => {
  const history = useHistory();
  const user = useMinimalAuth();
  const pathname = history.location.pathname;
  const { currentFolderInfo } = useCurrentFolder();
  const {
    onOpenSidebar,
    tourOpen,
    setTourOpen,
    tourFunctions,
    hideNavbar,
    handleOpenNewProjectDialog,
    handleCloseNewProjectDialog,
    touch,
  } = useMainContext();

  const [t, setT] = useState(false);

  const { essential } = useConsentValue();

  const enabled = useMemo(() => !!essential && !!tourOpen && !hideNavbar, [essential, hideNavbar, tourOpen]);

  const onCloseTour = useCallback(
    (done = false) => {
      setTourOpen(false, () => {
        if (tourOpen === 'demo' || tourOpen === 'sampleShare' || tourOpen === 'insureTech') {
          if (!user) {
            history.push('/register');
          } else {
            history.push('/drive/me');
          }
        }
      });
    },
    [history, setTourOpen, tourOpen, user]
  );

  const page = useMemo(() => {
    if (pathname === '/search') {
      return 'library';
    } else if (pathname.includes('/settings')) {
      return 'settings';
    } else if (pathname === '/view') {
      return 'view';
    } else if (pathname.includes('/drive/') && currentFolderInfo && currentFolderInfo.type !== 'folder') {
      return 'files';
    } else if (pathname.includes('/drive/')) {
      return 'drive';
    } else if (pathname.includes('notification=') || pathname.includes('login') || pathname.includes('register')) {
      return false;
    } else {
      return 'general';
    }
  }, [currentFolderInfo, pathname]);

  const steps = useMemo(() => {
    let steps =
      tourOpen === 'demo'
        ? AppUtility.demoSteps
        : tourOpen === 'sampleShare'
        ? AppUtility.sampleShare
        : tourOpen === 'insureTech'
        ? AppUtility.insureTech
        : AppUtility.tutorialSteps[page];

    if (!steps || !page) {
      return;
    }

    if (!user) {
      steps = steps.filter((s) => !s.skipStepNoUser);
    }
    if (user) {
      steps = steps.filter((s) => !s.skipStepUser);
    }
    if (!user || !user.commercial) {
      steps = steps.filter((s) => !s.skipStepNotCommercial);
    }
    if (currentFolderInfo) {
      steps = steps.filter((s) => !s.mapType || s.mapType === currentFolderInfo.type);
    }
    if (touch) {
      steps = steps.filter((s) => !s.skipStepMobile);
      steps = steps.map((x) => {
        let y = { ...x };
        y.intro = x.mobileIntro ? x.mobileIntro : x.intro;
        return y;
      });
    }
    let already = true;
    try {
      already = localStorage.getItem('tourAlreadyRan');
    } catch (e) {
      console.warn(e);
    }
    if (tourOpen === 'auto' && !already) {
      try {
        localStorage.setItem('tourAlreadyRan', true);
      } catch (e) {
        console.warn(e);
      }

      steps.unshift({
        element: '.tutorialHelp',
        title: 'Need Help?',
        intro: 'Ever feel lost? You can always get a quick tour of the current page by clicking help!',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
      });
    }

    return steps;
  }, [page, tourOpen, user, currentFolderInfo, touch]);

  const onBeforeDemoChange = useCallback(
    async (step) => {
      let url = steps?.[step]?.['url'];
      if (!!url && steps?.[step - 1]?.['url'] !== url) {
        history.replace(url);
      }

      if (steps[step]['sidebar']) {
        onOpenSidebar(true);
      } else {
        onOpenSidebar(false);
      }

      if (steps[step]['sidePane']) {
        tourFunctions['view']['openPane']();
      } else if (steps[step]['share']) {
        tourFunctions['share']?.['share'](true);
      } else if (steps[step]['layerDetails']) {
        tourFunctions['layerDetails']?.['layerDetails']();
        return;
      } else if (steps[step]['closeShare']) {
        tourFunctions['share']?.['share'](false);
      } else if (steps[step]['newMap']) {
        handleOpenNewProjectDialog();
      } else if (steps[step]['closeMap']) {
        handleCloseNewProjectDialog();
      }
    },
    [handleCloseNewProjectDialog, handleOpenNewProjectDialog, history, onOpenSidebar, steps, tourFunctions]
  );

  const onBeforeTourChange = useCallback(
    (step) => {
      let name = steps[step].title;
      if (steps[step]['sidebar']) {
        onOpenSidebar(true);
      } else {
        onOpenSidebar(false);
      }

      if (tourFunctions[page][name]) {
        tourFunctions[page][name]();
      }
    },
    [onOpenSidebar, page, steps, tourFunctions]
  );

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(history.location.search);
      const demoMode = queryParams.has('demoMode') && queryParams.get('demoMode');

      if (demoMode !== 'false' && !!essential && !!page) {
        if (!!demoMode && !tourOpen) {
          localStorage.setItem(page, true);

          if (demoMode === 'general') {
            setTourOpen('demo');
          } else if (demoMode === 'sampleShare' && ApiManager.cloud === CLOUDS.ellipsis) {
            setTourOpen('sampleShare');
          } else if (demoMode === 'insureTech' && ApiManager.cloud === CLOUDS.ellipsis) {
            setTourOpen('insureTech');
          } else if (demoMode) {
            setTourOpen('demo');
          }
        } else if (page !== 'general' && !tourOpen && !localStorage.getItem(page) && tourFunctions[page]) {
          localStorage.setItem(page, true);
          setTourOpen('auto');
        }
      }
    } catch (e) {
      console.warn(e);
    }
  }, [history, essential, page, setTourOpen, tourFunctions, tourOpen]);

  const hasRun = useRef(false);
  useEffect(() => {
    if (enabled && !hasRun.current) {
      hasRun.current = setTimeout(() => {
        const queryParams = new URLSearchParams(history.location.search);

        if (queryParams.has('demoMode')) {
          queryParams.delete('demoMode');
          history.replace({
            search: queryParams.toString(),
          });
        }

        setT(true);
      }, 500);
    }

    return () => {
      if (!!hasRun.current) {
        clearTimeout(hasRun.current);
      }
    };
  }, [enabled, history]);

  /* if (!enabled) {
    return null;
  } */

  return (
    <TourSteps
      enabled={enabled && t}
      tourOpen={tourOpen}
      steps={steps ?? []}
      onCloseTour={onCloseTour}
      onBeforeChange={
        tourOpen === 'demo' || tourOpen === 'sampleShare' || tourOpen === 'insureTech'
          ? onBeforeDemoChange
          : onBeforeTourChange
      }
    />
  );
};
export default Demo;
