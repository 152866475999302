import React, { memo } from 'react';

import { makeStyles } from 'tss-react/mui';
import { useDragLayer } from 'react-dnd';

import { ItemTypes } from '../ItemTypes';
import ItemsTemplate from './ItemsTemplate';

const useStyles = makeStyles({ name: 'ItemsDragLayer' })((theme, _params, classes) => ({
  root: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 3000,
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  field: {
    width: '200px',
    maxWidth: '200px',
    opacity: 1,
    [`&.${classes.dragging}`]: {
      opacity: '0.9',
    },
  },
  dragging: {},
}));

const getItemStyles = (currentOffset) => {
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const combineOffsets = (offset1, offset2) => {
  if (!offset1 || !offset2) return null;
  return { x: offset1.x + offset2.x, y: offset1.y + offset2.y };
};

const ItemsDragLayer = () => {
  const { classes } = useStyles();

  const { itemType, isDragging, item, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    currentOffset: combineOffsets(monitor.getDifferenceFromInitialOffset(), monitor.getInitialClientOffset()),
    isDragging: monitor.isDragging(),
  }));

  return (
    <div className={classes.root}>
      <DragPreview currentOffset={currentOffset} isDragging={isDragging} itemType={itemType} item={item} />
    </div>
  );
};

const DragPreview = memo(({ currentOffset, isDragging, itemType, item }) => {
  const { classes, cx } = useStyles();
  const renderItem = (type, item) => {
    switch (type) {
      case ItemTypes.GRID_ITEM:
        return <ItemsTemplate items={item.items} />;
      default:
        return null;
    }
  };
  if (!isDragging) {
    return null;
  }
  return (
    <div style={getItemStyles(currentOffset)}>
      <div className={cx(classes.field, isDragging && classes.dragging)}>{renderItem(itemType, item)}</div>
    </div>
  );
});
DragPreview.displayName = 'DragPreview';

ItemsDragLayer.propTypes = {};

export default ItemsDragLayer;
