import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import { Alert, Breadcrumbs, Button, IconButton, Tooltip } from '@mui/material';
import { AccountCircle, Link, StarBorder, PersonAdd, Settings, Visibility } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { useHistory } from 'react-router';

import { useMinimalAuth } from 'hooks';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

import { SharingComponent, useMainContext } from 'ReusableComponents';
import AccessLevelComponent from 'ReusableComponents/AccessLevelComponent';
import { isValidMap } from 'ReusableComponents/ValidLayer/ValidLayer';

import BreadcrumbLink from './BreadcrumbLink';
import { getErrorComp, rootNameMapping } from '../itemUtils';
import ApiManager from 'ApiManager';
import ManageSharingDialog from 'ReusableComponents/ManageSharingDialog';
import { getSharingType } from 'ReusableComponents/SharingComponent/SharingComponent';

const SMALL_SIZE = 700;

const useStyles = makeStyles({ name: 'Breadcrumbs' })((theme, _params, classes) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    minWidth: 0,
    minHeight: theme.spacing(9),
    position: 'relative',
    '&:after': {
      zIndex: 1,
      content: '""',
      position: 'absolute',
      top: `calc(100% - 2px)`,
      left: 0,
      right: 0,
      bottom: 1,
      background: theme.palette.primary.dark,
      opacity: 0.05,
      filter: `blur(0.75px)`,
    },
  },
  main: {
    display: 'flex',
    padding: theme.spacing(0.5, 0),
    gap: theme.spacing(2, 4),
    alignItems: 'center',
    flexWrap: 'wrap',
    flexBasis: '100%',
    minWidth: 0,
    position: 'relative',
    zIndex: 3,
  },
  external: {
    justifyContent: 'space-between',
  },
  container: {
    height: 'fit-content',
    minWidth: 0,
  },
  buttonBreadcrumb: {
    textTransform: 'none',
    padding: '0px 7px',
    minWidth: 'fit-content',
    color: theme.palette.text.secondary,
    [`&.${classes.active}`]: {
      color: theme.palette.text.primary,
    },
  },
  link: {
    textDecoration: 'none',
  },
  separator: {
    margin: '0px 2px',
  },
  active: {},
  alert: { alignItems: 'center', gap: 4 },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(6),
    minWidth: 0,
    '& .MuiBreadcrumbs-li': {
      minWidth: 0,
      maxWidth: '25vw',
      [theme.breakpoints.down('sm')]: { maxWidth: '50vw' },
    },
  },
  statusContainer: { display: 'flex', flexDirection: 'column', gap: theme.spacing(0.75) },
  flexContainer: { display: 'flex', alignItems: 'center', gap: theme.spacing(4) },
  breadcrumbContent: {
    gap: theme.spacing(1, 4),
    flexGrow: 1,
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: { flexWrap: 'wrap' },
  },
  pageActions: { gap: theme.spacing(2) },
  breadcrumbActions: { gap: theme.spacing(1) },
}));

const DriveBreadcrumbs = () => {
  const { classes, cx } = useStyles();

  const { root, currentFolderInfo: currentFolder, path, favoriteWholePathFolder, changeMainMode } = useCurrentFolder();
  const { onOpenSnackbar, windowWidth, tourOpen } = useMainContext();

  const [sharingOpen, setSharingOpen] = useState(false);
  const user = useMinimalAuth();
  const history = useHistory();

  const toggleFavorite = useCallback(() => {
    ApiManager.put(`/v3/path/${path[0].id}/favorite`, { favorite: true }, user)
      .then(() => {
        favoriteWholePathFolder();
      })
      .catch((err) => console.error(err));
  }, [user, favoriteWholePathFolder, path]);

  const copyToClipboard = () => {
    let url = window.location.host + '/drive/external';
    url = url + '?pathId=' + currentFolder.id;

    copy(url);

    onOpenSnackbar({
      content: 'Link to "' + currentFolder?.name + '" copied',
      level: 'success',
    });
  };

  const external = root === 'external' && (
    <div className={cx(classes.main, classes.external)}>
      {!!user ? (
        <Button startIcon={<StarBorder />} variant="outlined" color="primary" onClick={toggleFavorite}>
          Add to drive
        </Button>
      ) : (
        <Button
          startIcon={<AccountCircle />}
          variant="outlined"
          color="primary"
          onClick={() =>
            history.push('/login', {
              from: `${history.location.pathname}${history.location.search}`,
            })
          }
        >
          Log in to add to drive
        </Button>
      )}
      <Alert severity="info" variant="outlined" className={classes.alert}>
        This is an external path. {!!user ? 'Favorite this path' : 'Log in'} to add it to your drive.
      </Alert>
    </div>
  );

  const isRoot = !path || path?.length === 0;
  const isValidCurrentMap = isValidMap(currentFolder);
  const errorComp = getErrorComp(isValidCurrentMap?.reason);

  const hasShareAccess = currentFolder?.yourAccess.accessLevel >= ApiManager.newAccessLevel['share'];
  const hasEditPlusAccess = currentFolder?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+'];

  return (
    <div className={classes.root}>
      {external}
      {((root === 'favorites' && !!isRoot) ||
        (root === 'shared' && !!isRoot) ||
        (currentFolder?.yourAccess?.accessLevel < ApiManager.newAccessLevel['edit+'] && root !== 'external')) && (
        <Alert severity="info" variant="outlined" className={classes.alert}>
          View only - You cannot create items in this location
        </Alert>
      )}
      <div className={classes.main}>
        <div className={classes.wrapper}>
          <Breadcrumbs
            key={currentFolder?.id}
            maxItems={windowWidth > SMALL_SIZE ? 100 : 2}
            itemsBeforeCollapse={0}
            itemsAfterCollapse={2}
            className={classes.container}
            classes={{ separator: classes.separator }}
          >
            <BreadcrumbLink
              link={`/drive/${root === 'myMaps' ? 'me' : root}`}
              content={rootNameMapping[root]}
              isDroppable={root !== 'shared'}
              disabled={root === 'external'}
              owner={currentFolder?.owner}
            />
            {!!path
              ? [...path]?.map((folder, i) => (
                  <BreadcrumbLink key={folder.id} folder={folder} active={i === path.length - 1} />
                ))
              : null}
          </Breadcrumbs>
        </div>
        <div className={cx(classes.flexContainer, classes.breadcrumbContent)}>
          <div className={classes.flexContainer}>
            {!isRoot && windowWidth > SMALL_SIZE && (
              <div className={classes.statusContainer}>
                <AccessLevelComponent accessLevel={currentFolder?.yourAccess?.accessLevel} />
                <SharingComponent type={getSharingType(currentFolder)} />
              </div>
            )}
            <div className={cx(classes.flexContainer, classes.breadcrumbActions)}>
              <div className={classes.flexContainer}>
                {((!isRoot && hasShareAccess && windowWidth > SMALL_SIZE) || tourOpen) && (
                  <Tooltip title={!user ? 'Please login' : !hasShareAccess ? 'Insufficient Access' : 'Manage sharing'}>
                    <span>
                      <IconButton
                        className={'tutorialShare'}
                        onClick={() => setSharingOpen(true)}
                        disabled={!hasShareAccess}
                        size="large"
                      >
                        <PersonAdd />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </div>
              {((!isRoot && windowWidth > SMALL_SIZE) || tourOpen) && (
                <span className={'tutorialLink'}>
                  <Tooltip title="Copy Sharable Link">
                    <IconButton onClick={copyToClipboard} size="large">
                      <Link />
                    </IconButton>
                  </Tooltip>
                </span>
              )}
            </div>
          </div>

          <div className={cx(classes.flexContainer, classes.pageActions)}>
            {!isRoot &&
              ['map', 'shape'].includes(currentFolder.type) &&
              hasEditPlusAccess &&
              windowWidth > SMALL_SIZE && (
                <Tooltip title={!user ? 'Please login' : !hasEditPlusAccess ? 'Insufficient Access' : 'Settings'}>
                  <span>
                    <IconButton
                      disabled={!hasEditPlusAccess}
                      onClick={() => changeMainMode('/settings/general')}
                      size="large"
                    >
                      <Settings />
                    </IconButton>
                  </span>
                </Tooltip>
              )}

            {((!isRoot && windowWidth > SMALL_SIZE) || tourOpen) &&
              currentFolder?.type &&
              ['map', 'shape', 'pointCloud'].includes(currentFolder.type) && (
                <Tooltip title={errorComp?.disabled ? errorComp.title : 'Open in Viewer'}>
                  <span>
                    <div className={'tutorialOpenViewer'}>
                      <Button
                        disabled={errorComp?.disabled}
                        onClick={() => changeMainMode('/view')}
                        startIcon={<Visibility />}
                        variant="outlined"
                      >
                        View
                      </Button>
                    </div>
                  </span>
                </Tooltip>
              )}
          </div>
        </div>
      </div>

      <ManageSharingDialog open={sharingOpen} openFunc={setSharingOpen} path={currentFolder} suppressContextError />
    </div>
  );
};

DriveBreadcrumbs.propTypes = {
  pathId: PropTypes.string,
};

export default DriveBreadcrumbs;
