import { useState, useCallback } from 'react';

const useInput = (initialValue = '') => {
  const [value, setValue] = useState(initialValue);

  const handleChange = useCallback((event) => {
    event.preventDefault();
    setValue(event.target.value);
  }, []);

  const reset = useCallback(() => setValue(initialValue), [initialValue]);

  return [value, handleChange, setValue, reset];
};

export default useInput;
