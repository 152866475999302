import React from 'react';
import { Typography, Radio, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { InfoTooltip } from '../../core';

const useStyles = makeStyles()((theme, _params, classes) => ({
  formControl: {
    width: '330px',
  },
  section: { marginTop: theme.spacing(3) },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  infoTooltip: {
    marginLeft: '6px',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.light,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  radioGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  radioContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    [`&.${classes.disabled}`]: {
      cursor: 'auto',
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

const MainSettings = ({
  uploadedFileFormats,
  formatsForceEspg,
  autoDetectEspg,
  handleAutoDetectEspgChange,
  noDataValue,
  handleNoDataValue,
  type,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <section className={classes.section}>
        <div className={classes.sectionTitle}>
          <Typography>Get EPSG from:</Typography>{' '}
          <InfoTooltip
            className={classes.infoTooltip}
            interactive={true}
            placement="right"
            title={
              <React.Fragment>
                <Typography color="inherit">
                  The EPSG code tells us what coordinate system your file is using. See{' '}
                  <a href="https://epsg.io/" target="_blank" rel="noopener noreferrer" className={classes.link}>
                    https://epsg.io/
                  </a>
                  , for more information.
                </Typography>
              </React.Fragment>
            }
          />
        </div>

        <div className={classes.radioGroup}>
          <div
            className={`${classes.radioContainer} ${
              uploadedFileFormats.find((format) => formatsForceEspg.includes(format)) ? classes.disabled : ''
            }`}
            onClick={() => {
              if (uploadedFileFormats.find((format) => formatsForceEspg.includes(format))) {
                return;
              }
              handleAutoDetectEspgChange({ active: false, epsg: null });
            }}
          >
            <Radio
              color="primary"
              checked={!autoDetectEspg.active}
              disabled={!!uploadedFileFormats.find((format) => formatsForceEspg.includes(format))}
            />
            <Typography
              className={`${
                uploadedFileFormats.find((format) => formatsForceEspg.includes(format)) ? classes.disabled : ''
              }`}
            >
              Auto detect from file
            </Typography>
          </div>
          <div
            className={classes.radioContainer}
            onClick={() => !autoDetectEspg.active && handleAutoDetectEspgChange({ active: true })}
          >
            <Radio color="primary" checked={autoDetectEspg.active} />
            <Typography style={{ marginRight: '8px' }}>Specify: </Typography>
            <TextField
              key={autoDetectEspg.active}
              value={autoDetectEspg.epsg || ''}
              onChange={(e) => handleAutoDetectEspgChange({ active: true, epsg: e.target.value })}
              placeholder={type === 'pointCloud' ? '3857' : '4326'}
              type="number"
              error={autoDetectEspg.active && !autoDetectEspg.epsg}
              helperText={autoDetectEspg.active && !autoDetectEspg.epsg && 'Fill in an epsg code'}
            />
          </div>
        </div>
      </section>

      {type === 'raster' && (
        <section className={classes.section}>
          <div className={classes.sectionTitle}>
            <Typography>Set no data value:</Typography>{' '}
            <InfoTooltip
              className={classes.infoTooltip}
              interactive={true}
              placement="right"
              title={
                <React.Fragment>
                  <Typography color="inherit">
                    Raster pixels with the no data value will be interpreted as transparent.
                  </Typography>
                </React.Fragment>
              }
            />
          </div>

          <div className={classes.radioGroup}>
            <div
              className={`${classes.radioContainer} ${
                uploadedFileFormats.find((format) => formatsForceEspg.includes(format)) ? classes.disabled : ''
              }`}
              onClick={() => {
                handleNoDataValue(null);
              }}
            >
              <Radio
                color="primary"
                checked={noDataValue === null}
                disabled={!!uploadedFileFormats.find((format) => formatsForceEspg.includes(format))}
              />
              <Typography
                className={`${
                  uploadedFileFormats.find((format) => formatsForceEspg.includes(format)) ? classes.disabled : ''
                }`}
              >
                Detect from file
              </Typography>
            </div>
            <div className={classes.radioContainer} onClick={() => !noDataValue && handleNoDataValue(0)}>
              <Radio color="primary" checked={noDataValue !== null} />
              <Typography style={{ marginRight: '8px' }}>Specify: </Typography>
              <TextField
                value={noDataValue === null ? '' : noDataValue}
                onChange={(e) => handleNoDataValue(e.target.value)}
                type="number"
              />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

MainSettings.propTypes = {
  formatsForceEspg: PropTypes.arrayOf(PropTypes.string).isRequired,
  autoDetectEspg: PropTypes.object,
  handleAutoDetectEspgChange: PropTypes.func.isRequired,
};

export default MainSettings;
