import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { Autocomplete } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  form: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}));

const IndividualCardContent = ({ countries, billingInfo, updateBillingInfo, onCancel, setEdit }) => {
  const { classes } = useStyles();

  const [error, setError] = useState(false);
  const [name, setName] = useState(billingInfo?.name);
  const [street, setStreet] = useState(billingInfo?.address?.street);
  const [number, setNumber] = useState(billingInfo?.address?.number);
  const [city, setCity] = useState(billingInfo?.address?.city);
  const [zip, setZip] = useState(billingInfo?.address?.zip);
  const [inputCountry, setInputCountry] = useState(billingInfo?.country);
  const [country, setCountry] = useState(billingInfo?.country);

  let validFields =
    name != null &&
    name !== '' &&
    city != null &&
    city !== '' &&
    zip != null &&
    zip !== '' &&
    street != null &&
    street !== '' &&
    number != null &&
    number !== '' &&
    country != null &&
    country !== '' &&
    inputCountry != null &&
    inputCountry !== '';

  const onSubmit = () => {
    const body = {
      name: name,
      country: country?.name?.name ?? country?.name ?? country,
      isCompany: false,
      address: { city: city, zip: zip, street: street, number: number },
    };

    if (validFields) {
      setEdit(false);
      updateBillingInfo(body);
    } else {
      setError(true);
    }
  };

  return (
    <Box>
      <form className={classes.form}>
        <TextField
          label="Name"
          defaultValue={billingInfo?.name}
          required
          error={name === ''}
          variant="outlined"
          fullWidth
          onChange={(e) => setName(e.target.value)}
        ></TextField>
        <TextField
          label="Street"
          defaultValue={billingInfo?.address?.street}
          required
          error={street === ''}
          variant="outlined"
          fullWidth
          onChange={(e) => setStreet(e.target.value)}
        ></TextField>
        <Box display={'flex'} justifyContent="space-between">
          <TextField
            label="Number"
            defaultValue={billingInfo?.address?.number}
            required
            error={number === ''}
            variant="outlined"
            style={{ marginRight: '15px' }}
            fullWidth
            onChange={(e) => setNumber(e.target.value)}
          ></TextField>
          <TextField
            label="City"
            defaultValue={billingInfo?.address?.city}
            required
            error={city === ''}
            variant="outlined"
            fullWidth
            onChange={(e) => setCity(e.target.value)}
          ></TextField>
        </Box>
        <TextField
          label="Zip code"
          defaultValue={billingInfo?.address?.zip}
          required
          error={zip === ''}
          variant="outlined"
          fullWidth
          onChange={(e) => setZip(e.target.value)}
        ></TextField>
        <Autocomplete
          isOptionEqualToValue={(option, value) => option.name === value.name}
          defaultValue={{ name: billingInfo?.country }}
          onInputChange={(_, newValue) => {
            setInputCountry(newValue);
          }}
          onChange={(_, newValue) => {
            newValue && setCountry({ name: newValue });
          }}
          options={countries || []}
          getOptionLabel={(option) => option.name || ''}
          style={{ width: 'auto' }}
          slotProps={{ popper: { placement: 'top' } }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              label="Country"
              variant="outlined"
              required
              error={country === '' || inputCountry === ''}
            />
          )}
        />
        {error && (
          <Box py={1}>
            <Typography color="error">Some fields are missing or invalid!</Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="row-reverse" mt={2}>
          <Button disabled={!validFields} variant={'contained'} color="primary" onClick={onSubmit}>
            Submit
          </Button>
          <Box px={1} />
          {billingInfo != null && (
            <Button variant={'outlined'} color="primary" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
};

export default IndividualCardContent;
