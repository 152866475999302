import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { parseExtension } from 'Drive/FileItem/FilesUtils';

const useStyles = makeStyles({ name: 'FileNameComponent' })((theme, { maxWidth, variant }, classes) => ({
  root: {
    minWidth: 50,
    gap: 0,
    lineHeight: 1,
    maxWidth,
    fontSize: theme.typography?.[variant]?.fontSize,
    [`& .${classes.extension}`]: { fontSize: '0.66em', [theme.breakpoints.down('sm')]: { fontSize: '0.75em' } },
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'last baseline',
    flexWrap: 'nowrap',
    [`& .${classes.extension}`]: { maxWidth: '50%', flexShrink: 0 },
  },
  inline: { '&>*': { display: 'inline' } },
  extension: {},
  text: {},
}));

const FileNameComponent = ({ name, TypographyProps = {}, maxWidth = '100%', type = 'flex' }) => {
  const { fileName, fileExtension } = parseExtension(name);
  const { variant = 'body1', className: TypographyClassName } = TypographyProps;
  const { classes: styles, cx } = useStyles({ maxWidth, variant });

  return (
    <div className={cx(styles.root, styles[type])}>
      <Typography noWrap variant={variant} {...TypographyProps} className={cx(styles.text, TypographyClassName)}>
        {fileName}
      </Typography>
      <Typography
        noWrap
        variant={variant}
        {...TypographyProps}
        className={cx(styles.text, styles.extension, TypographyClassName)}
      >
        {fileExtension}
      </Typography>
    </div>
  );
};

export default FileNameComponent;
