import React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import { LoadingButton } from 'ReusableComponents';

const useSectionTitleStyles = makeStyles({
  name: 'SectionTitle',
})((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: theme.spacing(1, 2),
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  notFirst: {
    marginBottom: theme.spacing(2),
  },
  border: {
    borderBottom: `1px solid ${theme.palette.action.disabled}`,
  },
}));

const SectionTitle = ({ first = false, border = true, style: userStyles = {}, className, children }) => {
  const { classes, cx } = useSectionTitleStyles();

  return (
    <div
      className={cx(classes.container, !first && classes.notFirst, border && classes.border, className && className)}
      style={userStyles}
    >
      {children}
    </div>
  );
};

SectionTitle.propTypes = {
  first: PropTypes.bool,
  border: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/*
 * sub components
 */

const useSectionTitleTextStyles = makeStyles({
  name: 'SectionTitleText',
})((theme) => ({
  titleContainer: {
    display: 'flex',
    flexGrow: 1,
    paddingBottom: '1px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  title: {
    height: 'fit-content',
  },
}));

const SectionTitleText = ({
  style: userStyles = {},
  className,
  secondaryButton = null,
  children,
  TypographyProps = {},
  disableTypography = false,
  ContainerProps = {},
}) => {
  const { classes, cx } = useSectionTitleTextStyles();

  return (
    <div {...ContainerProps} className={cx(classes.titleContainer, ContainerProps?.className)}>
      {disableTypography ? (
        children
      ) : (
        <Typography
          component="h1"
          variant="h3"
          className={cx(classes.title, className)}
          style={userStyles}
          {...TypographyProps}
        >
          {children}
        </Typography>
      )}
      {secondaryButton && secondaryButton}
    </div>
  );
};

SectionTitleText.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  secondaryButton: PropTypes.node,
  children: PropTypes.node,
};

const useSectionTitleActionStyles = makeStyles({
  name: 'SectionTitleAction',
})((theme) => ({
  button: {
    height: '34px',
    minWidth: '100px',
    textTransform: 'none',
    maxWidth: 'fit-content',
  },
}));

const SectionTitleAction = ({ className, children, ...otherProps }) => {
  const { classes, cx } = useSectionTitleActionStyles();

  return (
    <LoadingButton variant="contained" color="primary" className={cx(classes.button, className)} {...otherProps}>
      {children}
    </LoadingButton>
  );
};

SectionTitleAction.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SectionTitle.SectionTitleText = SectionTitleText;
SectionTitle.SectionTitleAction = SectionTitleAction;

export default SectionTitle;
