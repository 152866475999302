import ApiManager from '../ApiManager';

const checkNotEmpty = (value, setErrors, valueName, valueLabel) => {
  if (value === '') {
    setErrors((prev) => ({ ...prev, [valueName]: `${valueLabel} is required` }));
    return false;
  }

  setErrors((prev) => ({ ...prev, [valueName]: '' }));
  return true;
};

const checkUsername = async (username, setErrors, doesExist = true) => {
  if (username === '') {
    setErrors((prev) => ({ ...prev, username: 'Username is required' }));
    return false;
  }

  if (!/^[A-Za-z0-9 _]*$/.test(username)) {
    setErrors((prev) => ({ ...prev, username: 'Username may only contain letters, numbers and underscores' }));
    return false;
  }

  if (username.includes(' ')) {
    setErrors((prev) => ({ ...prev, username: 'Username may not contain white spaces' }));
    return false;
  }

  if (username.length < 4) {
    setErrors((prev) => ({ ...prev, username: 'Username must have at least 4 characters' }));
    return false;
  }

  if (username.length > 64) {
    setErrors((prev) => ({ ...prev, username: 'Username cannot be over 64 characters long' }));
    return false;
  }

  const res = await ApiManager.get('/v3/user', { username: username, fuzzySearch: false });

  if (doesExist && res.find((r) => r.username === username)) {
    setErrors((prev) => ({ ...prev, username: 'Username already exists' }));
    return false;
  } else {
    setErrors((prev) => ({ ...prev, username: '' }));
  }

  return true;
};

const checkPassword = (password, setErrors) => {
  if (password === '') {
    setErrors((prev) => ({ ...prev, password: 'Password is required' }));
    return false;
  } else if (password.length < 7) {
    setErrors((prev) => ({ ...prev, password: 'Password should be at least 7 characters long' }));
    return false;
  }
  setErrors((prev) => ({ ...prev, password: '' }));
  return true;
};

const checkEmail = (email, setErrors) => {
  if (email === '') {
    setErrors((prev) => ({ ...prev, email: 'Email is required' }));
    return false;
  } else if (typeof email !== 'undefined') {
    let validEmail = false;
    if (email.includes('@')) {
      let ar = email.split('@');
      if (ar.length > 1 && ar[0].length > 0 && ar[1].length > 0) {
        let x = ar[1];
        if (x.includes('.')) {
          ar = x.split('.');
          if (ar.length > 1 && ar[0].length > 0 && ar[1].length > 0) {
            validEmail = true;
          }
        }
      }
    }
    if (!validEmail) {
      setErrors((prev) => ({ ...prev, email: 'Please enter valid email address.' }));
      return false;
    }
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  } else {
    setErrors((prev) => ({ ...prev, email: '' }));
    return true;
  }
};

/**
 * Submit to backend API, for the `/settings` route
 * @param {string} path - the path of the url to call (`/settings/${path}`)
 * @param {object} body - the body of the post request
 * @param {object} user - the user used for auth. Must contain a `token` field
 * @param {string} [successMsg] - the message to display in case of success
 * @param {onOpenSnackbar} [onOpenSnackbar] - global callback to display the feedback messages in a snackbar
 * @param {onSelectMap} [onSelectMap] - global callback to update the global map on success
 * @returns {object} the API response
 */
const submitSettingsToAPI = (method, path, body, user, successMsg, onOpenSnackbar, onSelectMap, callback, onError) => {
  return ApiManager.fetch(method, path, body, user)
    .then((res) => {
      if (!!successMsg && !!onOpenSnackbar) {
        onOpenSnackbar({
          content: successMsg,
          level: 'success',
        });
      }
      if (!!onSelectMap) {
        onSelectMap();
      }

      if (typeof callback === 'function') {
        callback();
      }

      return res;
    })
    .catch((error) => {
      if (onError) {
        onError();
      }
      if (!!onOpenSnackbar) {
        onOpenSnackbar({
          content: error.message,
          level: 'error',
        });
      }
    });
};

export { checkUsername, checkPassword, checkEmail, checkNotEmpty, submitSettingsToAPI };
