import React from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import InfoTooltip from '../InfoTooltip';

const useActionButtonStyles = makeStyles()((theme, { color, colorStrength }) => ({
  button: {
    textTransform: 'none',
    height: '25px',
    fontSize: '0.9rem',
    width: '100%',
    gridColumn: 3,
    color: theme.palette?.[color]?.[colorStrength],
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridRow: 3,
      maxWidth: '200px',
    },
  },
  outlined: {
    border: `1px solid ${theme.palette?.[color]?.[colorStrength]}`,
  },
  startIcon: {
    position: 'absolute',
    left: '12px',
  },
}));

const ActionButton = ({
  children,
  style: userStyles = {},
  color = 'primary',
  colorStrength = 'main',
  className = '',
  ...otherProps
}) => {
  const { classes } = useActionButtonStyles({ color, colorStrength });
  return (
    <Button
      variant="outlined"
      style={userStyles}
      className={`${classes.button} ${className}`}
      classes={{ outlined: classes.outlined, startIcon: classes.startIcon }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

ActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  color: PropTypes.string,
  colorStrength: PropTypes.string,
  className: PropTypes.string,
};

const useUserGroupSectionStyles = makeStyles()((theme) => ({
  section: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 170px',
    gridGap: '25px',
    [theme.breakpoints.down('md')]: {
      gridGap: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr auto auto',
      gridGap: '5px',
      paddingBottom: '10px',
      height: 'auto',
    },
    alignItems: 'center',
    paddingRight: '16px',
    paddingLeft: '24px',
    height: '58px',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0,0,0,0.05)',
    color: theme.palette.text.secondary,
    '&:last-child': {
      border: 'none',
    },
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  legendTooltip: {
    marginLeft: '6px',
  },
  title: {
    fontSize: '1.05rem',
    gridColumn: 1,
    color: theme.palette.text.primary,
  },
  value: {
    fontSize: '1rem',
    fontWeight: '600',
    minWidth: '100px',
    gridColumn: 2,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90px',
      gridColumn: 1,
    },
  },
}));

const ThreeColumnSection = ({
  legend,
  value,
  action,
  infoTooltipProps = {},
  style: userStyles = {},
  className = '',
}) => {
  const { classes } = useUserGroupSectionStyles();
  return (
    <section className={`${classes.section} ${className}`} style={userStyles}>
      <div className={classes.legendContainer}>
        <Typography className={classes.title}>{legend}</Typography>
        {!isEmpty(infoTooltipProps) && <InfoTooltip className={classes.legendTooltip} {...infoTooltipProps} />}
      </div>
      {!!value ? <Typography className={classes.value}>{value}</Typography> : ''}
      {!!action ? action : ''}
    </section>
  );
};

ThreeColumnSection.propTypes = {
  legend: PropTypes.string.isRequired,
  value: PropTypes.node,
  action: PropTypes.node,
  infoTooltipProps: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
};

export { ThreeColumnSection, ActionButton };
