import React from 'react';
import { PropTypes } from 'prop-types';

import { Fab, FormControlLabel, SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'RoundButtonWithLabel' })((theme, _params, classes) => ({
  root: {
    margin: 0,
    width: '100%',
    gap: theme.spacing(1.5),

    [`& .${classes.labelContainer}`]: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      textAlign: 'center',
      padding: theme.spacing(0, 1),
      transition: theme.transitions.create(['text-align, padding'], {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }),
      position: 'relative',
    },
    [`&.${classes.arrow}:hover .${classes.labelContainer}`]: {
      padding: theme.spacing(0),
      paddingRight: theme.spacing(2),
      textAlign: 'left',
    },
    [`& .${classes.arrow}`]: {
      width: 0,
      opacity: 0,
      alignSelf: 'center',
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create(['width', 'opacity'], {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeInOut,
      }),
    },
    [`&:hover .${classes.arrow}`]: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      opacity: 1,
    },
  },
  button: {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    zIndex: 1,
    transition: theme.transitions.create(['background-color', 'color']) + '!important',
    '&:hover': { backgroundColor: '#f0f0f0' },
    [`&.${classes.selected}`]: { color: theme.palette.secondary.main },
  },
  label: {
    color: theme.palette.text.primary,
    textAlign: 'inherit',
    overflowWrap: 'anywhere',
  },
  arrow: {},
  dangerZone: { [`& .${classes.label}`]: { color: theme.palette.error.dark } },
  selected: {},
  disabled: { pointerEvents: 'none', [`& .${classes.label}`]: { color: theme.palette.action.disabled } },
  labelContainer: {},
  speedDialContainer: {
    '&& .MuiSpeedDial-actions': { position: 'absolute', marginTop: 0 },
  },
  speedDial: {
    [`&:hover .${classes.button}`]: { backgroundColor: theme.palette.primary.main, color: 'white' },
  },
  speedDialIcon: {},
}));

const RoundButtonWithLabel = ({
  icon,
  label,
  component: Component,
  componentProps = {},
  onClick,
  disabled = false,
  arrow = false,
  dangerZone = false,
  selected = false,
  actions = false,
  ...rest
}) => {
  const { classes: styles, cx } = useStyles();

  const disabledFunc = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const inner = (
    <FormControlLabel
      className={cx(
        styles.root,
        !!dangerZone && styles.dangerZone,
        !!arrow && styles.arrow,
        !!disabled && styles.disabled
      )}
      control={
        actions !== false ? (
          <div className={styles.speedDialContainer}>
            <SpeedDial
              className={styles.speedDial}
              ariaLabel={label}
              direction="down"
              icon={<SpeedDialIcon icon={icon} className={styles.speedDialIcon} />}
              FabProps={{
                size: 'small',
                className: cx(styles.button, !!selected && styles.selected),
                disabled: disabled,
              }}
              {...rest}
            >
              {actions?.map(({ label, onClick: actionClick, ...action }) => (
                <SpeedDialAction key={label} tooltipTitle={label} FabProps={{ onClick: actionClick }} {...action} />
              ))}
            </SpeedDial>
          </div>
        ) : (
          <Fab size="small" className={cx(styles.button, !!selected && styles.selected)} disabled={disabled} {...rest}>
            {icon}
          </Fab>
        )
      }
      labelPlacement="bottom"
      disableTypography
      label={
        <div className={styles.labelContainer}>
          <Typography variant="button" className={cx(styles.label, !!disabled && styles.disabled)}>
            {label}
          </Typography>
          {!!arrow && !disabled && <KeyboardArrowRight fontSize="inherit" className={styles.arrow} />}
        </div>
      }
      onClick={!!disabled ? disabledFunc : onClick}
      disabled={disabled}
    />
  );

  return !!Component && !disabled ? <Component {...componentProps}>{inner}</Component> : inner;
};

RoundButtonWithLabel.propTypes = {
  icon: PropTypes.element.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  component: PropTypes.elementType,
  onClick: PropTypes.func,
  componentProps: PropTypes.object,
  disabled: PropTypes.bool,
  arrow: PropTypes.bool,
  dangerZone: PropTypes.bool,
  selected: PropTypes.bool,
};

export default RoundButtonWithLabel;
