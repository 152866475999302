import React from 'react';

import { AddCircle } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useMainContext } from 'ReusableComponents';
// import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const NewProject = React.forwardRef(({ close }, ref) => {
  const { handleOpenNewProjectDialog } = useMainContext();
  // const { currentFolderInfo, mainMode } = useCurrentFolder();

  return (
    <CombinedMenuItem
      icon={<AddCircle color="secondary" />}
      label="New Layer"
      action={() => {
        close();
        handleOpenNewProjectDialog();
      }}
      ref={ref}
    />
  );
});

export default NewProject;
NewProject.displayName = 'NewProject';
