import React from 'react';

import {
  BookmarkBorder,
  Email,
  Extension,
  Folder,
  InsertDriveFile as File,
  Landscape,
  Language,
  LocationCity,
  Map,
  Terminal,
} from '@mui/icons-material';
import { Avatar, SvgIcon, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { faDrawPolygon, faSatellite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useEmptyStyles from 'hooks/useEmptyStyles';

const FontAwesomeSvgIcon = React.forwardRef((props, ref) => {
  const { className, icon, sx = {} } = props;

  const { cx } = useEmptyStyles();

  const {
    icon: [width, height, , , svgPathData],
  } = icon;

  return (
    <SvgIcon
      ref={ref}
      viewBox={`0 0 ${width} ${height}`}
      className={cx('fa', className)}
      sx={{ boxSizing: 'border-box', p: `1.5px`, ...sx }}
    >
      {typeof svgPathData === 'string' ? (
        <path d={svgPathData} />
      ) : (
        svgPathData.map((d, i) => <path key={i} style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />)
      )}
    </SvgIcon>
  );
});

FontAwesomeSvgIcon.displayName = 'FontAwesomeSvgIcon';

const ShapeIcon = ({ className, sx }) => <FontAwesomeSvgIcon className={className} icon={faDrawPolygon} sx={sx} />;
const PointCloudIcon = ({ className, sx }) => (
  <SvgIcon className={className} sx={sx}>
    <path d="M17.593 5.574a1.045 1.045 0 0 0 .373 1.43l.58.34L12 10.956l-1.66-.916a1.045 1.045 0 1 0-1.01 1.83l1.625.896v7.169l-.615-.34a1.045 1.045 0 0 0-1.01 1.83l2.165 1.195c.306.169.675.174.985.013l8.337-4.312c.545-.282.888-.845.888-1.459v-9.49c0-.371-.197-.715-.517-.902L19.023 5.2a1.045 1.045 0 0 0-1.43.374Zm-4.548 14.414v-7.222l6.57-3.624v7.448l-6.57 3.398Z" />
    <path d="M12.528 1.393a1.045 1.045 0 0 0-1.056 0l-.003.002a1.045 1.045 0 0 0 .668 1.938 1.045 1.045 0 0 0 .393-1.938l-.002-.002Zm.733 1.642a1.045 1.045 0 0 0 .373 1.43l.004.002a1.045 1.045 0 1 0 1.057-1.803l-.004-.003a1.045 1.045 0 0 0-1.43.374Zm-3.243.422a1.045 1.045 0 0 0-1.43-.373l-.006.003A1.045 1.045 0 1 0 9.64 4.89l.006-.003c.498-.292.665-.932.373-1.43Zm5.408.847a1.045 1.045 0 0 0 .373 1.43l.002.001a1.045 1.045 0 1 0 1.057-1.803l-.002-.001a1.045 1.045 0 0 0-1.43.373Zm-8.295.845a1.045 1.045 0 0 0-1.43-.373l-.005.003a1.045 1.045 0 0 0 1.057 1.804l.005-.003c.498-.292.665-.933.373-1.43ZM4.245 6.841a1.045 1.045 0 0 0-1.43-.373l-.003.002c-.32.187-.517.53-.517.902v.002a1.045 1.045 0 0 0 2.08.156 1.04 1.04 0 0 0-.13-.689Zm.343 1.219a1.045 1.045 0 0 0 .41 1.42l.004.002a1.045 1.045 0 0 0 1.01-1.83l-.004-.002a1.045 1.045 0 0 0-1.42.41Zm2.165 1.194a1.045 1.045 0 0 0 .41 1.42l.002.002a1.045 1.045 0 1 0 1.01-1.83l-.002-.002a1.045 1.045 0 0 0-1.42.41ZM3.34 9.51c-.577 0-1.045.468-1.045 1.045v.006a1.045 1.045 0 1 0 2.09 0v-.006c0-.577-.468-1.045-1.045-1.045Zm0 3.185c-.577 0-1.045.468-1.045 1.045v.006a1.045 1.045 0 0 0 2.09 0v-.006c0-.577-.468-1.045-1.045-1.045Zm0 3.185c-.577 0-1.045.468-1.045 1.045v.003c0 .38.207.731.54.915l.002.001A1.045 1.045 0 0 0 4.3 16.508c-.16-.37-.53-.629-.959-.629Zm1.248 1.737a1.045 1.045 0 0 0 .41 1.42l.004.002a1.045 1.045 0 1 0 1.01-1.83l-.004-.003a1.045 1.045 0 0 0-1.42.41Zm2.165 1.194a1.045 1.045 0 0 0 .41 1.42l.002.001a1.045 1.045 0 0 0 1.01-1.83l-.002-.001a1.045 1.045 0 0 0-1.42.41Z" />
  </SvgIcon>
);

const useStyles = makeStyles({ name: 'ProjectTypeIcon' })((theme) => ({
  root: { display: 'inline-flex', justifyContent: 'center', position: 'relative' },
  avatar: { color: '#fff', width: 'inherit', height: 'inherit', aspectRatio: 1 },
  shape: { backgroundColor: theme.palette.shape.main },
  pointCloud: { backgroundColor: theme.palette.pointCloud.main },
  map: { backgroundColor: theme.palette.map.main },
  elevation: { backgroundColor: theme.palette.elevation.main },
  view: { backgroundColor: theme.palette.secondary.main },
  process: { backgroundColor: theme.palette.process.main },
  folder: { backgroundColor: theme.palette.folder.main },
  file: { backgroundColor: theme.palette.file.main },
  buildings: { backgroundColor: '#d6a718' },
  message: { backgroundColor: '#d6a718' },
  base: { backgroundColor: '#20e3e0' },
  icon: { width: '66%', height: '66%' },
  order: {
    color: '#fff',
    backgroundColor: theme.palette.secondary.main,
    width: '66%',
    height: '66%',
    position: 'absolute',
    top: '-25%',
    right: '-25%',
  },
  orderIcon: {
    width: '58%',
    height: '58%',
  },
}));

const parseType = (type) =>
  type === 'shape' || type === 'vector' ? 'vector' : type === 'map' || type === 'raster' ? 'raster' : type;

export const Icon = ({ className, sx, base, type, ...props }) => {
  const parsedType = parseType(type);

  return parsedType === 'process' ? (
    <Terminal className={className} sx={sx} {...props} />
  ) : parsedType === 'bookmark' ? (
    <BookmarkBorder className={className} sx={sx} {...props} />
  ) : parsedType === 'vector' ? (
    <ShapeIcon className={className} sx={sx} {...props} />
  ) : parsedType === 'raster' ? (
    <Map className={className} sx={sx} {...props} />
  ) : parsedType === 'pointCloud' ? (
    <PointCloudIcon className={className} sx={sx} {...props} />
  ) : parsedType === 'wmts' && base ? (
    <Language className={className} sx={sx} {...props} />
  ) : parsedType === 'buildings' ? (
    <LocationCity className={className} sx={sx} {...props} />
  ) : parsedType === 'elevation' ? (
    <Landscape className={className} sx={sx} {...props} />
  ) : parsedType === 'message' ? (
    <Email className={className} sx={sx} {...props} />
  ) : parsedType === 'folder' ? (
    <Folder className={className} sx={sx} {...props} />
  ) : parsedType === 'file' ? (
    <File className={className} sx={sx} {...props} />
  ) : (
    <Extension className={className} sx={sx} {...props} />
  );
};

const ProjectTypeIcon = ({ type, base = false, tooltip = false, style = {}, className = '' }) => {
  const { classes: styles, cx } = useStyles();

  const parsedType = parseType(type);

  const TypeIcon = (
    <div className={cx(styles.root, className)} style={style}>
      <Avatar
        className={cx(
          styles.avatar,
          type === 'process'
            ? styles.process
            : type === 'bookmark'
            ? styles.view
            : type === 'elevation'
            ? styles.elevation
            : type === 'folder'
            ? styles.folder
            : type === 'message'
            ? styles.message
            : type === 'buildings'
            ? styles.buildings
            : type === 'file'
            ? styles.file
            : base && type === 'wmts'
            ? styles.map
            : type === 'wfs' || parsedType === 'vector' || parsedType === 'vectorTiles'
            ? styles.shape
            : type === 'pointCloud'
            ? styles.pointCloud
            : styles.map
        )}
      >
        <Icon className={styles.icon} type={parsedType} base={base} />
      </Avatar>
      {type === 'order' && (
        <Avatar className={styles.order}>
          <FontAwesomeIcon icon={faSatellite} className={styles.orderIcon} />
        </Avatar>
      )}
    </div>
  );

  return tooltip ? (
    <Tooltip arrow title={parsedType}>
      {TypeIcon}
    </Tooltip>
  ) : (
    TypeIcon
  );
};

export default ProjectTypeIcon;
