import React, { createContext, useContext, useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { useMainContext } from 'ReusableComponents';

import ViewerUtility from 'Viewer/ViewerUtility';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { usePaneContentFuncs } from 'Viewer/DetailsPane/PaneContentContext';
import { PRESENTATION_MODE } from 'Viewer/ViewerUtility';
import { useMinimalAuth } from 'hooks';

export let viewPosition = { bounds: { xMin: -180, xMax: 180, yMin: -85, yMax: 85 }, zoom: 0 };

const mainViewerContext = createContext();

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const MainViewerProvider = ({ children }) => {
  const { setHideNavbar, tourOpen, hideNavbar } = useMainContext();

  const { currentFolderInfo: selectedMap } = useCurrentFolder();
  const user = useMinimalAuth();
  const [viewerConfig, setViewerConfig] = useState({});
  const [propertyTableSelect, setPropertyTableSelect] = useState();
  const [propertyTable, setPropertyTable] = useState();
  const [viewMode, setViewModeN] = useState('2D');
  const [flyToTarget, setFlyToTarget] = useState();
  const { hidePane } = usePaneContentFuncs();
  const [geolocation, setGeolocation] = useState();
  const [view3dConfig, setView3dConfigN] = useState({
    number: 0,
    radius: {},
    maxRadius: {},
    lineWidth: {},
    maxLoadingDepth: {},
    geometriesStep: {},
    MBsStep: {},
    altitudeOffset: {},
    altitudeMultiplier: {},
    maxLineWidth: {},
    pointCloudPages: {},
    retainView: {},
  });

  const setViewPosition = useCallback((x) => {
    viewPosition = x;
  }, []);

  const callB2 = useRef({ N: 0, cb: null });
  const done2 = useRef(0);
  useEffect(() => {
    if (done2.current !== callB2.current.N) {
      done2.current = callB2.current.N;
      if (callB2.current.cb) {
        callB2.current.cb();
      }
    }
  }, [flyToTarget]);

  useEffect(() => {
    console.log('user', user);
    console.log('hide navbar', hideNavbar);
    if (user && !user.username && !hideNavbar) {
      setHideNavbar(true);
    }
  }, [user, setHideNavbar, hideNavbar]);

  const isPresentationMode = useMemo(() => viewerConfig?.[PRESENTATION_MODE], [viewerConfig]);

  const onFlyTo = useCallback(
    async (targetObj, cb, withDelay = false, pathId) => {
      if (!targetObj) {
        setFlyToTarget(null);
        return;
      }
      if (window.innerWidth < 1000) {
        hidePane();
        await sleep(600);
      } else if (withDelay) {
        await sleep(600);
      }

      if (targetObj.type === 'bounds' && targetObj.bounds.xMax > 110 && targetObj.bounds.xMin < -110) {
        targetObj.bounds.xMin = -110;
        targetObj.bounds.xMax = 110;
      }

      if (targetObj.type === 'bounds' && targetObj.bounds.yMax > 60 && targetObj.bounds.yMin < -60) {
        targetObj.bounds.yMin = -60;
        targetObj.bounds.yMax = 60;
      }
      if (cb) {
        callB2.current = { cb: cb, N: callB2.current.N + 1 };
      }
      targetObj.pathId = selectedMap?.id;
      setFlyToTarget(targetObj);
    },
    [hidePane, selectedMap?.id]
  );

  const setView3dConfig = useCallback((view3dConfigN) => {
    if (view3dConfigN.camera) {
      view3dConfigN.camera.storedInfo = false;
    }
    view3dConfigN.number = 2;
    setView3dConfigN(view3dConfigN);
  }, []);

  const callB = useRef({ N: 0, cb: null });
  const done = useRef(0);
  useEffect(() => {
    if (done.current !== callB.current.N) {
      done.current = callB.current.N;
      if (callB.current.cb) {
        callB.current.cb();
      }
    }
  }, [viewMode]);

  const setViewMode = useCallback((x, cb) => {
    if (cb) {
      callB.current = { cb: cb, N: callB.current.N + 1 };
    }
    setViewModeN(x);
  }, []);

  const viewModeSwitch = useCallback(
    (e) => {
      if (viewMode === '3D') {
        setViewMode('2D', () => {
          if (e) {
            onFlyTo({ type: 'point', zoom: Math.round(e.z), point: { x: e.x, y: e.y } });
          }
        });
      } else {
        setViewMode('3D', () => {
          const boundingCenter = [
            (viewPosition.bounds.yMax + viewPosition.bounds.yMin) / 2,
            (viewPosition.bounds.xMax + viewPosition.bounds.xMin) / 2,
          ];
          if (!viewPosition.center) {
            // eslint-disable-next-line react/no-direct-mutation-state
            viewPosition.center = boundingCenter;
          }
          onFlyTo({
            type: 'point',
            zoom: Math.round(viewPosition.zoom),
            point: { x: viewPosition.center[1], y: viewPosition.center[0] },
            viewModeSwitch: true,
          });
        });
      }
    },
    [onFlyTo, setViewMode, viewMode]
  );

  useEffect(() => {
    if (!propertyTableSelect) {
      setPropertyTable(null);
    }
  }, [propertyTableSelect]);

  const getViewerConfig = useCallback(
    ({ viewerConfig = {} }) => {
      let alterViewerConfig = ViewerUtility.parseViewerConfig(viewerConfig, true);

      setHideNavbar(!!alterViewerConfig?.hideNavbar);

      setViewerConfig({ ...alterViewerConfig, loaded: true });
    },
    [setHideNavbar]
  );

  const closePropertyTable = useCallback(() => setPropertyTableSelect(null), []);

  const setLocation = useCallback(
    (position) => {
      if (position) {
        if (
          !geolocation ||
          geolocation.latitude !== position.coords.latitude ||
          geolocation.longitude !== position.coords.longitude
        ) {
          let newGeolocation = [position.coords.latitude, position.coords.longitude];
          onFlyTo({ type: 'point', zoom: 14, point: { x: newGeolocation[1], y: newGeolocation[0] } });
          setGeolocation(newGeolocation);
        }
      }
    },
    [onFlyTo, geolocation]
  );

  const getUserLocation = useCallback(() => {
    try {
      if (!tourOpen) {
        navigator.geolocation.getCurrentPosition(setLocation, (error) => console.log(error), {
          enableHighAccuracy: true,
          timeout: 5000,
        });
      }
    } catch {
      setLocation(null);
    }
  }, [setLocation, tourOpen]);

  const value = useMemo(
    () => ({
      viewerConfig,
      getViewerConfig,
      propertyTableSelect,
      setPropertyTableSelect,
      viewModeSwitch,
      closePropertyTable,
      propertyTable,
      setPropertyTable,
      viewMode,
      onFlyTo,
      flyToTarget,
      setViewMode,
      setViewPosition,
      getUserLocation,
      geolocation,
      view3dConfig,
      setView3dConfig,
      isPresentationMode,
    }),
    [
      geolocation,
      viewerConfig,
      setViewPosition,
      viewModeSwitch,
      getViewerConfig,
      viewMode,
      setViewMode,
      propertyTableSelect,
      closePropertyTable,
      flyToTarget,
      onFlyTo,
      setPropertyTableSelect,
      propertyTable,
      setPropertyTable,
      getUserLocation,
      view3dConfig,
      setView3dConfig,
      isPresentationMode,
    ]
  );

  return <mainViewerContext.Provider value={value}>{children}</mainViewerContext.Provider>;
};

export function useMainViewer() {
  const context = useContext(mainViewerContext);
  if (context === undefined) {
    throw new Error('useMainViewer must be used within a mainViewerProvider');
  }
  return context;
}

export function withMainViewer(Component) {
  return function WrapperComponent(props) {
    const context = useMainViewer();
    return <Component {...props} {...context} />;
  };
}

export function withMainViewerContext(Component) {
  return function WrapperComponent(props) {
    return (
      <MainViewerProvider>
        <Component {...props} />
      </MainViewerProvider>
    );
  };
}
