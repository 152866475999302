import React, { useRef, useState, useEffect, useMemo, useCallback } from 'react';

import {
  Badge,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Notifications as NotificationsIcon, Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import NotificationMenu from './NotificationMenu';
import { submitGetRequestToAPI } from './utils';
import { useNotificationState, useNotificationSetter } from './NotificationContext';
import VersionUtility from 'VersionUtility';
import { useMinimalAuth } from 'Account/AuthContext';
import { useQueryParams } from 'hooks';
import { SetupProgressWithSteps } from '../SetupProgress';
import ApiManager from 'ApiManager';

const useStyles = makeStyles()((theme, { hasNotifications, menuOpen }) => ({
  notificationButton: {
    color: menuOpen ? (hasNotifications ? theme.palette.secondary.main : 'inherit') : 'inherit',
  },
  showMoreButton: {
    width: 'fit-content',
    alignSelf: 'center',
  },
  cancelButton: {},
  divider: {
    width: 'calc(100% - 32px)',
    margin: '0 16px',
    boxSizing: 'border-box',
    marginBottom: '8px',
  },
  noNotificationText: {
    padding: '20px',
  },
}));

const INIT_SHOW_ALL = {
  invites: false,
  shares: false,
  imports: false,
  downloads: false,
  engagement: false,
};

function Notifications({ className }) {
  const menuOpen = useNotificationState();
  const setMenuOpen = useNotificationSetter();
  const { notification } = useQueryParams();

  const history = useHistory();
  const [notifications, setNotifications] = useState({
    invites: [],
    shares: [],
    imports: [],
    downloads: [],
    engagement: [],
  });
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(INIT_SHOW_ALL);

  const [allowOutClick, setAllowOutClick] = useState(false);
  const menuAnchorRef = useRef();

  const user = useMinimalAuth();

  useEffect(() => {
    if (!menuOpen) {
      setShowAll(INIT_SHOW_ALL);
      setLoading(true);
    }
  }, [menuOpen]);

  useEffect(() => {
    if (menuOpen) {
      const notRead = notifications?.engagement?.filter((n) => !n.read);
      if (notRead.length > 0) {
        const notReadIds = notRead.map((n) => n.id);
        ApiManager.put(`/v3/account/notification/read`, { notificationIds: notReadIds }, user);
      }
    }
  }, [menuOpen, notifications?.engagement, user]);

  const hasNotifications = useMemo(
    () =>
      (notifications?.downloads?.length > 0 &&
        notifications?.downloads?.some((notif) => notif.status !== 'downloaded')) ||
      notifications?.invites?.length > 0 ||
      notifications?.imports?.result?.length > 0 ||
      notifications?.engagement?.find((n) => !n.read) > 0 ||
      notifications?.shares?.length > 0,
    [notifications]
  );

  const { classes, cx } = useStyles({ hasNotifications, menuOpen });

  // get notifications

  const currentUser = useRef(user);

  useEffect(() => {
    history.listen((location) => {
      if (location.pathname.includes('login')) {
        setMenuOpen(false);
      }
    });
    //I need to close it since fetching notifications does not refresh otherwise
    if (!user || (currentUser.current && user && currentUser.current.id !== user.id)) {
      currentUser.current = user;
      setMenuOpen(false);
    }
  }, [user, history, setMenuOpen]);

  useEffect(() => {
    let fetchNotifications = async () => {
      try {
        let [invites, shares, imports, downloadsPointCloud, downloadsMap, downloadsShape, downloadsFile] =
          await Promise.all([
            submitGetRequestToAPI('/v3/path/transfer', null, user),
            submitGetRequestToAPI('/v3/path/invite', null, user),
            submitGetRequestToAPI('/v3/path/setupTask', null, user),
            submitGetRequestToAPI('/v3/path/pointCloud/timestamp/order', null, user),
            submitGetRequestToAPI('/v3/path/raster/timestamp/order', null, user),
            submitGetRequestToAPI('/v3/path/vector/timestamp/order', null, user),
            submitGetRequestToAPI('/v3/path/order', null, user),
          ]);
        invites = VersionUtility.convertInvites(invites);

        shares = VersionUtility.convertInvites(shares);
        const engagement = [];
        setNotifications((prev) => ({
          ...prev,
          shares,
          engagement,
          imports,
          ...(!menuOpen
            ? {
                invites: invites.sort(
                  (a, b) => moment(b.date).format('YYYYMMDDHHmm') - moment(a.date).format('YYYYMMDDHHmm')
                ),
              }
            : {}),
          downloads: [
            ...downloadsMap.map((download) => ({ ...download, type: 'map' })),
            ...downloadsShape.map((download) => ({ ...download, type: 'shape' })),
            ...downloadsPointCloud.map((download) => ({ ...download, type: 'pointCloud' })),
            ...downloadsFile.map((download) => ({
              ...download,
              path: { name: download.id, id: download.id },
              type: 'file',
            })),
          ].sort((a, b) => moment(b.date).format('YYYYMMDDHHmm') - moment(a.date).format('YYYYMMDDHHmm')),
        }));
      } catch (error) {
        console.warn(error);
      }
      if (menuOpen) {
        setLoading(false);
      }
    };
    if (user && menuOpen) {
      fetchNotifications();
      const interval = setInterval(() => {
        fetchNotifications();
      }, 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [user, menuOpen]);

  useMemo(() => {
    if (notification) {
      setTimeout(() => {
        setMenuOpen(true);
      }, 1000);
    }
  }, [setMenuOpen, notification]);

  const [accountSetup, setAccountSetup] = useState(true);

  useCallback((bool) => {
    setAccountSetup(bool);
  }, []);

  return (
    <>
      <IconButton
        className={cx(classes.notificationButton, 'tutorialNotificationButton', className)}
        color="inherit"
        ref={menuAnchorRef}
        onClick={() => {
          setMenuOpen(!menuOpen);
          setAllowOutClick(true);
        }}
      >
        <Tooltip title="Notifications" aria-label="notifications" placement="bottom">
          <Badge
            color="secondary"
            overlap="circular"
            variant="dot"
            invisible={(!hasNotifications && accountSetup) || menuOpen}
          >
            <NotificationsIcon />
          </Badge>
        </Tooltip>
      </IconButton>
      <SetupProgressWithSteps setAccountMenuOpen={() => setMenuOpen(false)} setAccountSetup={setAccountSetup} />

      <NotificationMenu
        open={menuOpen}
        allowOutClick={allowOutClick}
        handleClose={() => {
          setMenuOpen(false);
        }}
        anchorRef={menuAnchorRef}
        notification={notification}
      >
        <ListItem
          sx={{ position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white', mt: -0.5 }}
          secondaryAction={
            <IconButton className={classes.cancelButton} onClick={() => setMenuOpen(false)} edge="end">
              <Close />
            </IconButton>
          }
        >
          <ListItemText primary="Notifications" primaryTypographyProps={{ variant: 'h3' }} sx={{ m: 0 }} />
        </ListItem>

        {notifications?.invites.length === 0 &&
          notifications?.shares?.length === 0 &&
          notifications?.imports?.result?.length === 0 &&
          notifications?.downloads?.length === 0 && (
            /*notifications?.engagement?.length === 0 &&*/ <Typography className={classes.noNotificationText}>
              No notifications
            </Typography>
          )}
        {loading && (
          <Typography className={classes.noNotificationText}>
            {' '}
            <CircularProgress />
          </Typography>
        )}

        {!loading && <SetupProgressWithSteps setAccountMenuOpen={() => setMenuOpen(false)} />}

        {!loading && notifications?.shares?.length > 0 && (
          <NotificationMenu.Section title="Pending layer invites">
            {notifications?.shares.slice(0, 2).map((share, i) => (
              <div key={share.pathId}>
                <NotificationMenu.SectionMenuItem
                  pathId={share.pathId}
                  inviteId={share.id}
                  type={'share'}
                  projectType={share.type}
                  title={share.name}
                  sharedByUser={share.user}
                  value={!!Number(share.subscriptionFee) ? `€${share.subscriptionFee}` : 'Free'}
                  fee={share.subscriptionFee}
                  user={user}
                  handleClose={() => {
                    setMenuOpen(false);
                  }}
                />
                {(i < 1 || showAll?.shares) && i < notifications?.shares?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </div>
            ))}
            {showAll?.shares || notifications?.shares?.length < 3 ? (
              <>
                {notifications?.shares?.slice(2)?.map((share, i) => (
                  <div key={share.pathId}>
                    <NotificationMenu.SectionMenuItem
                      pathId={share.pathId}
                      title={share.name}
                      inviteId={share.id}
                      type={'share'}
                      projectType={share.type}
                      sharedByUser={share.user}
                      value={!!Number(share.subscriptionFee) ? `€${share.subscriptionFee}` : 'Free'}
                      fee={share.subscriptionFee}
                      user={user}
                      handleClose={() => setMenuOpen(false)}
                    />
                    {i < notifications?.shares?.length - 3 && <Divider className={classes.divider} />}
                  </div>
                ))}
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowAll((prev) => ({ ...prev, shares: true }))}
                className={classes.showMoreButton}
                style={{ marginTop: '8px', marginBottom: '4px' }}
              >
                Show more
              </Button>
            )}
          </NotificationMenu.Section>
        )}

        {!loading && notifications?.invites?.length > 0 && (
          <NotificationMenu.Section title="Pending layer transfers">
            {notifications?.invites?.slice(0, 2)?.map((invite, i) => (
              <div key={invite.id + i}>
                <NotificationMenu.SectionMenuItem
                  map={invite.path}
                  type={'invite'}
                  inviteId={invite.id}
                  projectType={invite.type}
                  title={invite.name}
                  invitedByUser={invite.user}
                  value={!!Number(invite.subscriptionFee) ? `€${invite.subscriptionFee}` : 'Free'}
                  fee={invite.subscriptionFee}
                  handleClose={() => setMenuOpen(false)}
                />
                {(i < 1 || showAll?.invites) && i < notifications?.invites?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </div>
            ))}
            {showAll?.invites || notifications?.invites?.length < 3 ? (
              <>
                {notifications?.invites?.slice(2).map((invite, i) => (
                  <div key={invite.token}>
                    <NotificationMenu.SectionMenuItem
                      inviteToken={invite.token}
                      inviteId={invite.id}
                      type={'invite'}
                      projectType={invite.type}
                      title={invite.mapName}
                      invitedByUser={invite.user}
                      map={invite.path}
                      value={!!Number(invite.subscriptionFee) ? `€${invite.subscriptionFee}` : 'Free'}
                      handleClose={() => setMenuOpen(false)}
                    />
                    {i < notifications?.invites?.length - 3 && <Divider className={classes.divider} />}
                  </div>
                ))}
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowAll((prev) => ({ ...prev, invites: true }))}
                className={classes.showMoreButton}
                style={{ marginTop: '8px', marginBottom: '4px' }}
              >
                Show more
              </Button>
            )}
          </NotificationMenu.Section>
        )}

        {!loading && notifications?.imports?.result?.length > 0 && (
          <NotificationMenu.Section title="Imports">
            {notifications?.imports?.result?.slice(0, 2)?.map((n, i) => (
              <div key={i}>
                <NotificationMenu.SectionMenuItem
                  type={'imports'}
                  title={`${n?.parameters?.title ?? n?.parameters?.name ?? n?.parameters?.stacInfo?.feature?.id}`}
                  legend={n?.status === 'errored' ? n?.message : moment(n.date).format('L')}
                  status={n?.status}
                  thumbnail={n?.parameters?.thumbnail}
                  pathId={n?.path?.id}
                  handleClose={() => setMenuOpen(false)}
                />
                {(i < 1 || showAll?.imports) && i < notifications?.imports?.result?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </div>
            ))}
            {showAll?.imports || notifications?.imports?.result?.length < 3 ? (
              <>
                {notifications?.imports?.result?.slice(2)?.map((n, i) => (
                  <div key={i}>
                    <NotificationMenu.SectionMenuItem
                      type={'imports'}
                      title={`${n?.parameters?.title ?? n?.parameters?.name ?? n?.parameters?.stacInfo?.feature?.id}`}
                      legend={n?.status === 'errored' ? n?.message : moment(n.date).format('L')}
                      status={n?.status}
                      thumbnail={n?.parameters?.thumbnail}
                      pathId={n?.path?.id}
                      handleClose={() => setMenuOpen(false)}
                    />
                    {i < notifications?.imports?.result?.length - 3 && <Divider className={classes.divider} />}
                  </div>
                ))}
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowAll((prev) => ({ ...prev, imports: true }))}
                className={classes.showMoreButton}
                style={{ marginBottom: '4px' }}
              >
                Show more
              </Button>
            )}
          </NotificationMenu.Section>
        )}

        {!loading && notifications?.downloads?.length > 0 && (
          <NotificationMenu.Section title="Available downloads">
            {notifications?.downloads?.slice(0, 2)?.map((download, i) => (
              <div key={`${download.mapName}-${download.date}`}>
                <NotificationMenu.SectionMenuItem
                  type={'download'}
                  projectType={download.type}
                  title={download.path.name}
                  legend={moment(download.date).format('L')}
                  status={download.status}
                  downloadId={download.id}
                />
                {(i < 1 || showAll?.downloads) && i < notifications?.downloads?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </div>
            ))}
            {showAll?.downloads || notifications?.downloads?.length < 3 ? (
              <>
                {notifications?.downloads?.slice(2)?.map((download, i) => (
                  <div key={`m${download.mapName}-${download.date}`}>
                    <NotificationMenu.SectionMenuItem
                      type={'download'}
                      projectType={download.type}
                      title={download.path.name}
                      legend={moment(download.date).format('L')}
                      status={download.status}
                      downloadId={download.id}
                    />
                    {i < notifications?.downloads?.length - 3 && <Divider className={classes.divider} />}
                  </div>
                ))}
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowAll((prev) => ({ ...prev, downloads: true }))}
                className={classes.showMoreButton}
                style={{ marginBottom: '4px' }}
              >
                Show more
              </Button>
            )}
          </NotificationMenu.Section>
        )}

        {!loading && notifications?.engagement?.length > 0 && false && (
          <NotificationMenu.Section title="Updates">
            {notifications?.engagement?.slice(0, 2)?.map((n, i) => (
              <div key={i}>
                <NotificationMenu.SectionMenuItem
                  type={'engagement'}
                  projectType={'message'}
                  title={`${n.aboutUser.username} viewed your layer ${n.aboutPath.name}`}
                  legend={moment(n.date).format('L')}
                />
                {(i < 1 || showAll?.engagement) && i < notifications?.engagement?.length - 1 && (
                  <Divider className={classes.divider} />
                )}
              </div>
            ))}
            {showAll?.engagement || notifications?.engagement?.length < 3 ? (
              <>
                {notifications?.engagement?.slice(2)?.map((n, i) => (
                  <div key={i}>
                    <NotificationMenu.SectionMenuItem
                      type={'engagement'}
                      projectType={'message'}
                      title={`${n.aboutUser.username} viewed your layer ${n.aboutPath.name}`}
                      legend={moment(n.date).format('L')}
                    />
                    {i < notifications?.engagement?.length - 3 && <Divider className={classes.divider} />}
                  </div>
                ))}
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => setShowAll((prev) => ({ ...prev, engagement: true }))}
                className={classes.showMoreButton}
                style={{ marginBottom: '4px' }}
              >
                Show more
              </Button>
            )}
          </NotificationMenu.Section>
        )}
      </NotificationMenu>
    </>
  );
}

Notifications.propTypes = {
  user: PropTypes.object,
};

export default Notifications;
