import { makeStyles } from 'tss-react/mui';

const useLinkStyleButtonStyles = makeStyles({ name: 'useLinkStyleButtonStyles' })((theme) => ({
  listItem: { '&, &.MuiListItem-root': { paddingRight: theme.spacing(9) } },
  secondaryAction: {
    right: theme.spacing(1),
    height: '100%',
    display: 'flex',
    cursor: 'default',
    alignItems: 'center',
  },
}));

export default useLinkStyleButtonStyles;
