import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { makeStyles } from 'tss-react/mui';
import { CircularProgress, Stepper, Step, StepLabel, StepContent, Button, Paper, Typography } from '@mui/material';

import ProjectNameStep from './ProjectNameStep';
import ProjectTypeStep from './ProjectTypeStep';
import FinishedStep from './FinishedStep';
import ApiManager from '../../ApiManager';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useAuthFuncs, useMinimalAuth } from 'Account/AuthContext';
import { useMainContext } from 'ReusableComponents';
import { usePushToDataLayer } from 'ConsentContext';

const MAX_LENGTH = 64;
const INITIAL_ERROR_STATE = { error: null, errorName: '' };

const useStyles = makeStyles({ name: 'NewProjectStepper' })((theme) => ({
  root: {
    width: '100%',
  },
  stepperContainer: {
    // padding: 24,
    paddingBottom: '20px',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(1, 2),
    flexWrap: 'wrap-reverse',
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

/* const nameToDataSource = (name) => {
  if (name === 'Sentinel-2') return 'sentinel2';
  return 'sentinel1VVVH';
}; */

function getSteps() {
  return ['Select a type', 'Choose a name', 'Upload your data!'];
}

const NewProjectStepper = ({ activeStep, setActiveStep, handleCloseNewProjectDialog, demoMode, className }) => {
  const { classes, cx } = useStyles();
  const steps = getSteps();

  const history = useHistory();
  const { currentFolderInfo, mainMode } = useCurrentFolder();
  const { addPath } = useMainContext();
  const [loading, setLoading] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [projectNameError, setProjectNameError] = useState(INITIAL_ERROR_STATE);
  const [selectedType, setSelectedType] = useState(null);
  const [mapId, setMapId] = useState(null);
  const { fetchUserInfo } = useAuthFuncs();

  const user = useMinimalAuth();

  const pushToDataLayer = usePushToDataLayer();

  const handleProjectNameChange = (e) => {
    if (e.target.value?.length === 0 || e.target.value?.length > MAX_LENGTH) {
      setProjectNameError({ error: true, errorName: '' });
    } else {
      setProjectNameError(INITIAL_ERROR_STATE);
    }

    setProjectName(e.target.value);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let body = {
      name: projectName.trim(),
    };

    if (currentFolderInfo) {
      body['parentId'] = currentFolderInfo.id;
    }

    body.type = selectedType === 'shape' ? 'vector' : selectedType === 'pointCloud' ? 'pointCloud' : 'raster';
    let res;
    try {
      res = await ApiManager.post(`/v3/path/${body.type}`, body, user);
    } catch (e) {
      setLoading(false);
      setProjectNameError({ error: e.message, name: projectName, exists: true });
      return;
    }

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;
    ApiManager.post(
      '/v3/path/' +
        res.id +
        `/${selectedType === 'shape' ? 'vector' : selectedType === 'pointCloud' ? 'pointCloud' : 'raster'}/timestamp`,
      { date: { from: today, to: today } },
      user
    ).then((r) => {
      addPath({ id: res.id, parentId: currentFolderInfo?.id, mainMode: mainMode });

      fetchUserInfo();
      setLoading(false);

      if (!!res) {
        pushToDataLayer({
          dataLayer: {
            event: 'new-project-added',
          },
        });

        setMapId(res.id);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    });
  };

  const handleGoToSettings = (e) => {
    e.preventDefault();

    setActiveStep(0);
    handleCloseNewProjectDialog();
    history.push(`${mainMode}?pathId=${mapId}`);
  };

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <ProjectTypeStep selectedType={selectedType} setSelectedType={setSelectedType} />;
        case 1:
          return (
            <ProjectNameStep
              projectName={projectName}
              handleProjectNameChange={handleProjectNameChange}
              projectNameError={projectNameError}
              MAX_LENGTH={MAX_LENGTH}
            />
          );

        case 2:
          return <FinishedStep loading={loading} />;
        default:
          return 'Unknown step';
      }
    },
    [selectedType, projectName, loading, projectNameError]
  );

  return (
    <div className={cx(classes.root, className)}>
      {user || demoMode ? (
        <>
          <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepperContainer}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <form onSubmit={activeStep === 0 ? handleNext : activeStep === 1 ? handleSubmit : handleGoToSettings}>
                    {getStepContent(index)}
                    <div className={classes.actionsContainer}>
                      {activeStep === 1 && (
                        <Button disabled={activeStep === 0} onClick={handleBack} color="inherit">
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                          (!!projectNameError.error && activeStep === 1) ||
                          (activeStep === 0 ? selectedType === null : activeStep === 1 ? projectName === '' : false)
                        }
                      >
                        {loading ? (
                          <CircularProgress style={{ height: '28px', width: '28px', color: 'inherit' }} />
                        ) : activeStep === 0 ? (
                          'Continue'
                        ) : activeStep === 1 ? (
                          'Create layer'
                        ) : (
                          'Upload files'
                        )}
                      </Button>
                    </div>
                  </form>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <Typography>All steps completed - you&apos;re finished</Typography>
              <Button onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </Paper>
          )}
        </>
      ) : (
        <CircularProgress style={{ marginLeft: 'auto' }} />
      )}
    </div>
  );
};

NewProjectStepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
  setActiveStep: PropTypes.func.isRequired,
  handleCloseNewProjectDialog: PropTypes.func.isRequired,
};

export default NewProjectStepper;
