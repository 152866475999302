import React from 'react';

import ApiUrls from 'ApiUrls';

import {
  Edit,
  EditOutlined,
  Home,
  NotInterested,
  PersonAdd,
  VerifiedUser,
  Visibility,
  VisibilityOutlined,
  VisibilityTwoTone,
} from '@mui/icons-material';

import { amber, blueGrey, deepPurple, green, lightBlue, lightGreen, red } from '@mui/material/colors';

import FileSaver from 'file-saver';

const apiUrl = process.env.REACT_APP_API_URL;

const rootNames = {
  myMaps: 'myDrive',
  shared: 'sharedWithMe',
  favorites: 'favorites',
  trash: 'trash',
};

const newAccessLevelsLong = [
  {
    key: 'noAccess',
    accessLevel: 0,
    label: 'None',
    tooltip: 'No Access',
    icon: NotInterested,
    color: null,
    public: true,
  },
  {
    key: 'view',
    accessLevel: 100,
    label: 'View',
    tooltip: 'Vectors and raster layers',
    icon: VisibilityOutlined,
    color: green[600],
    public: true,
  },
  {
    key: 'view+',
    accessLevel: 200,
    label: 'View+',
    tooltip: 'Series, messages, raw data, download',
    icon: VisibilityTwoTone,
    color: lightBlue[600],
    public: true,
  },
  {
    key: 'share',
    accessLevel: 350,
    label: 'Share',
    tooltip: 'Share layers and folders',
    icon: PersonAdd,
    color: deepPurple[600],
    public: false,
  },
  {
    key: 'edit',
    accessLevel: 550,
    label: 'Edit',
    tooltip: 'Add data to maps',
    icon: EditOutlined,
    color: amber[700],
    public: true,
  },
  {
    key: 'fullView',
    accessLevel: 600,
    label: 'Full View',
    tooltip: 'View private data as well',
    icon: Visibility,
    color: blueGrey[900],
    public: false,
  },
  {
    key: 'edit+',
    accessLevel: 800,
    label: 'Edit+',
    tooltip: 'Create layers and add timestamps',
    icon: Edit,
    color: red[600],
    public: false,
  },
  {
    key: 'admin',
    accessLevel: 1000,
    label: 'Admin',
    tooltip: 'Delete, change public access, and trash layers',
    icon: VerifiedUser,
    color: lightGreen[800],
    public: false,
  },
  {
    key: 'owner',
    accessLevel: 1000,
    label: 'Owner',
    tooltip: null,
    icon: Home,
    color: null,
    public: false,
  },
];

const newAccessLevelLongParsed = newAccessLevelsLong?.map(({ icon: Icon, ...rest }, i) => ({
  ...rest,
  icon: <Icon fontSize="inherit" />,
  // value: i,
}));

const newAccessLevels = newAccessLevelsLong
  .filter((x) => ![/*'noAccess',*/ 'owner'].includes(x.key))
  .reduce((total, { key, accessLevel }) => ({ ...total, [key]: accessLevel }), {});

const cloudUrl = ApiUrls?.find((u) => u?.url === process.env.REACT_APP_API_URL);

const ApiManager = {
  adminUserName: 'admin',
  apiUrl: apiUrl,
  cloud: cloudUrl?.cloud,
  idProvider: cloudUrl?.idProvider,
  displayName: cloudUrl.displayName,
  apiType: cloudUrl.apiType,
  newAccessLevel: newAccessLevels,
  newAccessLevelLong: newAccessLevelLongParsed,
  rootNames: rootNames,
  accessLevels: {
    viewMap: 100,
    download: 150,
    aggregatedData: 200,
    timeSeries: 210,
    viewGeoMessages: 200,
    addGeoMessages: 400,
    addGeoMessageImage: 410,
    addSeries: 415,
    addPrivateGeoMessage: 420,
    addPolygons: 500,
    submitRasterData: 515,
    addRestrictedPolygons: 525,
    viewPrivateGeoMessages: 550,
    deleteGeomessages: 600,
    alterOrDeleteCustomPolygons: 700,
    forms: 750,
    customPolygonLayers: 800,
    rerun: 850,
    userManagement: 900,
    owner: 1000,

    mapPublicLevelOne: 300, // viewGeoMessages
    mapPublicLevelTwo: 500, // addPolygons

    min: 0,
    max: 1000,
  },

  get: (url, body, user, resolve, signal, extraOptions) => {
    return apiManagerFetch('GET', url, body, user, false, resolve, signal, extraOptions);
  },

  post: (url, body, user, isDownload, resolve) => {
    return apiManagerFetch('POST', url, body, user, isDownload, resolve);
  },
  put: (url, body, user, isDownload, resolve) => {
    return apiManagerFetch('PUT', url, body, user, isDownload, resolve);
  },
  delete: (url, body, user, isDownload, resolve) => {
    return apiManagerFetch('DELETE', url, body, user, isDownload, resolve);
  },
  patch: (url, body, user, isDownload, resolve) => {
    return apiManagerFetch('PATCH', url, body, user, isDownload, resolve);
  },
  fetch: (method, url, body, user, resolve) => {
    return apiManagerFetch(method, url, body, user, false, resolve);
  },

  upload: (url, file, user, events, body, propertyName = 'data') => {
    let formData = new FormData();
    console.log(url, file, user, events, body, propertyName);
    if (body) {
      let keys = Object.keys(body);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let param;
        if (typeof body[key] === 'object') {
          param = JSON.stringify(body[key]);
        } else {
          param = body[key];
        }

        formData.append(key, param);
      }
    }

    formData.append(propertyName, file);

    let request = new XMLHttpRequest();

    // no idea why it's not working otherwise but it's not working otherwise for progress
    request.upload.addEventListener('progress', events.progress);
    Object.keys(events).forEach((event) => {
      // see above for the weird condition
      event !== 'progress' && request.addEventListener(event, events[event]);
    });

    request.open('POST', `${apiUrl}${url}?isApiManagerUpload=true`, true);

    if (user) {
      request.setRequestHeader('Authorization', `Bearer ${user.token}`);
    }

    request.send(formData);
    return request;
  },
  getBaseUrl: () => apiUrl,
};

function CustomError(status, message) {
  var error = Error.call(this, message);

  this.name = 'API Error';
  this.message = error.message;
  this.stack = error.stack;
  this.status = status;
}

CustomError.prototype = Object.create(Error.prototype);
CustomError.prototype.constructor = CustomError;

async function apiManagerFetch(method, url, body, user, isDownload, resolve = true, signal, extraOptions) {
  let headers = {};
  let urlAddition = '';

  headers['Content-Type'] = 'application/json';

  if (user) {
    headers['Authorization'] = `Bearer ${user.token}`;
  }

  if (method === 'GET' && body) {
    let stripedBody = Object.fromEntries(Object.entries(body).filter(([_, v]) => v != null));

    let keys = Object.keys(stripedBody);

    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];

      let value = stripedBody[key];

      if (typeof value === 'object') {
        stripedBody[key] = JSON.stringify(value);
      }
    }

    urlAddition = new URLSearchParams(stripedBody).toString();
    urlAddition = '?' + urlAddition;
  }

  url = `${apiUrl}${url}${urlAddition}`;
  let gottenResponse = null;
  let isText = false;
  let isJson = false;

  let options = {
    method: method,
    headers: headers,
    signal: signal,
    ...extraOptions,
  };

  if (body && method !== 'GET') {
    options.body = JSON.stringify(body);
  }

  let request = fetch(url, options)
    .then((response) => {
      gottenResponse = response;

      let contentType = response.headers.get('Content-Type');

      if (contentType) {
        isText = contentType.includes('text');
        isJson = contentType.includes('application/json');
      } else {
        isText = true;
      }

      if (isJson) {
        return response.json();
      } else if (isText) {
        return response.text();
      } else {
        return response.blob();
      }
    })
    .then((result) => {
      if (gottenResponse.status === 200) {
        if (isDownload) {
          let contentDisposition = gottenResponse.headers.get('Content-Disposition');
          let matches = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/.exec(contentDisposition);
          let fileName = matches[1];
          FileSaver.saveAs(result, fileName);
        } else {
          return result;
        }
      } else {
        if (!isText) {
          throw new CustomError(gottenResponse.status, result.message);
        } else {
          throw new CustomError(gottenResponse.status, result);
        }
      }
    });

  if (resolve === true) {
    return await request;
  } else return request;
}

export default ApiManager;
