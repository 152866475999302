import React from 'react';
import PropTypes from 'prop-types';

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

const InputDialog = ({
  agreeText = 'Confirm',
  cancelText = 'Cancel',
  className = '',
  disableAgree,
  handleClose,
  handleValueChange,
  legend,
  onClick,
  open,
  textFieldProps = {},
  title,
  value,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle className={className} title={title} sx={{ pb: 1 }} />
      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ py: 1 }}>
          {!!legend && <DialogContentText sx={{ pb: 2 }}>{legend}</DialogContentText>}
          <TextField
            autoFocus
            value={value}
            onChange={handleValueChange}
            variant="outlined"
            fullWidth
            {...textFieldProps}
          />
        </DialogContent>
        <DialogActions>
          <Button color={'inherit'} onClick={handleClose}>
            {cancelText}
          </Button>
          <Button color="primary" type="submit" disabled={disableAgree}>
            {agreeText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

InputDialog.propTypes = {
  agreeText: PropTypes.string,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  disableAgree: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  legend: PropTypes.oneOfType([(PropTypes.string, PropTypes.node)]),
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  textFieldProps: PropTypes.object,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default InputDialog;
