import React from 'react';

import PropTypes from 'prop-types';

import { Box, SvgIcon } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Skeleton from '@mui/material/Skeleton';
import { CreateNewFolder, Link, Public } from '@mui/icons-material';

import AppUtility from 'AppUtility';

const fontSize = 14;
const GAP = 6;

const useStyles = makeStyles({ name: 'SharingComponent' })((theme, _params, classes) => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: GAP,
    fontSize: fontSize,
    lineHeight: fontSize + 'px',
    whiteSpace: 'nowrap',
    [`&:hover .${classes.collapse}`]: {
      maxWidth: '100vw',
      marginLeft: 0,
    },
  },
  link: {
    color: theme.palette.info.dark,
  },
  public: {
    color: theme.palette.success.main,
  },
  private: {
    color: theme.palette.error.dark,
  },
  subscribable: {
    color: theme.palette.warning.dark,
  },
  label: { textAlign: 'left' },
  button: {
    flexGrow: 1,
    [`& .${classes.label}`]: { flexGrow: 1 },
    '& .MuiSvgIcon-root': { marginRight: theme.spacing(1) },
  },
  collapse: {
    maxWidth: 0,
    overflow: 'hidden',
    marginLeft: -GAP,
    transition: theme.transitions.create(['margin-left', 'max-width']),
  },
}));

const NoAccessHiddenIcon = ({ style }) => (
  <SvgIcon style={style}>
    <path d="M11.3256 7.99029L6.94905 3.61165C8.48229 2.59223 10.3164 2 12.296 2C17.6526 2 22 6.34951 22 11.7087C22 13.6893 21.4081 15.5243 20.3891 17.0583L18.9723 15.6408C19.6613 14.4951 20.0592 13.1456 20.0592 11.7087C20.0592 8.45631 18.0505 5.6699 15.2072 4.51456V4.91262C15.2072 5.98058 14.3338 6.85437 13.2664 6.85437H11.3256V7.99029ZM21.214 20.6311L19.8457 22L17.6429 19.7961C16.1097 20.8252 14.2756 21.4175 12.296 21.4175C6.93935 21.4175 2.59195 17.068 2.59195 11.7087C2.59195 9.72815 3.18389 7.8932 4.20281 6.35922L2 4.15534L3.36827 2.78641L21.214 20.6311ZM11.3256 17.534C10.2581 17.534 9.38476 16.6602 9.38476 15.5922V14.6214L4.73654 9.97087C4.61038 10.534 4.53275 11.1068 4.53275 11.7087C4.53275 15.6699 7.49248 18.932 11.3256 19.4078V17.534Z" />
  </SvgIcon>
);

NoAccessHiddenIcon.propTypes = {
  style: PropTypes.object,
};

export const getSharingType = (map) => {
  var type = '';

  if (!map) {
    return undefined;
  }

  if (map?.publicAccess?.hidden === true) {
    if (map?.publicAccess?.publicAccessLevel === 0) {
      type = 'private';
    } else if (map?.publicAccess?.publicAccessLevel > 0) {
      type = 'linkSharing';
    }
  } else if (map?.publicAccess?.hidden === false) {
    if (map?.publicAccess?.publicAccessLevel === 0) {
      type = 'subscribable';
    } else if (map?.publicAccess?.publicAccessLevel > 0) {
      type = 'public';
    }
  }

  return type;
};

const SharingComponent = ({
  map,
  className: userClassName = '',
  skeleton,
  style: userStyles = {},
  disabled,
  type,
  component = 'div',
  labelProps: PropsLabel = {},
  isButton,
  collapse = false,
}) => {
  const { classes, cx } = useStyles();
  const { className: labelClass, ...labelProps } = { ...PropsLabel };

  let icon, className, label;

  if (!type && !!map) {
    type = getSharingType(map);
  }

  if (!!type && !skeleton) {
    if (type === 'private') {
      icon = <NoAccessHiddenIcon style={{ fontSize: 'inherit' }} />;
      className = classes.private;
      label = 'Private';
    } else if (type === 'linkSharing') {
      icon = <Link style={{ fontSize: 'inherit' }} />;
      className = classes.link;
      label = 'Link Sharing';
    } else if (type === 'subscribable') {
      icon = <CreateNewFolder style={{ fontSize: 'inherit' }} />;
      className = classes.subscribable;
      label = 'Include in catalog';
    } else if (type === 'public') {
      icon = <Public style={{ fontSize: 'inherit' }} />;
      className = classes.public;
      label = 'Public';
    }
  }

  if ((!icon && !label) || skeleton) {
    icon = <Skeleton variant="circular" width={fontSize} height={fontSize} />;
    label = <Skeleton variant="text" width={52} height={fontSize} />;
  }

  var styling = { ...userStyles };
  if (disabled === true) {
    styling.opacity = 0.5;
  }

  return (
    <Box
      component={component}
      className={cx(classes.root, isButton && classes.button, className, userClassName)}
      style={styling}
    >
      {icon}
      <Box className={cx(classes.label, labelClass, collapse && classes.collapse)} {...labelProps}>
        {label}
      </Box>
    </Box>
  );
};

const testSame = (prevProps, nextProps) => {
  if (
    prevProps?.skeleton !== nextProps?.skeleton ||
    prevProps?.map?.id !== nextProps?.map?.id ||
    prevProps?.map?.publicAccess?.hidden !== nextProps?.map?.publicAccess?.hidden ||
    prevProps?.map?.publicAccess?.publicAccessLevel !== nextProps?.map?.publicAccess?.publicAccessLevel ||
    prevProps?.disabled !== nextProps?.disabled ||
    prevProps?.type !== nextProps?.type ||
    prevProps?.className !== nextProps?.className
  ) {
    /* console.log({
      ...(prevProps?.skeleton !== nextProps?.skeleton ? { skeleton: [prevProps?.skeleton, nextProps?.skeleton] } : {}),
      ...(prevProps?.map?.id !== nextProps?.map?.id ? { id: [prevProps?.map?.id, nextProps?.map?.id] } : {}),
      // mapHidden: [prevProps?.map?.hidden, nextProps?.map?.hidden],
      // mapPublicAccessLevel: [prevProps?.map?.publicAccessLevel, nextProps?.map?.publicAccessLevel],
      ...(prevProps?.map?.publicAccess?.hidden !== nextProps?.map?.publicAccess?.hidden
        ? { hidden: [prevProps?.map?.publicAccess?.hidden, nextProps?.map?.publicAccess?.hidden] }
        : {}),
      ...(prevProps?.map?.publicAccess?.publicAccessLevel !== nextProps?.map?.publicAccess?.publicAccessLevel
        ? {
            publicAccess: [
              prevProps?.map?.publicAccess?.publicAccessLevel,
              nextProps?.map?.publicAccess?.publicAccessLevel,
            ],
          }
        : {}),
      ...(prevProps?.disabled !== nextProps?.disabled ? { disabled: [prevProps?.disabled, nextProps?.disabled] } : {}),
      ...(prevProps?.type !== nextProps?.type ? { type: [prevProps?.type, nextProps?.type] } : {}),
      ...(prevProps?.className !== nextProps?.className
        ? { className: [prevProps?.className, nextProps?.className] }
        : {}),
    }); */
    return false;
  }
  return true;
};

export default React.memo(SharingComponent, testSame);

SharingComponent.propTypes = {
  map: PropTypes.shape({
    id: PropTypes.string.isRequired,
    publicAccess: PropTypes.shape({
      hidden: PropTypes.bool.isRequired,
      publicAccessLevel: PropTypes.number.isRequired,
    }).isRequired,
  }),
  isButton: PropTypes.bool,
  disabled: PropTypes.bool,
  skeleton: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOf(AppUtility.sharingTypes),
};
