import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { useHistory } from 'react-router';

const RedirectDialog = ({ open, handleClose }) => {
  const history = useHistory();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'You are not in a location where you can create a new layer'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You can create layers in your Drive, either in your {'My Drive'} section or in a shared folder where you have
          admin rights.
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          Do you want to go to your Drive to create a new layer?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'inherit'}>
          Cancel
        </Button>
        <Button onClick={() => history.push('/drive/me')} color="primary" autoFocus>
          Go to Drive
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectDialog;
