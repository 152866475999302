import React, { useContext } from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import uploadContext from './uploadContext';

const useStyles = makeStyles()((theme) => ({
  container: {
    backgroundColor: theme.palette.grey[100],
    minHeight: '30px',
    fontSize: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '18px',
    paddingRight: '8px',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  cancelButton: {
    color: '#18548C',
    padding: 0,
    fontSize: '0.9rem',
  },
  title: {
    fontSize: '0.9rem',
  },
}));

const UploadSubHeader = () => {
  const { classes } = useStyles();
  const { estimatedTime } = useContext(uploadContext);

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>{estimatedTime} min left...</Typography>
      <Button className={classes.cancelButton}>Cancel</Button>
    </div>
  );
};

export default UploadSubHeader;
