import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const InputDialog = ({
  open,
  handleClose,
  value,
  handleValueChange,
  title,
  legend,
  onClick,
  cancelText = 'Cancel',
  agreeText = 'Confirm',
  textFieldProps = {},
  disabled,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    onClick(e);
  };
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>{legend}</DialogContentText>
          <TextField
            variant="standard"
            autoFocus
            value={value}
            onChange={handleValueChange}
            fullWidth
            {...textFieldProps} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelText}
          </Button>
          <Button color="primary" type="submit" disabled={disabled}>
            {agreeText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

InputDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  agreeText: PropTypes.string,
  textFieldProps: PropTypes.object,
};

export default InputDialog;
