import React, { useCallback, useMemo, useState } from 'react';

import { Button, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import SharingComponent, { getSharingType } from './SharingComponent';

import AppUtility from 'AppUtility';
import ApiManager from 'ApiManager';

const useStyles = makeStyles({ name: 'SharingButton' })((theme) => ({
  root: {
    background: 'white',
    gap: theme.spacing(1.25),
    '& .MuiButton-endIcon': {
      marginLeft: 0,
      paddingLeft: theme.spacing(0.5),
      borderLeft: `1px ${theme.palette.divider} solid`,
    },
  },
  menuItem: { minHeight: theme.spacing(6) },
  sharing: { flexGrow: 1 },
  sharingItem: { display: 'flex', '& .MuiTypography-root': { lineHeight: 1.25 } },
  label: { flexGrow: 1 },
}));

const SharingButton = ({ layer, skeleton, disabled, onChange = () => {} }) => {
  const [open, setOpen] = useState(false);
  const { classes: styles } = useStyles();

  const allowedSharing = useMemo(
    () =>
      [
        AppUtility.sharingTypesLong.public.name,
        layer?.publicAccess.minPublicAccessLevel === 0 && AppUtility.sharingTypesLong.subscribable.name,
        layer?.publicAccess.canHide === true && AppUtility.sharingTypesLong.linkSharing.name,
        layer?.publicAccess.minPublicAccessLevel === 0 &&
          layer?.publicAccess.canHide === true &&
          AppUtility.sharingTypesLong.private.name,
      ].filter((x) => !!x),
    [layer]
  );

  const openMenu = useCallback((e) => {
    e?.stopPropagation();
    setOpen(e?.currentTarget);
  }, []);
  const closeMenu = useCallback(() => setOpen(false), []);

  const changeSharingStatus = (status) => {
    closeMenu();

    onChange({
      hidden: !(status === 'public' || status === 'subscribable'),
      publicAccessLevel:
        status === 'public' || status === 'linkSharing'
          ? Math.max(ApiManager.newAccessLevel['view'], layer?.publicAccess.minPublicAccessLevel)
          : 0,
    });
  };

  return (
    <>
      <Button
        className={styles.root}
        onClick={openMenu}
        endIcon={<ArrowDropDown />}
        disabled={skeleton || disabled}
        fullWidth
        color="inherit"
      >
        <SharingComponent type={getSharingType(layer)} /* className={styles.sharing} */ isButton />
      </Button>
      <Menu open={!!open} onClose={closeMenu} anchorEl={open} onClick={(e) => e?.stopPropagation()}>
        {AppUtility.sharingTypes.map((item) => (
          <MenuItem
            className={styles.menuItem}
            key={item}
            selected={item === getSharingType(layer)}
            disabled={!allowedSharing.includes(item)}
            onClick={() => changeSharingStatus(item)}
          >
            <ListItemText
              disableTypography
              primary={
                <SharingComponent
                  className={styles.sharingItem}
                  type={item}
                  labelProps={{ component: Typography, variant: 'button' }}
                />
              }
              secondary={
                !allowedSharing.includes(item) && (
                  <Typography variant="caption">Disabled by inferred sharing status</Typography>
                )
              }
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SharingButton;
