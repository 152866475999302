import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Container /* , Typography */ } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import UploadSettings from './UploadSettings/UploadSettings';
import UploadDropZone from './UploadDropzone';
import FilesManagementContext from '../FilesManagementContext';
import ApiManager from 'ApiManager';

const useUploadStyles = makeStyles({ name: 'UploadPage' })((theme) => ({
  uploadPageContainer: {},
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  /*notificationContent: {
    textAlign: 'center',
  },
  notificationLink: {
    textDecoration: 'underline',
    color: 'inherit',
  }, */
}));

const formatsForceEspg = ['csv', 'las', 'mesh', 'obj', 'laz', 'xyz', 'mesh', 'connection', 'jpg', 'jgw'];

// validate that the format is allowed and if so what it should be uploaded as
export const validateFileFormat = (format, fileFormats) => {
  let error = null;
  let uploadAs = null;

  // file out error or uploadAs according to `fileFormats`
  fileFormats.forEach((category) => {
    if (category.formats.includes(format)) {
      if (category.type === 'error') {
        error = category.errorMessage;
        return;
      }

      uploadAs = !!category.uploadAs ? category.uploadAs : format;
      return;
    }
  });

  // if both are still `null`, it means the format wasn't recognized
  if (error === null && uploadAs === null) {
    const possibleFormats = fileFormats
      .flatMap((category) => (category.type === 'success' ? category.formats : []))
      .map((format) => `.${format}`);

    error = `Extension .${format} not supported, allowed formats are: ${possibleFormats
      .slice(0, -1)
      .join(', ')} and ${possibleFormats.at(-1)}`;
  }

  return { error, uploadAs };
};

const UploadPage = ({ type, uploadFormats, captureId, setUploadOpen, setAutoProcessOpen }) => {
  const { classes } = useUploadStyles();

  const { map, user } = useContext(FilesManagementContext);
  // const mapId = map.id;
  const [uploads, setUploads] = useState(null);
  const [files, setFiles] = useState([]);

  const getAllUploads = useMemo(
    () => async () => {
      let nextPage = null;
      let first = true;
      let newFiles = [];
      if (!captureId) {
        return;
      }
      while (first || !!nextPage) {
        first = false;
        let res;
        try {
          res = await ApiManager.get(
            `/v3/path/${map.id}/${
              map.type === 'map' ? 'raster' : map.type === 'pointCloud' ? 'pointCloud' : 'vector'
            }/timestamp/${captureId}/file`,
            { pageStart: nextPage },
            user
          );
        } catch {
          res = { result: [] };
        }

        newFiles = [...newFiles, ...res.result];
        setUploads(newFiles);
        nextPage = res.nextPageStart;
      }
    },
    [map.id, user, map.type, captureId]
  );

  useEffect(() => {
    getAllUploads();
  }, [getAllUploads]);

  // ok so 2 issues with this:
  // 1. it's epsg, not espg
  // 2. this varialbe is doing the opposite of what the name would suggest it does
  const [autoDetectEspg, setAutoDetectEspg] = useState({ active: false, epsg: null });
  const [noDataValue, handleNoDataValue] = useState(null);

  const [fastUpload, setFastUpload] = useState(true);
  const [downsample, setDownsample] = useState(true);
  const [dateColumns, setDateColumns] = useState(['']);
  const [datePatterns, setDatePatterns] = useState(['']);

  const uploadedFileFormats = useMemo(() => files.map((file) => file.name.split('.').at(-1)), [files]);

  const handleAutoDetectEspgChange = useCallback(
    (value) => {
      if (uploadedFileFormats.find((format) => formatsForceEspg.includes(format)) && value === null) return;
      setAutoDetectEspg({ ...autoDetectEspg, ...value });
    },
    [uploadedFileFormats, autoDetectEspg]
  );

  //TODO prevent racing and set upload to null when refreshing to prevent needing to wait a long time to see an effect when for example deleting everything
  //danielvandermaas committed on Jul 12

  useEffect(() => {
    if (uploadedFileFormats.find((format) => format === 'csv') && !autoDetectEspg.epsg && !autoDetectEspg.csvForce) {
      console.log('THE ERROR IS HERE');
      console.log('x');
      handleAutoDetectEspgChange({ active: true, epsg: 4326, csvForce: true });
    } else {
      if (uploadedFileFormats.find((format) => formatsForceEspg.includes(format)) && !autoDetectEspg.active) {
        handleAutoDetectEspgChange({ active: true, csvForce: false });
      }
    }
  }, [handleAutoDetectEspgChange, uploadedFileFormats, autoDetectEspg]);

  /* const EPSGNotification = useMemo(
    () => (
      <Typography className={classes.notificationContent}>
        Something went wrong with the EPSG code of your file. For more information on that issue, visit{' '}
        <a
          className={classes.notificationLink}
          href="https://docs.ellipsis-drive.com/troubleshooting/trouble-with-epsg-or-crs"
          target="_blank"
          rel="noreferrer"
        >
          this Help Center article.
        </a>
      </Typography>
    ),
    [classes.notificationContent, classes.notificationLink]
  ); */

  return (
    <Container className={classes.uploadPageContainer}>
      <>
        <UploadSettings
          uploadedFileFormats={uploadedFileFormats}
          formatsForceEspg={formatsForceEspg}
          noDataValue={noDataValue}
          handleNoDataValue={handleNoDataValue}
          setFastUpload={setFastUpload}
          fastUpload={fastUpload}
          downsample={downsample}
          setDownsample={setDownsample}
          autoDetectEspg={autoDetectEspg}
          handleAutoDetectEspgChange={handleAutoDetectEspgChange}
          type={type}
          dateColumns={dateColumns}
          setDateColumns={setDateColumns}
          datePatterns={datePatterns}
          setDatePatterns={setDatePatterns}
        />
        <UploadDropZone
          autoDetectEspg={autoDetectEspg}
          captureId={captureId}
          noDataValue={noDataValue}
          type={type}
          uploads={uploads}
          files={files}
          setFiles={setFiles}
          fastUpload={fastUpload}
          setUploadOpen={setUploadOpen}
          setAutoProcessOpen={setAutoProcessOpen}
          downsample={downsample}
          dateColumns={dateColumns}
          datePatterns={datePatterns}
          uploadFormats={uploadFormats}
        />
      </>
    </Container>
  );
};

UploadPage.propTypes = {
  titleSection: PropTypes.node,
  fileFormats: PropTypes.array,
  type: PropTypes.string,
};

export default UploadPage;
