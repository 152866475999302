import React, { useContext } from 'react';
import { Typography, Paper, List, ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import FilesManagementContext from 'FilesManagement/FilesManagementContext';
import { useMinimalAuth } from 'hooks';
import { submitSettingsToAPI } from 'MapManagement/utils';

const useStyles = makeStyles()((theme, _params, classes) => ({
  section: {
    marginTop: theme.spacing(3),
  },
  interpText: {},
  cardContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '20px',
  },
  card: {
    width: '300px',
    cursor: 'pointer',
    padding: '16px',
    boxSizing: 'border-box',
    borderWidth: '1px',
    [`&.${classes.selected}`]: {
      borderColor: theme.palette.primary.main,
      backgroundColor: `${theme.palette.primary.main} + 09`,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} + 04`,
    },
  },
  cardTitle: {},
  cardSubtitle: {},
  selected: {},
}));

const RasterSettings = () => {
  const { classes, cx } = useStyles();
  const user = useMinimalAuth();
  const { map, onOpenSnackbar, onSelectMap } = useContext(FilesManagementContext);

  const handleInterpolationChange = async (value, type) => {
    let body = {};
    body[type] = value;
    await submitSettingsToAPI('PATCH', `/v3/path/${map.id}/raster`, body, user, null, onOpenSnackbar, () => {
      onSelectMap();
    });
  };

  return (
    <section className={classes.section}>
      <Typography className={classes.interpText} variant="subtitle1" gutterBottom>{`Interpolation method`}</Typography>
      <div className={classes.cardContainer}>
        <List component="nav" aria-label="main mailbox folders">
          <ListItemButton
            selected={map.interpolationMethod === 'autoDetect'}
            onClick={(event) => {
              handleInterpolationChange('autoDetect', 'interpolation');
            }}
          >
            <ListItemText primary="Auto detect" secondary="Let the server pick interpolation based on data" />
          </ListItemButton>
          <ListItemButton
            selected={map.interpolationMethod === 'linear'}
            onClick={(event) => {
              handleInterpolationChange('linear', 'interpolation');
            }}
          >
            <ListItemText primary="Linear" secondary="Recommended for imagery" />
          </ListItemButton>
          <ListItemButton
            selected={map.interpolationMethod === 'nearest'}
            onClick={(event) => {
              handleInterpolationChange('nearest', 'interpolation');
            }}
          >
            <ListItemText primary="Nearest neighbour" secondary="Required for rasters containing classifications" />
          </ListItemButton>
        </List>
      </div>
    </section>
  );
};

export default RasterSettings;
