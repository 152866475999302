import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles({ name: 'DateRangePicker' })((theme) => ({
  datePickerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  datePickerInput: {
    textAlign: 'center',
    cursor: 'pointer',
    '&.Mui-disabled': { padding: 0 },
  },
  divider: { height: '1px' },
  datePickerInputRoot: {
    '&:before': {
      opacity: '0',
    },
    '&:after': {
      opacity: '0',
    },
  },
}));

const DateRangePicker = ({
  startDate,
  handleStartDateChange,
  endDate,
  handleEndDateChange,
  disabled = false,
  disableFuture = true,
  className = '',
  editMode,
}) => {
  const { classes, cx } = useStyles({ disabled });

  return (
    <div className={cx(classes.datePickerContainer, className)}>
      <Box display="flex" alignItems={'center'} justifyContent="space-between" gap={1}>
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          value={moment(startDate)}
          maxDate={disabled ? moment('2300-01-01') : moment(endDate)}
          onChange={handleStartDateChange}
          disabled={disabled}
        />
      </Box>
      <Box display="flex" alignItems={'center'} justifyContent="space-between" gap={3}>
        <DatePicker
          slotProps={{ textField: { size: 'small' } }}
          disableFuture={disableFuture && !disabled}
          minDate={disabled ? undefined : moment(startDate)}
          value={moment(endDate)}
          onChange={handleEndDateChange}
          disabled={disabled}
        />
      </Box>
    </div>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  handleStartDateChange: PropTypes.func,
  endDate: PropTypes.string.isRequired,
  handleEndDateChange: PropTypes.func,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  className: PropTypes.string,
};

export default DateRangePicker;
