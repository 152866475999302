import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  title: {},
  subTitle: {},
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '10px',
  },
  textFieldContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: theme.spacing(1, 0),
  },
  textFieldLabel: {},
  textField: {
    width: '100%',
  },
  sendEmailButton: {
    margin: '12px 0',
    width: '100%',
  },
  forgotPasswordContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 0 15px 0',
    marginBottom: '6px',
  },
  Link: {},
  signUpContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
  },
  signUpText: {
    paddingRight: '5px',
  },
  errorContainer: {
    width: '100%',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.error.dark}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '10px ',
    boxSizing: 'border-box',
  },
  errorText: {
    color: theme.palette.error.dark,
    textAlign: 'left',
  },
  successContainer: {
    width: '100%',
    borderRadius: '6px',
    border: `1px solid ${theme.palette.success.main}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    marginTop: '10px',
    boxSizing: 'border-box',
  },
  successText: {
    color: theme.palette.success.main,
  },
}));

export default useStyles;
