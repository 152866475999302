import React from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

function CircularProgressWithLabel({ typographyStyles, value, ...otherProps }) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} {...otherProps} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary" style={typographyStyles}>{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;
