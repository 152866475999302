import React, { useContext } from 'react';

import { Typography, IconButton, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Close, ExpandMore } from '@mui/icons-material';

import uploadContext from './uploadContext';
import { useUploadLogic } from './useUploadLogic';

const useStyles = makeStyles({ name: 'UploadHeader' })((theme, { showAll }, classes) => ({
  container: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: showAll ? '0px' : '4px',
    borderBottomRightRadius: showAll ? '0px' : '4px',
    padding: theme.spacing(1, 2),
    boxSizing: 'border-box',
    gap: theme.spacing(3),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
  },
  title: {},
  divider: {},
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  iconButtons: {},
  expandButton: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create('transform', { duration: theme.transitions.duration.shortest }),
    [`&.${classes.expanded}`]: {
      transform: 'rotate(0deg)',
    },
  },
  expanded: {},
}));

const UploadHeader = () => {
  const { uploads, showAll, toggleShowAll } = useContext(uploadContext);
  const { classes } = useStyles({ showAll });
  const { doHideDialog, setUploads } = useUploadLogic();

  const uploadNumber = uploads.reduce((acc, curr) => (typeof curr.progressStatus === 'number' ? acc + 1 : acc), 0);
  const successNumber = uploads.reduce((acc, curr) => (curr.progressStatus === 'success' ? acc + 1 : acc), 0);
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Stack>
          <Typography variant="body2">Uploading</Typography>
          <Typography>{uploadNumber} items</Typography>
        </Stack>
        {successNumber > 0 && (
          <>
            <span className={classes.divider}>|</span>
            <Stack>
              <Typography variant="body2">Successful</Typography>
              <Typography>{successNumber} uploads</Typography>
            </Stack>
          </>
        )}
      </div>
      <div className={classes.buttonGroup}>
        <IconButton
          className={`${classes.iconButtons} ${classes.expandButton} ${showAll ? classes.expanded : ''}`}
          color="inherit"
          onClick={toggleShowAll}
          size="small"
        >
          <ExpandMore />
        </IconButton>
        <IconButton
          disabled={
            uploads.filter(
              (u) => u.progressStatus !== 'success' && u.progressStatus !== 'error' && u.progressStatus !== 'skipped'
            ).length > 0
          }
          className={classes.iconButtons}
          color="inherit"
          onClick={() => {
            doHideDialog();
            setUploads([]);
          }}
          size="small"
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
};

export default UploadHeader;
