import React from 'react';

import { Typography, ButtonBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import SidebarSectionSubs from './SidebarSectionSub';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: '16px 16px',
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${theme.palette.divider}`,
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    // to make the bar have the right curvature
    '&:last-child:before': {
      borderBottomLeftRadius: '3px',
    },
    '&:first-child:before': {
      borderTopLeftRadius: '3px',
    },
  },
  selected: {
    '&:before': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '4px',
      content: '""',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const SidebarSection = ({ tab, useSidebarSectionLink, rootPath, className = '' }) => {
  const { classes } = useStyles();
  const history = useHistory();

  const { changeMainMode, mainMode } = useCurrentFolder();

  const selected = history.location.pathname.split('/')[2] === tab.path;

  const handleClick = () => {
    if (mainMode) {
      changeMainMode(`${rootPath}/${tab.path}`);
    } else {
      history.push(`${rootPath}/${tab.path}`);
    }
  };

  return (
    <ButtonBase className={`${classes.root} ${selected && classes.selected} ${className}`} onClick={handleClick}>
      <Typography>{tab.name}</Typography>
      {tab.type === 'subs' && (
        <SidebarSectionSubs subs={tab.subs} rootPath="/settings" useSidebarSectionLink={useSidebarSectionLink} />
      )}
    </ButtonBase>
  );
};

SidebarSection.propTypes = {
  tab: PropTypes.shape({
    path: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    subs: PropTypes.array,
  }),
  useSidebarSectionLink: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SidebarSection;
