import React, { useMemo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';

// different styling method (no makeStyles) because copy paste and I'm a lazy bastard
const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#ABABAB',
  borderStyle: 'dashed',
  backgroundColor: '#F0F0F0',
  color: '#919191',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  width: 'auto',
  boxSizing: 'border-box',
  marginBottom: '14px',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const DropZoneArea = ({ onChange, className = '', style: userStyles = {} }) => {
  const onDrop = useCallback(onChange, [onChange]);
  const isMobile = useMediaQuery(`(max-width: 1000px)`);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...userStyles,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [userStyles, isDragActive, isDragAccept, isDragReject]
  );
  return (
    <div {...getRootProps({ style })} className={className}>
      <input {...getInputProps()} />
      {!isMobile ? <p>Drag and drop some files here, or click to select files</p> : <p>Click to select files</p>}
    </div>
  );
};

DropZoneArea.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default DropZoneArea;
