import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function useQueryParams() {
  const location = useLocation();

  const queryParameters = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    let params = {};
    for (let p of searchParams) {
      params[p[0]] = p[1];
    }
    return params;
  }, [location.search]);

  return queryParameters;
}

export default useQueryParams;
