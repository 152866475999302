import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  // DialogTitle,
} from '@mui/material';
import DialogTitle from 'ReusableComponents/DialogTitle/DialogTitle';

const ConfirmationDialog = ({
  open,
  handleClose,
  title,
  legend,
  onClick,
  loading,
  cancelText = 'cancel',
  agreeText = 'confirm',
  style = {},
}) => {
  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={style}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{legend}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color={'inherit'}>
          {cancelText}
        </Button>
        {!loading ? (
          <Button onClick={onClick} color="primary" autoFocus>
            {agreeText}
          </Button>
        ) : (
          <CircularProgress />
        )}
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  legend: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  cancelText: PropTypes.string,
  agreeText: PropTypes.string,
  style: PropTypes.object,
};

export default ConfirmationDialog;
