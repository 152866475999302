import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';

import marks from './AccessRightsLevels';
import StyledSlider from './StyledSlider';

const useStyles = makeStyles()((theme) => ({
  root: {
    marginBottom: '30px',
    // padding: '18px 24px !important',
  },
  title: {
    paddingBottom: '4px',
  },
  subtitle: {
    paddingTop: '0px',
    fontSize: '1rem',
  },
  slider: {
    height: '440px !important',
  },
  actions: {
    paddingRight: '24px',
  },
}));

const SetAccessRightsDialog = ({ open, handleClose, title, subtitle, sliderValue, setSliderValue, onSubmit }) => {
  const { classes } = useStyles();
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogTitle classes={{ root: classes.title }}>{title}</DialogTitle>
      <DialogTitle classes={{ root: classes.subtitle }}>{subtitle}</DialogTitle>
      <DialogContent className={classes.root}>
        <StyledSlider
          orientation="vertical"
          step={null}
          marks={marks}
          min={0}
          max={marks.length - 1}
          track="inverted"
          className={classes.slider}
          value={sliderValue}
          onChange={(_, v) => setSliderValue(v)}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button autoFocus onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSubmit} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SetAccessRightsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  sliderValue: PropTypes.number.isRequired,
  setSliderValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SetAccessRightsDialog;
