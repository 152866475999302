import React from 'react';

import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({
  name: 'LinkStyleButton',
})((theme, _params, classes) => ({
  root: {
    minWidth: theme.spacing(6),
    position: 'relative',
    padding: theme.spacing(1, 0.5),
    marginLeft: -5,
    lineHeight: 1,
    '&:after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      height: 1,
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      maxWidth: 0,
      bottom: theme.spacing(0.5),
      opacity: 0,
      transition: theme.transitions.create(['opacity', 'max-width']),
    },
    [`&:hover:after, &.${classes.active}:after`]: {
      opacity: 1,
      maxWidth: '75%',
    },
    [`&.${classes.active}:hover:after`]: {
      maxWidth: '95%',
    },
  },
  active: {},
}));

const LinkStyleButton = React.forwardRef(
  ({ active, label, action, onClick, className, children, color, sx = {}, ...props }, ref) => {
    const { classes: styles, cx } = useStyles();

    return (
      <Button
        className={cx(styles.root, active && styles.active, className)}
        size="small"
        onClick={action ?? onClick}
        ref={ref}
        sx={{
          color,
          '&&:after': { backgroundColor: color ?? 'primary.main' },
          ...sx,
        }}
        color={color ? 'inherit' : undefined}
        {...props}
      >
        {children ?? label}
      </Button>
    );
  }
);

LinkStyleButton.displayName = 'LinkStyleButton';
export default LinkStyleButton;
