import React from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';

const RotatableArrow = ({ in: on }) => (
  <KeyboardArrowDown
    sx={(theme) => ({
      transition: theme.transitions.create('transform'),
      transform: 'rotate(0deg)',
      ...(on ? { transform: 'rotate(-180deg)' } : {}),
    })}
  />
);

export default RotatableArrow;
