import React from 'react';

import { Typography, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { convertBytesToMbsOrKbs } from '../utils';
import { AutoSizer, List } from 'react-virtualized';
import { validateFileFormat } from '../UploadPage';

const useStyles = makeStyles()((theme, { showBorder }) => ({
  container: {
    width: '100%',

    border: showBorder ? `1px solid ${theme.palette.divider}` : '',
    borderRadius: '4px',
  },
}));

const UploadPreview = ({ files, removeFile, uploadFormats }) => {
  const { classes } = useStyles({ showBorder: files?.length !== 0 });
  const rowRenderer = ({ key, index, style }) => {
    const file = files[index];
    return (
      <div key={key} style={style}>
        <FileCard
          fileName={file.name}
          fileSize={convertBytesToMbsOrKbs(file.size)}
          index={index + 1}
          key={file.path}
          onClick={() => removeFile(file.name)}
          lastChild={index === files.length - 1}
          uploadFormats={uploadFormats}
        />
      </div>
    );
  };

  return (
    <div className={classes.container} style={{ height: `${files.length * 80}px`, maxHeight: '400px' }}>
      <AutoSizer>
        {({ height, width }) => (
          <List height={height} rowCount={files.length} rowHeight={80} rowRenderer={rowRenderer} width={width} />
        )}
      </AutoSizer>
    </div>
  );
};

const useFileCardStyles = makeStyles()((theme, { lastChild }) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto 53px',
    alignItems: 'center',
    padding: '5px 12px',
    width: '100%',
    gridGap: '14px',
    height: '80px',
    boxSizing: 'border-box',
    borderBottom: lastChild ? '' : `1px solid ${theme.palette.divider}`,
  },
  fileName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '&:hover': {
      overflow: 'visible',
      backgroundColor: 'white',
      boxShadow: theme.shadows[1],
      borderRadius: '1px',
      whiteSpace: 'normal',
      wordBreak: 'break-all',
    },
  },
  indexText: {
    minWidth: '25px',
  },
  fileNameContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorText: {
    fontSize: '0.9rem',
    color: theme.palette.error.main,
  },
}));

const FileCard = ({ fileName, fileSize, index, onClick, lastChild, uploadFormats }) => {
  const { classes } = useFileCardStyles({ lastChild });

  const { error } = validateFileFormat(fileName.split('.').at(-1), uploadFormats);

  return (
    <div className={classes.container}>
      <Typography className={classes.indexText}>{index}</Typography>
      <div className={classes.fileNameContainer}>
        <Typography className={classes.fileName}>{fileName}</Typography>
        {error ? <Typography className={classes.errorText}>{error}</Typography> : null}
      </div>
      <Typography>{fileSize}</Typography>
      <IconButton onClick={onClick}>
        <Delete />
      </IconButton>
    </div>
  );
};

FileCard.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UploadPreview;
