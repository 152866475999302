import React, { useState, useRef } from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Check, Close, OpenInNew } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import ProjectTypeIcon from '../../../../ReusableComponents/ProjectTypeIcon';
import { ConfirmationDialog } from '../../../../MapManagement/core';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
// import VersionUtility from 'VersionUtility';
import { parseSize } from 'Storage/BalanceUtility';
import { useAuthFuncs, useAuth } from 'hooks';
import { downloadFile } from 'Drive/itemUtils';
import ApiManager from 'ApiManager';
import { useMainContext } from 'ReusableComponents';
import CustomAvatar from 'ReusableComponents/CustomAvatar';

const useStyles = makeStyles()((theme, { type }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '4px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 16px',
  },

  projectTypeIcon: {
    height: '40px',
    width: '40px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginLeft: '18px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight: '6px',
  },
  title: {
    fontSize: '1rem',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  legend: {
    color: type === 'download' ? theme.palette.success.main : theme.palette.text.secondary,
    fontSize: '0.8rem',
    textTransform: type === 'download' ? 'capitalize' : 'none',
  },
  value: {
    whiteSpace: 'nowrap',
    fontSize: '0.975rem',
  },
  ownerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '4px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '8px',
    padding: '0 3px',
  },
  button: {
    padding: '0 12px',
    '&:first-child': {
      marginRight: '12px',
    },
  },
  declineButton: {
    color: theme.palette.text.secondary,
  },
}));

const SectionMenuItemInvite = ({ projectType, title, invitedByUser, value, map, handleClose }) => {
  const { classes, cx } = useStyles({ type: 'invite' });
  const history = useHistory();
  const user = useAuth();
  const { /* addPath, */ setViewerKey, setDriveContentKey } = useMainContext();
  const { handleAccept } = useCurrentFolder();
  // const { currentFolderInfo, onToLocationClick, getPathInfo } = useCurrentFolder();

  const [storageExceeded, setStorageExceeded] = useState(false);

  const [inviteStatus, setInviteStatus] = useState('pending');
  const [doubleName, setDoubleName] = useState(false);

  const [dialog, setDialog] = useState({ open: false });
  const { fetchUserInfo } = useAuthFuncs();
  const hassubmitted = useRef(false);
  const onSubmit = async (accept) => {
    if (hassubmitted.current) return;

    hassubmitted.current = true;

    console.log('submittin');
    setDialog({ open: false });

    if (accept) {
      if (user.plan.maxStorage < user.storage + map.storageSize) {
        setStorageExceeded(true);
        return;
      }
    }

    let res;
    try {
      res = await ApiManager.post(`/v3/path/${map.id}/transfer/accept`, { accept }, user);
    } catch (e) {
      setDoubleName(true);
    }

    if (!!res) {
      if (accept) {
        handleAccept();
        setViewerKey();
        setDriveContentKey();
        setInviteStatus(res.id);
        fetchUserInfo();
      } else {
        setInviteStatus('declined');
      }
    }
  };

  const handleButtonClick = () => {
    let redirectLocation;
    if (projectType === 'bookmark') {
      redirectLocation = `/view?pathId=${inviteStatus}`;
    } else {
      redirectLocation = `/drive/me?pathId=${inviteStatus}`;
    }

    const currentLocation = `${history.location.pathname}${history.location.search}`;
    if (redirectLocation && currentLocation !== redirectLocation) {
      history.push(redirectLocation);
    }

    handleClose();
  };

  const noBalance = dialog.type === 'accept' && user.maxNegative < user.coins - dialog.amount;

  return (
    <>
      <div className={classes.container}>
        <ProjectTypeIcon type={projectType} className={classes.projectTypeIcon} />
        <div className={classes.textContainer}>
          <Typography className={classes.title}>{map.name}</Typography>
          <div className={classes.ownerContainer}>
            <Typography className={classes.legend}>Invited by:</Typography>

            <CustomAvatar sx={{ ml: 0.5 }} user={invitedByUser} size={15} />
            <Typography className={classes.legend} style={{ marginLeft: '3px' }}>
              {invitedByUser.username}
            </Typography>
          </div>
        </div>
        <Typography className={classes.value}>{parseSize({ value: map.storageSize })}</Typography>
      </div>
      <div className={classes.buttonContainer}>
        {inviteStatus === 'pending' ? (
          <>
            <Button
              variant="text"
              color="primary"
              className={classes.button}
              onClick={
                value !== 'Free' ? () => setDialog({ open: true, type: 'accept', amount: value }) : () => onSubmit(true)
              }
              startIcon={<Check />}
            >
              Accept
            </Button>
            <Button
              variant="text"
              className={cx(classes.declineButton, classes.button)}
              startIcon={<Close />}
              onClick={() => {
                console.log('declining');
                setDialog({ open: true, type: 'decline', amount: null });
              }}
            >
              Decline
            </Button>
          </>
        ) : inviteStatus === 'declined' ? (
          <Typography style={{ marginRight: '10px' }}>You declined the invite</Typography>
        ) : (
          <Button
            variant="text"
            color="primary"
            className={classes.button}
            onClick={handleButtonClick}
            startIcon={<OpenInNew />}
          >
            Go to{' '}
            {projectType === 'folder'
              ? 'folder'
              : projectType === 'file'
              ? 'file'
              : projectType === 'bookmark'
              ? 'bookmark'
              : 'layer'}
          </Button>
        )}
      </div>

      <ConfirmationDialog
        style={{ zIndex: 2001 }}
        open={storageExceeded}
        handleClose={() => setStorageExceeded(false)}
        title={'Not enough storage space on your Drive'}
        legend={`This map takes up ${parseSize({
          value: map.storageSize,
        })}. Please increase your storage space before accepting the transfer.`}
        cancelText="Cancel"
        agreeText={'UPGRADE STORAGE'}
        onClick={() => {
          history.push('/plan');
          handleClose();
          setStorageExceeded(false);
        }}
      />

      <ConfirmationDialog
        style={{ zIndex: 2001 }}
        open={doubleName}
        handleClose={() => setDoubleName(false)}
        title={'This name already exists'}
        legend={`This name already occurs in your my Drive root. Please rename this layer before accepting this new one.`}
        cancelText="Cancel"
        agreeText={'Go to My Drive'}
        onClick={() => {
          history.push('/drive/me');
          handleClose();
          setDoubleName(false);
        }}
      />

      <ConfirmationDialog
        style={{ zIndex: 2001 }}
        open={dialog.open}
        handleClose={() => setDialog((prev) => ({ ...prev, open: false }))}
        title={
          noBalance && dialog.type === 'accept'
            ? 'Insufficient balance.'
            : dialog.type === 'accept'
            ? 'Are you sure you want to accept this transfer?'
            : 'Are you sure you want to decline this transfer?'
        }
        legend={
          noBalance
            ? 'You need to top up our couple a credit card'
            : dialog.type === 'accept'
            ? `You will be billed ${dialog.amount}.`
            : undefined
        }
        cancelText="Cancel"
        agreeText={noBalance ? 'To balance' : dialog.type === 'accept' ? 'Pay now' : 'Decline invitation'}
        onClick={() => onSubmit(dialog.type === 'accept', noBalance)}
      />
    </>
  );
};

SectionMenuItemInvite.propTypes = {
  projectType: PropTypes.oneOf(['map', 'shape']).isRequired,
  title: PropTypes.string.isRequired,
  invitedById: PropTypes.string.isRequired,
  mapId: PropTypes.string.isRequired,
  user: PropTypes.object,
};

export default SectionMenuItemInvite;
