import React /* , { useMemo } */ from 'react';

import { Avatar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useProfileContext } from 'ReusableComponents/ProfileContext';

import { getAvatarColor, getAvatarTextColor } from 'AppUtility';

const useStyles = makeStyles({ name: 'CustomAvatar' })((theme) => ({
  root: { display: 'inline-flex' },
}));

const CustomAvatar = ({ user, size = 40, className, sx = {}, style = {}, variant = 'circular', colorId, ...props }) => {
  const { classes: styles, cx } = useStyles();
  const profile = useProfileContext(user?.id);

  // console.log(profile);

  if (Object.keys(props).length) {
    console.log({ ...props });
  }

  const backgroundColor = getAvatarColor({ id: colorId || user?.id });

  return (
    <Avatar
      className={cx(styles.root, className)}
      src={profile?.cachedImageUrl}
      variant={variant}
      sx={{
        backgroundColor,
        height: size,
        width: size,
        fontSize: size / 2,
        color: getAvatarTextColor(backgroundColor),
        ...style,
        ...sx,
      }}
      {...props}
    >
      {user && user.username
        ? user.username.substr(0, 2).toUpperCase()
        : user && user.email
        ? user.email.substr(0, 2).toUpperCase()
        : null}
    </Avatar>
  );
};

export default CustomAvatar;
