import React from 'react';

import { Typography, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { withStyles } from 'tss-react/mui';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ApiManager from 'ApiManager';
import { useMainContext } from 'ReusableComponents/MainContext';

import * as animationData from './404.json';
import { useAuth } from '../hooks';
import { retryableLazy } from 'globalUtils';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';

const Lottie = retryableLazy(() => import('lottie-react'));

const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    width: '100%',
    height: '100%',
    gap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
  },
  title: {},
  subtitle: {},
  button: { padding: theme.spacing(3) },
  link: {
    color: theme.palette.primary.light,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  lottieContainer: {
    maxWidth: '400px',
    maxHeight: '400px',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
      maxHeight: '250px',
    },
  },
}));

const ColorButton = withStyles(Button, (theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.common.white),
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
    },
  },
}));

const Page404 = ({ selectedMap, error }) => {
  const { classes } = useStyles();
  const history = useHistory();
  const user = useAuth();

  const { setOpenContact } = useMainContext();

  const setContactOpen = (b) => {
    setOpenContact({
      title: 'Inquiry for ' + selectedMap.user.username,
      cb: () => {
        history.push('/drive');
      },
      userId: selectedMap.user.id,
    });
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  let type = 'layer';
  if (selectedMap && selectedMap.type === 'folder') {
    type = 'folder';
  }
  if (error && error.type === 'folder') {
    type = 'folder';
  }

  let content =
    !error && !selectedMap
      ? {
          title: `Lost in space`,
          content: `Page not found`,
          buttonName: 'To Drive',
          onClick: () => {
            history.push('/drive/me');
          },
        }
      : error && error?.type === 'bookmark'
      ? {
          title: `Path is of type bookmark`,
          content: `You cannot open bookmarks in the file system`,
          buttonName: 'To Drive',
          onClick: () => {
            history.push('/drive/me');
          },
        }
      : error && error?.type === 'file'
      ? {
          title: `Path is of type file`,
          content: `You cannot open files in the viewer`,
          buttonName: 'To Drive',
          onClick: () => {
            history.push('/drive/me');
          },
        }
      : error && error?.type === 'file'
      ? {
          title: `Path is of type folder`,
          content: `You cannot open files in the viewer`,
          buttonName: 'To Drive',
          onClick: () => {
            history.push('/drive/me');
          },
        }
      : error && error?.status === 429 && user
      ? {
          title: `Too Much Usage`,
          content: `You exceeded your maximum usage on this ${type} for this month. Please contact the owner of this map to increase your bandwidth.`,
          buttonName: 'Contact owner',
          onClick: () => {
            setContactOpen(true);
          },
        }
      : error && error.status === 429 && !user
      ? {
          title: `Too Much Usage`,
          content: `You exceeded your maximum usage on this ${type} for this month. Please login in order to contact the owner of this ${type} to increase your bandwidth.`,
          buttonName: 'Log in',
          onClick: () => {
            history.push('/login', {
              from: `${history.location.pathname}${history.location.search}`,
            });
          },
        }
      : error && error.status === 400
      ? {
          title: `${type} not found`,
          content: `Maybe it has been deleted or the link is invalid`,
          buttonName: 'To Drive',
          onClick: () => {
            history.push('/drive/me');
          },
        }
      : !user && (selectedMap?.yourAccess.accessLevel === 0 || error?.status === 403)
      ? {
          title: `Access Denied`,
          content: `You have no access to this ${type}. Try to login and retry.`,
          buttonName: 'Log in',
          onClick: () => {
            history.push('/login', {
              from: `${history.location.pathname}${history.location.search}`,
            });
          },
        }
      : user && selectedMap?.yourAccess.accessLevel === 0
      ? {
          title: `Access Denied`,
          content: `You have no access to this ${type} request the owner for access.`,
          buttonName: 'request access',
          onClick: () => {
            setContactOpen(true);
          },
        }
      : user && error?.status === 403
      ? {
          title: `Access Denied`,
          content: `You have no access to this ${type}`,
          buttonName: 'To Drive',
          onClick: () => {
            history.push('/drive/me');
          },
        }
      : selectedMap?.deleted && selectedMap?.yourAccess.accessLevel >= ApiManager.newAccessLevel['edit+']
      ? {
          title: `${type} was trashed`,
          content: `If you wish to use this ${type} you need to recover it first`,
          buttonName: selectedMap.path.root === 'myMaps' ? 'Open trash' : 'Go to folder',
          onClick: () => {
            let url;
            if (selectedMap.path.root === 'myMaps') {
              url = '/drive/trash';
            } else {
              url = '/drive/shared';
            }
            if (selectedMap.path.path.legth > 1) {
              url = url + '?pathId=' + selectedMap.path.path[selectedMap.path.path.legth - 1].id;
            }
            history.push(url);
          },
        }
      : user && selectedMap?.deleted && selectedMap?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']
      ? {
          title: `${type} was trashed`,
          content: `Contact the owner to recover the ${type}`,
          buttonName: 'Contact owner',
          onClick: () => {
            setContactOpen(true);
          },
        }
      : !user && selectedMap?.deleted && selectedMap?.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']
      ? {
          title: `${type} was trashed`,
          content: `Log in to contact the owner`,
          buttonName: 'Login',
          onClick: () => {
            history.push('/login', {
              from: `${history.location.pathname}${history.location.search}`,
            });
          },
        }
      : selectedMap?.disabled && user && user.id !== selectedMap?.user.id
      ? {
          title: `${type} Temporarily Disabled`,
          content: `This ${type} has been disabled please contact the owner`,
          buttonName: 'Contact owner',
          onClick: () => {
            setContactOpen(true);
          },
        }
      : selectedMap?.disabled && !user
      ? {
          title: `${type} Temporarily Disabled`,
          content: `This map has been disabled please login to contact the owner`,
          buttonName: 'Log in',
          onClick: () => {
            history.push('/drive', {
              from: `${history.location.pathname}${history.location.search}`,
            });
          },
        }
      : selectedMap?.disabled && user && user.id === selectedMap?.user.id && user.plan.maxNegative > user.coins
      ? {
          title: `${type} Temporarily Disabled`,
          content: `This ${type} has been disabled due to lack of payment. Please add some balance or add a payment method.`,
          buttonName: 'Go to balance',
          onClick: () => {
            history.push('/balance', {
              from: `${history.location.pathname}${history.location.search}`,
            });
          },
        }
      : {
          title: `Lost in space`,
          content: `Unknown reason`,
          buttonName: 'Go to Drive',
          onClick: () => {
            history.push('/drive', {
              from: `${history.location.pathname}${history.location.search}`,
            });
          },
        };

  return (
    <React.Fragment>
      <div
        className={classes.container}
        style={{
          backgroundImage: `linear-gradient(180deg, rgba(13, 46, 76, 0.6), rgba(13, 46, 76, 0.6)), url(${process.env.PUBLIC_URL}/images/error/spacebg.jpg)`,
        }}
      >
        <div className={classes.lottieContainer}>
          <SuspenseContainer displayName="Page404">
            <Lottie {...defaultOptions} />
          </SuspenseContainer>
        </div>
        <Typography className={classes.title} variant="h1">
          {content.title}
        </Typography>
        <Typography className={classes.subtitle} variant="bodyEmphasis">
          {content.content}
        </Typography>
        <ColorButton variant="contained" className={classes.button} onClick={content.onClick}>
          {content.buttonName}
        </ColorButton>
      </div>
    </React.Fragment>
  );
};

Page404.propTypes = {
  reason: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Page404;
