import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { Add } from '@mui/icons-material';
import { CircularProgress, Fab, Tooltip, useMediaQuery } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { useMinimalAuth } from './hooks';

import { SelectedItemProvider } from './Drive/selectedItemContext';
import ItemsDragLayer from './Drive/ItemsDragLayer';
import { ContextMenuProvider } from './Drive/ContextMenuContext';
import useDataProvider from './Drive/useDataProvider';
import SearchAndFilters from './Drive/SearchAndFilters';
import Breadcrumbs from './Drive/Breadcrumbs';
import { useCurrentFolder } from './Drive/CurrentFolderContext';
import Page404 from 'Page404/Page404';
import { DriveContext } from 'Drive/DriveContext';
import { DriveMenu, WrapperForDriveContextMenu } from 'Drive/DriveMenu/DriveMenu';
import UserNotAuthorized from 'Drive/Pages/unauthorized/UserNotAuthorized';
import { BuyItem } from 'Drive/Pages/BuyItem/BuyItem';

import { useMainContext } from 'ReusableComponents';

import { useUploadLogic } from 'UploadDialog';
import { retryableLazy } from 'globalUtils';
import SuspenseContainer from 'ReusableComponents/SuspenseContainer';
import { NAVBAR_BREAK } from 'App';
import ProcessComponent from 'ProcessComponent';

const Viewer = retryableLazy(() => import('Viewer/Viewer'));
const MapManagement = retryableLazy(() => import('MapManagement/MapManagement'));
const InnerDrive = retryableLazy(() => import('Drive/InnerDrive'));
const FilesManagement = retryableLazy(() => import('FilesManagement'));
const FileComponent = retryableLazy(() => import('FileComponent'));

// use to identify the Drive background from others
export const currentName = '__current_displayed__';

const useStyles = makeStyles({
  name: 'Drive',
})((theme) => ({
  root: {
    display: 'grid',
    height: '100%',
    gridTemplateRows: 'min-content auto 1fr',
    overflow: 'hidden',
  },
  scrollWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  skeletonGridWrapper: {
    marginTop: 24,
    display: 'grid',
    gridGap: 12,
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    [theme.breakpoints.down('sm')]: { gridTemplateColumns: 'repeat(1, 1fr)' },
  },
  mapWrapper: { display: 'grid', gridGap: theme.spacing(1) },
  listWrapper: {
    paddingTop: 0,
    marginTop: 12,
    gridTemplateColumns: '1fr',
  },
  gridWrapper: { gridTemplateColumns: 'repeat(auto-fill, minmax(270px, 1fr))' },
  spacer: {},
}));

const Drive = ({ viewerButtonRef }) => {
  //everything in Drive should react on the breadcrumb, NOT THE URL. Otherwise things might get inconsistent
  const { root, currentFolderInfo, mainMode, breadcrumbError } = useCurrentFolder();
  const user = useMinimalAuth();
  const pathId = currentFolderInfo?.id;
  const { setTourFunctions, onOpenSidebar } = useMainContext();

  useEffect(() => {
    setTourFunctions('drive', {
      'Ellipsis Drive': (cb) => onOpenSidebar(true, cb),
    });
  }, [setTourFunctions, onOpenSidebar]);

  if (!mainMode) {
    return <CircularProgress color="primary" style={{ position: 'absolute', top: '50%', right: '50%' }} />;
  }

  if (
    breadcrumbError ||
    (!!currentFolderInfo &&
      (currentFolderInfo?.deleted ||
        currentFolderInfo?.disabled ||
        (currentFolderInfo?.plans.length === 0 && currentFolderInfo?.yourAccess?.accessLevel === 0)))
  ) {
    return <Page404 selectedMap={currentFolderInfo} error={breadcrumbError} />;
  }

  if (currentFolderInfo?.plans.length > 0 && currentFolderInfo?.yourAccess?.accessLevel === 0) {
    return <BuyItem />;
  }

  if (!user && !currentFolderInfo) {
    return <UserNotAuthorized />;
  }

  return (
    <ContextMenuProvider>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <ItemsDragLayer />
      <SelectedItemProvider root={root} pathId={pathId}>
        <WrapperForDriveContextMenu>{<ActualDrive viewerButtonRef={viewerButtonRef} />}</WrapperForDriveContextMenu>
      </SelectedItemProvider>
    </ContextMenuProvider>
  );
};

export default Drive;

const ActualDrive = ({ viewerButtonRef, onContextMenu, layerClick }) => {
  const { classes: styles } = useStyles();

  const { currentFolderInfo, mainMode, getPathInfo } = useCurrentFolder();
  const { newCaptureKey } = useMainContext();
  const { hideDialog } = useUploadLogic();
  const folderContentProps = useDataProvider();

  const queryBreak = useMediaQuery((theme) => theme.breakpoints.up(NAVBAR_BREAK));

  const currentNewCaptureKey = useRef(newCaptureKey.key);
  useEffect(() => {
    if (currentNewCaptureKey.current !== newCaptureKey.key) {
      currentNewCaptureKey.current = newCaptureKey.key;
      if (newCaptureKey.e) {
        layerClick(newCaptureKey.e);
      }
    }
  }, [newCaptureKey, layerClick]);
  let mode;
  if (mainMode === '/view') {
    mode = 'view';
  } else if (mainMode.includes('/settings')) {
    mode = 'settings';
  }

  return (
    <SuspenseContainer displayName="Drive">
      {mode === 'view' && <Viewer viewerButtonRef={viewerButtonRef} />}
      {mode === 'settings' && <MapManagement selectedMap={currentFolderInfo} onSelectMap={getPathInfo} />}

      {!mode && (
        <DriveContext.Provider value={folderContentProps}>
          <div className={styles.root} onContextMenu={onContextMenu}>
            <SearchAndFilters />
            {mainMode !== '/drive/trash' && mainMode !== '/drive/search' ? (
              <Breadcrumbs />
            ) : (
              <div className={styles.spacer} />
            )}
            {currentFolderInfo && currentFolderInfo.type === 'file' && mainMode !== '/drive/search' ? (
              <FileComponent />
            ) : currentFolderInfo && currentFolderInfo.type === 'process' && mainMode !== '/drive/search' ? (
              <ProcessComponent />
            ) : currentFolderInfo && currentFolderInfo.type !== 'folder' && mainMode !== '/drive/search' ? (
              <FilesManagement />
            ) : (
              <InnerDrive />
            )}
          </div>
          {hideDialog && !queryBreak && (!currentFolderInfo || currentFolderInfo.type === 'folder') && (
            <Tooltip
              title={currentFolderInfo && currentFolderInfo.type !== 'folder' ? 'Add timestamp' : 'Add layer or folder'}
            >
              <Fab
                sx={(theme) => ({ position: 'absolute', right: theme.spacing(2), bottom: theme.spacing(2) })}
                color={'secondary'}
                aria-label="add"
                size="large"
                onClick={(e) => {
                  onContextMenu(e);
                }}
              >
                <Add />
              </Fab>
            </Tooltip>
          )}

          <DriveMenu />
        </DriveContext.Provider>
      )}
    </SuspenseContainer>
  );
};
