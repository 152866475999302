import React from 'react';

import { PersonAdd } from '@mui/icons-material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';

const ManageSharing = ({ setDialog, path, onClose }) => {
  const manageSharing = () => {
    setDialog({
      type: 'sharing',
      title: 'Manage Sharing',
      content: { id: path?.id },
    });

    onClose();
  };

  return <CombinedMenuItem icon={<PersonAdd />} label="Sharing" action={manageSharing} openDialog />;
};

export default ManageSharing;
