import React from 'react';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';

import Section from './Section';
import SectionMenuItem from './SectionMenuItem';

import { Popover, MenuList } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  container: {
    width: 'min(95vw, 360px)',
    maxHeight: 'min(80vh, 700px)',
    overflowY: 'auto',
    boxSizing: 'border-box',
    zIndex: 2000,
    marginTop: theme.spacing(1),
  },
  popper: {},
}));

function NotificationMenu({ open, handleClose, anchorRef, children, notification, allowOutClick }) {
  const { classes } = useStyles();

  return (
    <Popover
      open={!!open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      onClose={() => {
        if (allowOutClick) {
          handleClose();
        }
      }}
      className={classes.popper}
      BackdropProps={{ invisible: false }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ className: classes.container }}
    >
      {
        <MenuList autoFocusItem={open} id="menu-list-grow">
          {children}
        </MenuList>
      }
    </Popover>
  );
}

NotificationMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  anchorRef: PropTypes.any,
  children: PropTypes.node.isRequired,
};

NotificationMenu.Section = Section;
NotificationMenu.SectionMenuItem = SectionMenuItem;

export default NotificationMenu;
