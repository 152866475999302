import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles({ name: 'Section' })((theme) => ({
  container: {
    maxWidth: '660px',
  },
  textField: {
    width: '260px',
  },
  textFieldInput: {},
  submitButton: {},
  groupTitle: {},
  copyLinkBtn: {},
  caption: {
    marginBottom: '6px',
  },
}));

export default useStyles;
