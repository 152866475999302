import React, { createContext, useContext, useState } from 'react';

const contextMenuContext = createContext();

const ContextMenuProvider = ({ children }) => {
  const [menuOptions, setMenuOptions] = useState({
    open: false,
  });

  return <contextMenuContext.Provider value={{ menuOptions, setMenuOptions }}>{children}</contextMenuContext.Provider>;
};

function useContextMenu() {
  const context = useContext(contextMenuContext);
  if (context === undefined) {
    throw new Error('useContextMenuContext must be used within a ContextMenuProvider');
  }
  return context;
}

function withContextMenu(Component) {
  return function WrapperComponent(props) {
    const menuOptions = useContextMenu();
    return <Component {...props} contextMenuOptions={menuOptions} />;
  };
}

export { ContextMenuProvider, useContextMenu, withContextMenu };
