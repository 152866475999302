import { useState, useCallback, useEffect } from 'react';

function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (e) {
      console.warn(e);
    }
  }, [storedValue, key]);

  const setValue = useCallback((value) => {
    setStoredValue(value);
  }, []);

  return [storedValue, setValue];
}

export default useLocalStorage;
