import React from 'react';

import { AddCircle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import CombinedMenuItem from '../Components/CombinedMenuItem/CombinedMenuItem';
import { useMainContext } from 'ReusableComponents';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import ApiManager from 'ApiManager';

const NewTimestamp = React.forwardRef(({ close }, ref) => {
  const { setNewCaptureKey } = useMainContext();
  const { currentFolderInfo } = useCurrentFolder();
  return (
    <Tooltip
      title={
        currentFolderInfo.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']
          ? 'Insufficient access'
          : 'Add a timestamp'
      }
    >
      <span>
        <CombinedMenuItem
          icon={<AddCircle color="secondary" />}
          label="New Timestamp"
          action={() => {
            close();
            setNewCaptureKey();
          }}
          disabled={currentFolderInfo.yourAccess.accessLevel < ApiManager.newAccessLevel['edit+']}
          ref={ref}
        />
      </span>
    </Tooltip>
  );
});

NewTimestamp.displayName = 'NewTimestamp';

export default NewTimestamp;
